/* eslint-disable react/destructuring-assignment */
import { Spinner } from '@nike/eds';

const Loading = (full: any) => {
  const OverCss = {
    width: '100vw',
    height: '100vh',
    position: 'fixed',
    alignItems: 'center',
    justifyContent: 'center',
    display: 'flex',
    top: 0,
    left: 0,
    zIndex: 999,
  };
  const InlineCss = {
    width: '100px',
    height: '100px',
  };
  return (
    <div className="eds--layers-10">
      <div style={full ? OverCss : InlineCss}>
        <Spinner size="large" />
      </div>
    </div>
  );
};

export default Loading;
