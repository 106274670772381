import { Select } from '@nike/eds';
import { FC, useEffect } from 'react';
import { Props as ReactSelectProps } from 'react-select';
import { useAppDispatch, useAppSelector } from '../../../redux/hooks';
import { roles } from '../../common/RolesConstants';
import {
  TRANSFER_TYPE_OUTSOLE_COMPLETE,
  TRANSFER_TYPE_TECH_PACK,
  TRANSFER_TYPE_TOOLING,
  fullTransferTypeOptions,
  ITransferData,
} from '../NewTranferInterface';
import { updateInputTouched } from '../../../redux/actions/inputActions';

interface FieldProps {
  transferData: any | ITransferData;
  setTransferData: (params: any) => any;
}

const TransferType: FC<FieldProps> = ({ transferData, setTransferData }) => {
  const [transferItemData, userRole, isEditMode] = useAppSelector(state => [
    state.transferRecordItem.transferItemData.source,
    state.userRole.role,
    state.input.isEditMode,
  ]);

  const dispatch = useAppDispatch();

  // clearing fields logic
  useEffect(() => {
    if (
      (transferData.transferType?.value === TRANSFER_TYPE_OUTSOLE_COMPLETE &&
        transferData.transferType?.value !== transferItemData?.transferType) ||
      (transferData.transferType?.value === TRANSFER_TYPE_TOOLING &&
        transferData.transferType?.value !== transferItemData?.transferType)
    ) {
      setTransferData({
        ...transferData,
        styleNumber: null,
        productColorwayCode: null,
        productCode: null,
        pccCodes: '',
        tdCode: '',
        styleName: '',
      });
    }
    if (
      transferData.transferType?.value === TRANSFER_TYPE_TECH_PACK &&
      transferData.transferType?.value !== transferItemData?.transferType
    ) {
      setTransferData({
        ...transferData,
        productColorwayCode: null,
        productCode: null,
        outsole: '',
        transferGeos: ['ALL'],
      });
    }
  }, [transferData.transferType]);

  const handleSelectInputChange: ReactSelectProps['onChange'] = (
    value: any
  ) => {
    setTransferData({ ...transferData, transferType: value });

    dispatch<any>(updateInputTouched(true));
  };

  return (
    <Select
      options={fullTransferTypeOptions}
      id="transfer-type-select"
      label="Transfer Type"
      name="transferType"
      onChange={handleSelectInputChange}
      value={transferData.transferType}
      isDisabled={
        (userRole === roles.factory || userRole === roles.pmo) &&
        isEditMode &&
        transferItemData.status !== 'Factory' &&
        transferItemData.status !== 'Draft'
      }
    />
  );
};

export default TransferType;
