/* eslint-disable no-underscore-dangle */
import { Dispatch } from 'redux';
import { toast } from 'react-toastify';
import axios from 'axios';
import {
  TR_ITEM_FETCH_SUCCESS,
  TR_ITEM_LOADING_END,
  TR_ITEM_LOADING_START,
  TR_ITEM_FETCH_FAILED,
  ResponseCode,
  TransferRecordItemDispatchType,
  TR_POST_COMMENT_SUCCESS,
  TR_POST_COMMENT_FAILED,
  TR_POST_COMMENT_START,
  TR_POST_COMMENT_END,
  TR_PUT_TRANSFER_DATA_START,
  TR_PUT_TRANSFER_DATA_SUCCESS,
  TR_PUT_TRANSFER_DATA_END,
  TR_PUT_TRANSFER_DATA_FAILED,
  TR_PATCH_FLAG_SUCCESS,
  TR_PATCH_FLAG_FAILED,
  TR_PATCH_TRANSFER_STATUS_FAILED,
  TR_PATCH_TRANSFER_STATUS_SUCCESS,
  TR_PATCH_TRANSFER_STATUS_START,
  IUpdateTransferStatusItemData,
  TR_DELETE_ATTACHMENT_START,
  TR_DELETE_ATTACHMENT_SUCCESS,
  TR_DELETE_ATTACHMENT_FAILED,
  TR_DELETE_ATTACHMENT_END,
  TR_ADD_ATTACHMENT_START,
  TR_ADD_ATTACHMENT_SUCCESS,
  TR_ADD_ATTACHMENT_FAILED,
  TR_ADD_ATTACHMENT_END,
  TR_ITEM_DUPLICATE_SUCCESS,
  TR_ITEM_DUPLICATE_FAILED,
  TR_ITEM_DUPLICATE_END,
  TR_ITEM_DUPLICATE_START,
} from '../types/transferRecordItemTypes';

import apiInstance from '../axios/index';

import apiConfig from '../../config/apiConfig';
import {
  POST_TRANSFER_DATA_DASHBOARD_SUCCESS,
  PostTransferData,
} from '../types/newTransferTypes';

export const fetchTransferRecordItem =
  (id: any) => async (dispatch: Dispatch<TransferRecordItemDispatchType>) => {
    dispatch({ type: TR_ITEM_LOADING_START });
    const errorMsg = 'Could not get Transfer Record Details';
    try {
      let getTransferUrl = apiConfig.baseURL;
      if (id.transferId !== undefined) {
        getTransferUrl += apiConfig.getTransferItem.url.replace(
          '{id}',
          id.transferId
        );
      } else {
        getTransferUrl += apiConfig.getTransferItem.url.replace('{id}', id);
      }

      const response = await apiInstance.get(`${getTransferUrl}`, {});

      if (response && response.status === ResponseCode.OKAY) {
        dispatch({
          type: TR_ITEM_FETCH_SUCCESS,
          payload: response.data.response,
        });
      } else {
        dispatch({
          type: TR_ITEM_FETCH_FAILED,
          payload: response.data.response,
        });
        toast.error(errorMsg);
      }
    } catch (err) {
      toast.error(errorMsg);
      dispatch({
        type: TR_ITEM_LOADING_END,
      });
    } finally {
      dispatch({
        type: TR_ITEM_LOADING_END,
      });
    }
  };

export const duplicateTransferRecordItem =
  (id: any) => async (dispatch: Dispatch<TransferRecordItemDispatchType>) => {
    dispatch({ type: TR_ITEM_DUPLICATE_START });
    const errorMsg = 'Could not get Transfer Record Details';
    try {
      let getTransferUrl = apiConfig.baseURL;
      if (id.transferId !== undefined) {
        getTransferUrl += apiConfig.getTransferItem.url.replace(
          '{id}',
          id.transferId
        );
      } else {
        getTransferUrl += apiConfig.getTransferItem.url.replace('{id}', id);
      }

      const response = await apiInstance.get(`${getTransferUrl}`, {});

      if (response && response.status === ResponseCode.OKAY) {
        dispatch({
          type: TR_ITEM_DUPLICATE_SUCCESS,
          payload: response.data.response.source,
        });
      } else {
        dispatch({
          type: TR_ITEM_DUPLICATE_FAILED,
          payload: response.data.response.source,
        });
        toast.error(errorMsg);
      }
    } catch (err) {
      toast.error(errorMsg);
      dispatch({
        type: TR_ITEM_DUPLICATE_END,
        payload: {
          id: '',
          source: {
            attachments: [],
          },
        },
      });
    } finally {
      dispatch({
        type: TR_ITEM_DUPLICATE_END,
        payload: {
          id: '',
          source: {
            attachments: [],
          },
        },
      });
    }
  };

export const updateTransferFlag =
  (id: any, importantFlag: any) =>
  async (dispatch: Dispatch<TransferRecordItemDispatchType>) => {
    dispatch({ type: TR_ITEM_LOADING_START });
    const successMsg = importantFlag
      ? 'Transfer marked as flagged'
      : 'Transfer unmarked as flagged';
    const errorMsg = 'Something went wrong. Failed to update transfer';
    try {
      const putTransferRecord =
        apiConfig.baseURL +
        apiConfig.patchTransferFlag.url
          .replace('{id}', id)
          .replace('{value}', importantFlag);

      const response = await apiInstance.patch(`${putTransferRecord}`);

      if (response && response.status === ResponseCode.OKAY) {
        toast.success(successMsg);
        dispatch({
          type: TR_PATCH_FLAG_SUCCESS,
          payload: response.data.response.source,
        });

        dispatch({
          type: POST_TRANSFER_DATA_DASHBOARD_SUCCESS,
          payload: {
            ...response.data.response.source,
            _id: response.data.response.id,
          },
        });
      } else {
        toast.error(errorMsg);
      }
    } catch (err) {
      toast.error(errorMsg);
      dispatch({ type: TR_PATCH_FLAG_FAILED });
    } finally {
      dispatch({
        type: TR_ITEM_LOADING_END,
      });
    }
  };

export const postTransferComment =
  (id: any, body: any) =>
  async (dispatch: Dispatch<TransferRecordItemDispatchType>) => {
    dispatch({ type: TR_POST_COMMENT_START });

    try {
      let postTransferCommentUrl = apiConfig.baseURL;
      if (id.transferId !== undefined) {
        postTransferCommentUrl += apiConfig.patchTransferComments.url.replace(
          '{id}',
          id.transferId
        );
      } else {
        postTransferCommentUrl += apiConfig.patchTransferComments.url.replace(
          '{id}',
          id
        );
      }
      const response = await apiInstance.patch(
        `${postTransferCommentUrl}`,
        body
      );

      if (response && response.status === ResponseCode.CREATED) {
        dispatch({
          type: TR_POST_COMMENT_SUCCESS,
          payload: response.data.response,
        });

        toast.success('Comment added');
      }
    } catch (err) {
      dispatch({
        type: TR_POST_COMMENT_FAILED,
      });
      const errorMsg = 'Something went wrong. Failed to post comment';

      toast.error(errorMsg);
    } finally {
      dispatch({ type: TR_POST_COMMENT_END });
    }
  };

export const updateTransferData =
  (id: string, body: PostTransferData) =>
  async (dispatch: Dispatch<TransferRecordItemDispatchType>) => {
    dispatch({ type: TR_PUT_TRANSFER_DATA_START });
    try {
      let putTransferUrl = apiConfig.baseURL;
      putTransferUrl += apiConfig.updateTransferData.url.replace('{id}', id);

      const response = await apiInstance.put(`${putTransferUrl}`, body);

      if (response && response.status === ResponseCode.OKAY) {
        dispatch({
          type: TR_PUT_TRANSFER_DATA_SUCCESS,
          payload: response.data.response.source,
        });
        toast.success('Save successful');
      }

      dispatch({
        type: POST_TRANSFER_DATA_DASHBOARD_SUCCESS,
        payload: {
          ...response.data.response.source,
          _id: response.data.response.id,
        },
      });
    } catch (err: any) {
      dispatch({
        type: TR_PUT_TRANSFER_DATA_FAILED,
      });

      let errorMessage = 'Unable to save record';
      if (
        err?.response?.data?.error?.code === '400' ||
        err?.response?.status === 400
      ) {
        errorMessage = `Unable to save record. ${
          err.response?.data?.error?.description ||
          err.response?.data?.response[0]?.reason ||
          err.response?.data?.response
        }`;
      }

      toast.error(errorMessage);

      dispatch({ type: TR_PUT_TRANSFER_DATA_END });
    }
  };

export const updateTransferRecordItemStatus =
  (status: string, data: IUpdateTransferStatusItemData) =>
  async (dispatch: Dispatch<TransferRecordItemDispatchType>) => {
    dispatch({ type: TR_PATCH_TRANSFER_STATUS_START });
    try {
      const updateTransferStatusUrl =
        apiConfig.baseURL +
        apiConfig.putUpdateTransferStatus.url.replace('{action}', status);

      const response = await apiInstance.patch(
        `${updateTransferStatusUrl}`,
        data
      );

      if (response && response.status === ResponseCode.OKAY) {
        let successMessage = '';

        if (response.data.response.itemsList[0].source.status === 'Confirmed') {
          successMessage = 'Transfer Confirmed successfully';

          dispatch({
            type: TR_PATCH_TRANSFER_STATUS_SUCCESS,
            payload: response.data.response.itemsList[0],
          });
        }

        if (response.data.response.itemsList[0].source.status === 'Cancelled') {
          successMessage = 'Transfer Cancelled successfully';

          dispatch({
            type: TR_PATCH_TRANSFER_STATUS_SUCCESS,
            payload: response.data.response.itemsList[0],
          });
        }

        if (response.data.response.itemsList[0].source.status === 'Factory') {
          successMessage = 'Transfer sent to Factory successfully';

          dispatch({
            type: TR_PATCH_TRANSFER_STATUS_SUCCESS,
            payload: response.data.response.itemsList[0],
          });
        }

        if (response.data.response.itemsList[0].source.status === 'Sourcing') {
          successMessage = 'Transfer sent to Sourcing successfully';

          dispatch({
            type: TR_PATCH_TRANSFER_STATUS_SUCCESS,
            payload: response.data.response.itemsList[0],
          });
        }

        toast.success(successMessage);
      }

      dispatch({
        type: POST_TRANSFER_DATA_DASHBOARD_SUCCESS,
        payload: {
          _id: response.data.response.itemsList[0]._id,
          ...response.data.response.itemsList[0].source,
        },
      });
    } catch (err: any) {
      const errData =
        err.response?.data?.response.length > 1
          ? err.response?.data?.response
          : err.response.data.response[0].reason;
      if (err.response?.data?.response.length >= 1) {
        dispatch({
          type: TR_PATCH_TRANSFER_STATUS_FAILED,
          payload: { status, ...errData },
        });
      }
      toast.error(`Unable to update transfer record status, ${errData}`);
    }
  };

export const deleteTransferAttachment =
  (id: string | undefined, fileName: string) =>
  async (dispatch: Dispatch<TransferRecordItemDispatchType>) => {
    dispatch({ type: TR_DELETE_ATTACHMENT_START });
    const errorMsg = 'Something went wrong. Failed to delete attachment';
    try {
      let deleteTransferAttachmentUrl = apiConfig.baseURL;
      if (fileName !== undefined) {
        deleteTransferAttachmentUrl += apiConfig.deleteTransferAttachments.url
          .replace('{fileName}', fileName)
          .replace('{id}', id || '');
      }

      const response = await apiInstance.post(
        `${deleteTransferAttachmentUrl}`,
        {}
      );

      if (
        response &&
        (response.status === ResponseCode.CREATED ||
          response.status === ResponseCode.OKAY)
      ) {
        dispatch({
          type: TR_DELETE_ATTACHMENT_SUCCESS,
          payload: fileName,
        });

        toast.success('Deleted Successfully');
      }

      const finalObject = response.data.response.source;

      dispatch({
        type: POST_TRANSFER_DATA_DASHBOARD_SUCCESS,
        payload: {
          ...finalObject,
          _id: response.data.response.source.transferId,
        },
      });

      dispatch({ type: TR_DELETE_ATTACHMENT_END });
    } catch (err: any) {
      if (err.response) {
        dispatch({
          type: TR_DELETE_ATTACHMENT_FAILED,
          payload: err.response.status,
        });
        if (err?.response?.data?.error?.code === '400') {
          toast.error(err.response.data.error.description);
        }
      } else {
        toast.error(errorMsg);
      }

      dispatch({ type: TR_DELETE_ATTACHMENT_END });
    }
  };

export const uploadTransferAttachment =
  (id: string | undefined, file: File | undefined) =>
  async (dispatch: Dispatch<TransferRecordItemDispatchType>) => {
    dispatch({ type: TR_ADD_ATTACHMENT_START });
    const errorMsg = 'Something went wrong. Failed to upload attachment';
    const {
      baseURL,
      uploadTransferAttachmentGenerateS3Url,
      uploadTransferAttachmentUpdateRecord,
    } = apiConfig;
    const tenMegaBytes = 10485760;

    try {
      if (file) {
        if (file.size <= tenMegaBytes) {
          const s3FilePath = file.name;

          const insertDoc = {
            fileType: file.type,
            fileName: file.name,
            DisplayName: file.name,
            attachmentUrl: s3FilePath,
            createdTimestamp: Date.now(),
          };

          const generateS3Path = `${uploadTransferAttachmentGenerateS3Url.url.replace(
            '{id}',
            id || ''
          )}?filename=${id}/${s3FilePath}`;

          // Generating TempURL
          const s3PathResponse = await apiInstance.get(
            baseURL + generateS3Path
          );

          if (s3PathResponse && s3PathResponse.status === ResponseCode.OKAY) {
            const s3Url = s3PathResponse?.data?.s3path;
            const s3UploadResponse = await axios.put(s3Url, file, {
              headers: {
                'Content-Type':
                  'multipart/form-data; boundary=---WebKitFormBoundary7MA4YWxkTrZu0gW',
              },
            });

            if (
              s3UploadResponse &&
              s3UploadResponse.status === ResponseCode.OKAY
            ) {
              // Updating transfer record initiate
              const updateTransferS3UploadPath = `${uploadTransferAttachmentUpdateRecord.url.replace(
                '{id}',
                id || ''
              )}`;
              const response = await apiInstance.put(
                baseURL + updateTransferS3UploadPath,
                insertDoc
              );

              if (response && response.status === ResponseCode.CREATED) {
                dispatch({
                  type: TR_ADD_ATTACHMENT_SUCCESS,
                  payload: response.data.response,
                });

                dispatch({
                  type: POST_TRANSFER_DATA_DASHBOARD_SUCCESS,
                  payload: {
                    ...response.data.response.source,
                    _id: response.data.response.id,
                  },
                });
                toast.success('Uploaded Successfully');
              }
              dispatch({ type: TR_ADD_ATTACHMENT_END });
            }
          }
        } else {
          toast.error('File size cannot exceed 10Mb');
        }
      }
    } catch (err) {
      dispatch({
        type: TR_ADD_ATTACHMENT_FAILED,
      });
      toast.error(`${errorMsg} ${err}`);
      dispatch({ type: TR_ADD_ATTACHMENT_END });
    }
  };
