import { FC, useCallback } from 'react';
import { Button } from '@nike/eds';
import { TransferRecordItemSourceType } from '../../redux/types/transferRecordItemTypes';
import { roles } from '../common/RolesConstants';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import { updateTransferFlag } from '../../redux/actions/transferRecordItemActions';

interface TransferPrimaryDataBoxProps {
  data: TransferRecordItemSourceType;
}
const TransferTitle: FC<TransferPrimaryDataBoxProps> = ({ data = {} }) => {
  const {
    productCode,
    outsoleCode,
    importantFlag,
    status,
    styleNumber,
    transferId,
  } = data;

  const [userRole] = useAppSelector(state => [state.userRole.role]);
  const dispatch = useAppDispatch();

  const handleTransferFlagToggle = useCallback(() => {
    if (userRole !== roles.planning && transferId) {
      dispatch<any>(updateTransferFlag(transferId, !importantFlag));
    }
  }, [userRole, roles.planning, transferId, importantFlag]);

  const renderStatusIndicator = () => {
    if (status === 'Cancelled') {
      return (
        <span id="status-indicator" className="cell-cancelled">
          {status}
        </span>
      );
    }
    if (status === 'Confirmed') {
      return (
        <span id="status-indicator" className="cell-confirmed">
          {status}
        </span>
      );
    }
    if (status === 'Sourcing') {
      return (
        <span id="status-indicator" className="cell-sourcing">
          {status}
        </span>
      );
    }
    if (status === 'Factory') {
      return (
        <span id="status-indicator" className="cell-factory">
          {status}
        </span>
      );
    }
    return (
      <span id="status-indicator" className="cell-draft">
        {status}
      </span>
    );
  };

  return (
    <div className="eds-grid--m-col-2">
      <h3 className="eds-type--title-3 transfer-title">
        Transfer {productCode || styleNumber || outsoleCode}:{' '}
        {renderStatusIndicator()}
      </h3>

      <Button size="medium" variant="ghost" onClick={handleTransferFlagToggle}>
        {importantFlag ? (
          <span id="flag-icon" className="eds-spacing--pl-8">
            Transfer Flagged as Important
          </span>
        ) : (
          <span id="flag-icon" className="eds-spacing--pl-8">
            Flag as Important
          </span>
        )}
      </Button>
    </div>
  );
};
export default TransferTitle;
