/* eslint-disable no-useless-escape */
/* eslint-disable no-underscore-dangle */
import { FC, useState, useEffect, useMemo } from 'react';
import { Button, Text } from '@nike/eds';
import { Link, useNavigate } from 'react-router-dom';
import { CaretLeft } from '@nike/nike-design-system-icons';
import 'react-datepicker/dist/react-datepicker.css';
import moment from 'moment';
import ConfirmModal from './ConfirmModal';
import './NewTransfer.scss';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import { postTransferDataCall } from '../../redux/actions/newTransferActions';
import { validateOnSubmit } from './Validation';
import MessageModal from './MessageModal';
import {
  roles,
  TRANSFER_STATUS_CONFIRMED,
  TRANSFER_STATUS_FACTORY,
  TRANSFER_STATUS_DRAFT,
  TRANSFER_STATUS_SOURCING,
} from '../common/RolesConstants';
import {
  ITransferData,
  transferInitialState,
  TRANSFER_TYPE_TOOLING,
  TRANSFER_TYPE_TECH_PACK,
  TRANSFER_TYPE_OUTSOLE_COMPLETE,
  TRANSFER_TYPE_EXISTING,
  TRANSFER_TYPE_MODEL_COMPLETE,
  TRANSFER_TYPE_COMPLETE,
  TRANSFER_TYPE_NEW,
} from './NewTranferInterface';
import Form from './Form';
import {
  hideError,
  hideErrorActionCreator,
  submitErrorActionCreator,
} from '../../redux/actions/errorActions';
import fixColorwayCodeArray from '../../utils/saveTransfer';
import {
  POST_TRANSFER_DATA_RESET,
  PostTransferData,
  STYLE_NAME_FETCH_CLEAR,
} from '../../redux/types/newTransferTypes';
import {
  updateEditMode,
  updateInputTouched,
} from '../../redux/actions/inputActions';

const NewTransfer: FC = () => {
  const [isConfirmTransferDataValid, setIsConfirmTransferDataValid] =
    useState<boolean>(false);
  const [isDraftTransferDataValid, setIsDraftTransferDataValid] =
    useState<boolean>(false);
  const [isOpenTransferDataValid, setIsOpenTransferDataValid] =
    useState<boolean>(false);
  const [isConfirmModalOpen, setIsConfirmModalOpen] = useState<boolean>(false);
  const [transferData, setTransferData] =
    useState<ITransferData>(transferInitialState);
  const [stateClicked, setStateClicked] = useState(false);

  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [
    productOfferingIdentifier,
    outsoleCodes,
    postTransferData,
    userRole,
    userId,
    modelName,
    transferItemData,
    newTransferDone,
    inputTouched,
    hasBlockingErrors,
  ] = useAppSelector(state => [
    state.newTransfer.productInfo?._source.productOfferingIdentifier,
    state.newTransfer.outsoleCodes,
    state.newTransfer.postTransferData,
    state.userRole.role,
    state.userId.id,
    state.newTransfer.productInfo?._source.modelName,
    state.transferRecordItem.transferItemData.source,
    state.newTransfer.newTransferDone,
    state.input.isTouched,
    state.error.hasBlockingError,
  ]);

  useEffect(() => {
    dispatch<any>(hideError());
    dispatch<any>(updateEditMode(false));

    const getColorway = () => {
      let colorObject:
        | { value: string; label: string }
        | { value: string; label: string }[]
        | null;
      if (
        transferItemData.colorwayCode &&
        !Array.isArray(transferItemData.colorwayCode)
      ) {
        colorObject = {
          value: transferItemData.colorwayCode,
          label: transferItemData.colorwayCode,
        };
      } else if (
        transferItemData.colorwayCode &&
        Array.isArray(transferItemData.colorwayCode)
      ) {
        colorObject = {
          value: transferItemData.colorwayCode[0],
          label: transferItemData.colorwayCode[0],
        };
      } else {
        colorObject = null;
      }
      return colorObject;
    };

    if (transferItemData !== undefined && transferItemData !== null) {
      if (Object.keys(transferItemData).length !== 0) {
        setTransferData({
          ...transferData,
          transferGeos: transferItemData.transferGeos || ['ALL'],
          transferType: transferItemData.transferType
            ? {
                value: transferItemData.transferType,
                label: transferItemData.transferType,
              }
            : null,
          dueDate: new Date(moment(new Date()).add(7, 'days').toISOString()),
          season: transferItemData.seasonYearCode
            ? {
                value: transferItemData.seasonYearCode,
                label: transferItemData.seasonYearCode,
              }
            : null,
          productCode:
            transferItemData.productCode !== null
              ? {
                  value: transferItemData.productCode,
                  label: transferItemData.productCode,
                }
              : null,
          styleNumber: transferItemData.styleNumber
            ? {
                value: transferItemData.styleNumber,
                label: transferItemData.styleNumber,
              }
            : null,
          styleName: transferItemData.styleName || '',
          productColorwayCode: getColorway(),
          toFactory: transferItemData.toFactoryCode
            ? {
                value: transferItemData.toFactoryCode,
                label: transferItemData.toFactoryCode,
              }
            : null,
          fromFactory: transferItemData.fromFactoryCode
            ? {
                value: transferItemData.fromFactoryCode,
                label: transferItemData.fromFactoryCode,
              }
            : null,
          toFactoryGroup: transferItemData.toFactoryGroupCode || '',
          fromFactoryGroup: transferItemData.fromFactoryGroupCode || '',
          engineeringTransferType: transferItemData.engineeringType
            ? {
                value: transferItemData.engineeringType,
                label: transferItemData.engineeringType,
              }
            : null,
          transferReason: transferItemData.transferReason
            ? {
                value: transferItemData.transferReason,
                label: transferItemData.transferReason,
              }
            : null,
          productPlanningGroup: {
            value: transferItemData.productPlanningGroup,
            label: transferItemData.productPlanningGroup,
          },
        });
      }
    }
  }, []);

  useEffect(() => {
    if (
      postTransferData.success &&
      (postTransferData.data.status === 'Draft' ||
        postTransferData.data.status === 'Confirmed' ||
        postTransferData.data.status === 'Sourcing' ||
        postTransferData.data.status === 'Factory')
    ) {
      if (newTransferDone) {
        setTimeout(() => {
          navigate('/'); // on success save data navigating to dashboard
        }, 1000);
      }
    }
  }, [postTransferData.success]);

  const onCancelConfirm = () => {
    setTransferData({ ...transferData });
    setIsConfirmModalOpen(false);
    dispatch<any>(updateInputTouched(false));
    dispatch<any>(updateEditMode(false));
    navigate('/');
  };

  const onConfirmModalClose = () => {
    setStateClicked(false);
    setIsConfirmModalOpen(false);
  };

  const onDismissErrorModal = () => {
    setStateClicked(false);
    dispatch({
      type: POST_TRANSFER_DATA_RESET,
    });
  };

  const onCancel = () => {
    if (inputTouched) {
      setIsConfirmModalOpen(true);
    } else {
      onCancelConfirm();
    }
  };

  const onClear = () => {
    setTransferData(transferInitialState);
    dispatch({
      type: STYLE_NAME_FETCH_CLEAR,
    });

    // reset error
    dispatch<any>(
      hideErrorActionCreator([
        'dueDate',
        'toFactory',
        'fromFactory',
        'fromFactoryGroup',
        'toFactoryGroup',
        'productPlanningGroup',
        'outsole',
        'pccCodes',
        'styleNumber',
        'productColorwayCode',
      ])
    );
  };

  useEffect(() => {
    // draft
    if (
      (transferData.transferType?.value === TRANSFER_TYPE_TECH_PACK ||
        transferData.transferType?.value === TRANSFER_TYPE_EXISTING ||
        transferData.transferType?.value === TRANSFER_TYPE_MODEL_COMPLETE ||
        transferData.transferType?.value === TRANSFER_TYPE_COMPLETE ||
        transferData.transferType?.value === TRANSFER_TYPE_NEW) &&
      transferData.season?.value &&
      (transferData.productCode?.value || transferData.styleNumber?.value)
    ) {
      setIsDraftTransferDataValid(true);
    } else if (
      (transferData.transferType?.value === TRANSFER_TYPE_TOOLING ||
        transferData.transferType?.value === TRANSFER_TYPE_OUTSOLE_COMPLETE) &&
      transferData.season?.value &&
      transferData.outsole !== '' &&
      transferData.outsole !== undefined
    ) {
      setIsDraftTransferDataValid(true);
    } else {
      setIsDraftTransferDataValid(false);
    }

    // factory or sourcing
    if (
      (transferData.transferType?.value === TRANSFER_TYPE_TECH_PACK ||
        transferData.transferType?.value === TRANSFER_TYPE_EXISTING ||
        transferData.transferType?.value === TRANSFER_TYPE_MODEL_COMPLETE ||
        transferData.transferType?.value === TRANSFER_TYPE_COMPLETE ||
        transferData.transferType?.value === TRANSFER_TYPE_NEW) &&
      transferData.season?.value &&
      (transferData.productCode?.value || transferData.styleNumber?.value) &&
      transferData.productPlanningGroup?.value &&
      transferData.toFactory?.value
    ) {
      setIsOpenTransferDataValid(true);
    } else if (
      (transferData.transferType?.value === TRANSFER_TYPE_TOOLING ||
        transferData.transferType?.value === TRANSFER_TYPE_OUTSOLE_COMPLETE) &&
      transferData.season?.value &&
      transferData.productPlanningGroup?.value &&
      transferData.outsole !== '' &&
      transferData.outsole !== undefined &&
      transferData.toFactory?.value
    ) {
      setIsOpenTransferDataValid(true);
    } else {
      setIsOpenTransferDataValid(false);
    }

    // confirm
    if (
      (transferData.transferType?.value === TRANSFER_TYPE_EXISTING ||
        transferData.transferType?.value === TRANSFER_TYPE_NEW) &&
      transferData.season?.value &&
      (transferData.styleNumber?.value || transferData.productCode?.value) &&
      transferData.pccCodes !== '' &&
      transferData.pccCodes !== undefined &&
      transferData.productPlanningGroup?.value &&
      transferData.outsole !== '' &&
      transferData.outsole !== undefined &&
      transferData.toFactory?.value &&
      transferData.fromFactory?.value &&
      userRole === roles.analyst
    ) {
      setIsConfirmTransferDataValid(true);
    } else if (
      (transferData.transferType?.value === TRANSFER_TYPE_TECH_PACK ||
        transferData.transferType?.value === TRANSFER_TYPE_MODEL_COMPLETE ||
        transferData.transferType?.value === TRANSFER_TYPE_COMPLETE) &&
      transferData.season?.value &&
      (transferData.styleNumber?.value || transferData.productCode?.value) &&
      transferData.pccCodes !== '' &&
      transferData.pccCodes !== undefined &&
      transferData.productPlanningGroup?.value &&
      transferData.toFactory?.value &&
      transferData.fromFactory?.value &&
      userRole === roles.analyst
    ) {
      setIsConfirmTransferDataValid(true);
    } else if (
      transferData.transferType?.value === TRANSFER_TYPE_TOOLING &&
      transferData.season?.value &&
      transferData.productPlanningGroup?.value &&
      transferData.toFactory?.value &&
      transferData.fromFactory?.value &&
      transferData.outsole !== '' &&
      transferData.outsole !== undefined &&
      userRole === roles.analyst
    ) {
      setIsConfirmTransferDataValid(true);
    } else if (
      transferData.transferType?.value === TRANSFER_TYPE_OUTSOLE_COMPLETE &&
      transferData.season?.value &&
      transferData.productPlanningGroup?.value &&
      transferData.toFactory?.value &&
      transferData.fromFactory?.value &&
      transferData.outsole !== '' &&
      transferData.outsole !== undefined &&
      userRole === roles.analyst
    ) {
      setIsConfirmTransferDataValid(true);
    } else {
      setIsConfirmTransferDataValid(false);
    }

    // if form have a blocking error
    if (hasBlockingErrors) {
      setIsConfirmTransferDataValid(false);
      setIsDraftTransferDataValid(false);
      setIsOpenTransferDataValid(false);
    }
  }, [transferData]);

  const handlePostTransferData = (status: string) => {
    if (
      status === TRANSFER_STATUS_SOURCING ||
      status === TRANSFER_STATUS_FACTORY ||
      status === TRANSFER_STATUS_CONFIRMED ||
      status === TRANSFER_STATUS_DRAFT
    ) {
      const transferDataPayload: PostTransferData = {
        importantFlag: false,
        styleName: transferData.styleName,
        colorwayCode: fixColorwayCodeArray(transferData.productColorwayCode),
        seasonYearCode: transferData.season?.value || '',
        styleNumber: transferData.styleNumber?.value || '',
        transferNotes: transferData.transferNotes,
        dueDate: transferData.dueDate,
        productPlanningGroup: transferData.productPlanningGroup?.value || '',
        engineeringType: transferData.engineeringTransferType?.value,
        fromFactoryCode: transferData.fromFactory?.value || '',
        toFactoryCode: transferData.toFactory?.value || '',
        transferGeos: transferData.transferGeos,
        transferReason: transferData.transferReason?.value,
        transferType: transferData.transferType?.value || '',
        outsoleCode:
          transferData.outsole ||
          transferItemData.outsoleCode ||
          outsoleCodes[0] ||
          '',
        toFactoryGroupCode: transferData.toFactoryGroup || '',
        fromFactoryGroupCode: transferData.fromFactoryGroup || '',
        productCreationCenterCode: transferData.pccCodes || '',
        tdCode: transferData.tdCode || '',
        status,
        createdBy: userId,
        createdTimestamp: new Date().toISOString().slice(0, -5),
        modelName,
        productOfferingIdentifier,
      };

      dispatch<any>(postTransferDataCall(transferDataPayload));
    }
  };

  const allTrue = (obj: any) => {
    // eslint-disable-next-line no-restricted-syntax
    for (const o in obj) if (obj[o].error) return false;
    return true;
  };

  const handleStateButtonClick = (state: string) => {
    const validation = validateOnSubmit(transferData);

    if (!stateClicked) {
      setStateClicked(true);
      if (!allTrue(validation)) {
        dispatch<any>(submitErrorActionCreator(validation));
      } else {
        handlePostTransferData(state);
      }
    }
  };

  const errorMessageContent = useMemo(() => {
    if (
      Array.isArray(postTransferData?.data?.error) &&
      postTransferData.data.error.length > 0
    ) {
      if (
        postTransferData.data.error.length === 1 &&
        postTransferData.data.error[0].reason === 'Duplicate record'
      ) {
        return (
          <Text font="body-1" as="p">
            The transfer you are attempting to create has already been created
            and is a duplicate, here is a{' '}
            <Link
              className="redirection-link"
              to={`/transfer-view/${postTransferData.data.error[0].duplicateId}`}
            >
              link{' '}
            </Link>
            to the duplicate transfer.
          </Text>
        );
      }
      return (
        <ul className="numList">
          {postTransferData.data.error.map((item: { [key: string]: string }) =>
            item.reason === 'Duplicate record' ? (
              <li key={item.duplicateId}>
                For Colorway Code: {item.colorwayCode}, here is a{' '}
                <Link
                  className="redirection-link"
                  to={`/transfer-view/${item.duplicateId}`}
                >
                  link
                </Link>{' '}
                to the duplicate transfer.
              </li>
            ) : (
              <li key={item.reason.toString()}>
                For Colorway Code: {item.colorwayCode}.{' '}
                {item.reason.replace(/[\[\]']+/g, '').toString()}
              </li>
            )
          )}
        </ul>
      );
    }

    return postTransferData.data.error;
  }, [postTransferData?.data]);

  return (
    <div className="new-transfer-container" style={{ padding: '52px 56px' }}>
      <ConfirmModal
        onConfirm={onCancelConfirm}
        isOpen={isConfirmModalOpen}
        onDismiss={onConfirmModalClose}
      />
      <MessageModal
        headerTitle="Error Details"
        message={errorMessageContent}
        isOpen={!!postTransferData.error && postTransferData.alreadyExists}
        onDismiss={onDismissErrorModal}
      />
      <div className="header-container eds-button-group">
        <Button
          className="back-label"
          size="medium"
          variant="ghost"
          onClick={onCancel}
          beforeSlot={<CaretLeft />}
        >
          Back to Dashboard
        </Button>
        <div>
          <Button
            id="action-buttons"
            size="medium"
            variant="secondary"
            onClick={onClear}
          >
            Clear
          </Button>
          <Button
            id="draft-button"
            className="state-buttons"
            size="medium"
            variant="primary"
            disabled={!isDraftTransferDataValid}
            onClick={() => {
              handleStateButtonClick(TRANSFER_STATUS_DRAFT);
            }}
          >
            Save Draft
          </Button>
          {userRole === roles.analyst ? (
            <>
              <Button
                id="send-to-factory-button"
                className="state-buttons"
                size="medium"
                variant="primary"
                disabled={!isOpenTransferDataValid}
                onClick={() => {
                  handleStateButtonClick(TRANSFER_STATUS_FACTORY);
                }}
              >
                Send to Factory
              </Button>
              <Button
                id="confirm-button"
                className="state-buttons"
                size="medium"
                variant="primary"
                disabled={!isConfirmTransferDataValid}
                onClick={() => {
                  handleStateButtonClick(TRANSFER_STATUS_CONFIRMED);
                }}
              >
                Confirm
              </Button>
            </>
          ) : (
            <Button
              id="send-to-sourcing-button"
              className="state-buttons"
              size="medium"
              variant="primary"
              disabled={!isOpenTransferDataValid}
              onClick={() => {
                handleStateButtonClick(TRANSFER_STATUS_SOURCING);
              }}
            >
              Send to Sourcing
            </Button>
          )}
        </div>
      </div>

      <Text font="title-3" as="h3">
        Create Transfer Record
      </Text>

      <Form transferData={transferData} setTransferData={setTransferData} />
    </div>
  );
};

export default NewTransfer;
