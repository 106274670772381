// eslint-disable-next-line import/prefer-default-export
export const roles = {
  factory: 'FactoryPlanning',
  planning: 'WHQSupplyPlanning',
  pmo: 'PMOPlanning',
  analyst: 'WHQSourcing',
};

// everywhere else constants
export const TRANSFER_STATUS_SOURCING = 'Sourcing';
export const TRANSFER_STATUS_FACTORY = 'Factory';
export const TRANSFER_STATUS_CONFIRMED = 'Confirmed';
export const TRANSFER_STATUS_DRAFT = 'Draft';
export const TRANSFER_STATUS_CANCELLED = 'Cancelled';

// api constants
export const API_TRANSFER_STATUS_DRAFT = 'draft';
export const API_TRANSFER_STATUS_SOURCING = 'sourcing';
export const API_TRANSFER_STATUS_FACTORY = 'factory';
export const API_TRANSFER_STATUS_CONFIRM = 'confirm';
export const API_TRANSFER_STATUS_CANCEL = 'cancel';
