import { DELETE_TRANSFER_DATA_DASHBOARD_SUCCESS } from './transferRecordsTypes';

export const TR_DELETE_START = 'TR_DELETE_START';
export const TR_DELETE_END = 'TR_DELETE_END';
export const TR_DELETE_SUCCESS = 'TR_DELETE_SUCCESS';
export const TR_DELETE_FAILED = 'TR_DELETE_FAILED';
export const TR_DELETE_RESET = 'TR_DELETE_RESET';

interface IDeleteTransferStart {
  type: typeof TR_DELETE_START;
}

interface IDeleteTransferEnd {
  type: typeof TR_DELETE_END;
}

interface IDeleteTransferSuccess {
  type: typeof TR_DELETE_SUCCESS;
}

interface IDeleteTransferFailed {
  type: typeof TR_DELETE_FAILED;
}

interface IDeleteTransferReset {
  type: typeof TR_DELETE_RESET;
}

interface IDeleteTransferDataDashBoardSuccess {
  type: typeof DELETE_TRANSFER_DATA_DASHBOARD_SUCCESS;
  payload: any;
}

export type DeleteTransferDispatchType =
  | IDeleteTransferStart
  | IDeleteTransferEnd
  | IDeleteTransferSuccess
  | IDeleteTransferFailed
  | IDeleteTransferReset
  | IDeleteTransferDataDashBoardSuccess;

export enum ResponseCode {
  OKAY = 200,
  NOTFOUND = 400,
}
