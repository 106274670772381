import {
  POST_TRANSFER_DATA_DASHBOARD_SUCCESS,
  PostTransferData,
} from './newTransferTypes';

export const UPDATE_TRANSFER_STATUS_START = 'UPDATE_TRANSFER_STATUS_START';
export const UPDATE_TRANSFER_STATUS_SUCCESS = 'UPDATE_TRANSFER_STATUS_SUCCESS';
export const UPDATE_TRANSFER_STATUS_FAILURE = 'UPDATE_TRANSFER_STATUS_FAILURE';
export const UPDATE_TRANSFER_STATUS_RESET = 'UPDATE_TRANSFER_STATUS_RESET';

export interface IUpdateTransferStatusData {
  ids: string[];
}

interface IUpdateTransferStatusStart {
  type: typeof UPDATE_TRANSFER_STATUS_START;
}

interface IUpdateTransferStatusSuccess {
  type: typeof UPDATE_TRANSFER_STATUS_SUCCESS;
  payload?: object;
}

interface IUpdateTransferStatusFailure {
  type: typeof UPDATE_TRANSFER_STATUS_FAILURE;
  payload?: object;
}

interface IUpdateTransferStatusReset {
  type: typeof UPDATE_TRANSFER_STATUS_RESET;
}

interface IPostTransferDataDashBoardSuccess {
  type: typeof POST_TRANSFER_DATA_DASHBOARD_SUCCESS;
  payload: DashboardValuesTransferData;
}

export interface DashboardValuesTransferData extends PostTransferData {
  _id: string;
}

export type UpdateTransferStatusDispatchType =
  | IUpdateTransferStatusStart
  | IUpdateTransferStatusSuccess
  | IUpdateTransferStatusFailure
  | IUpdateTransferStatusReset
  | IPostTransferDataDashBoardSuccess;

export enum ResponseCode {
  OKAY = 200,
}
