import { selectAccessToken, useAuthStore } from '@nike/aegis-auth-react';
import axios from 'axios';

import apiConfig from '../config/apiConfig';

interface ISttTokenHeaders {
  Authorization: string;
  RefreshToken: string;
  userid: string;
  userrole: string;
}

export async function fetchSttTokenHeaders(): Promise<ISttTokenHeaders> {
  const accessToken = selectAccessToken(useAuthStore.getState()) || '';
  const storeData = useAuthStore.getState();
  const idClaims = { groups: [''], ...storeData.idClaims };

  const getTransferUrl = apiConfig.baseURL + apiConfig.getSttToken.url;
  const userid = idClaims?.email?.toLowerCase() || '';
  const userrole =
    (idClaims?.groups && idClaims?.groups[0]?.split('.').at(-1)) || '';

  const response = await axios.get(getTransferUrl, {
    headers: {
      Authorization: `Bearer ${accessToken}`,
      userid,
      userrole,
    },
  });

  return {
    Authorization: response.data?.data.access_token
      ? `Bearer ${response.data?.data.access_token}`
      : '',
    RefreshToken: response.data?.data.refresh_token,
    userid,
    userrole,
  };
}

export async function fetchUserGroupName(): Promise<string> {
  const storeData = useAuthStore.getState();
  const idClaims = { groups: [''], ...storeData.idClaims };

  const userRole =
    (idClaims?.groups && idClaims?.groups[0]?.split('.').at(-1)) || '';

  return userRole;
}

export async function fetchUserID(): Promise<string> {
  const storeData = useAuthStore.getState();
  const idClaims = { groups: [''], ...storeData.idClaims };

  const userId = idClaims?.email?.toLowerCase() || '';

  return userId;
}
