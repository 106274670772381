import { Select } from '@nike/eds';
import { FC } from 'react';
import { Props as ReactSelectProps } from 'react-select';
import { useAppDispatch } from '../../../redux/hooks';
import { engineeringTypeOptions, ITransferData } from '../NewTranferInterface';
import { updateInputTouched } from '../../../redux/actions/inputActions';

interface FieldProps {
  transferData: any | ITransferData;
  setTransferData: (params: any) => any;
}

const EngineeringType: FC<FieldProps> = ({ transferData, setTransferData }) => {
  const dispatch = useAppDispatch();

  const handleSelectInputChange: ReactSelectProps['onChange'] = (
    value: any
  ) => {
    setTransferData({ ...transferData, engineeringTransferType: value });

    dispatch<any>(updateInputTouched(true));
  };

  return (
    <Select
      isClearable
      options={engineeringTypeOptions}
      id="engineering-trf-type-select"
      label="Engineering Transfer Type"
      name="engineeringTransferType"
      onChange={handleSelectInputChange}
      value={transferData.engineeringTransferType}
      isDisabled={!transferData?.transferType?.value}
    />
  );
};

export default EngineeringType;
