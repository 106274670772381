import { groupBy, differenceBy } from 'lodash';
import moment from 'moment';
import { Fragment } from 'react';
import { ITransferRecordItemChangeLog } from '../../redux/types/transferRecordItemTypes';
import { sortChangeLogDates } from '../../utils/sortFunctions';

const getLogFieldName = (log: ITransferRecordItemChangeLog) => {
  if (log.field === '' && log.newValue === '' && log.originalValue === '') {
    return 'Created';
  }
  if (log.field) {
    // eslint-disable-next-line func-names
    return log.field.replace(/([A-Z])/g, ' $1').replace(/^./, function (str) {
      return str.toUpperCase();
    });
  }
  if (Object.keys(log)[0] === 'importantFlag') {
    return 'Important Flag';
  }
  return '';
};

const getLogValueToShow = (
  log: ITransferRecordItemChangeLog,
  key: keyof ITransferRecordItemChangeLog
) => {
  if (log.field === 'attachments') {
    if (key) {
      let originalValue = [];
      try {
        originalValue = JSON.parse(log.originalValue);
      } catch (e) {
        originalValue = [];
      }
      let newValue = [];
      try {
        newValue = JSON.parse(log.newValue);
      } catch (e) {
        newValue = [];
      }
      const originalValues = Array.isArray(originalValue)
        ? originalValue
        : [originalValue];
      const newValues = Array.isArray(newValue) ? newValue : [newValue];

      const listToShow =
        key === 'originalValue'
          ? differenceBy(originalValues, newValues, 'createdTimestamp')
          : differenceBy(newValues, originalValues, 'createdTimestamp');

      if (listToShow.length) {
        return (
          <ol className="attachments-ol">
            {listToShow.map(a =>
              a.fileName ? (
                <li key={a.attachmentUrl}>
                  <p>File name: {a.fileName}</p>
                </li>
              ) : null
            )}
          </ol>
        );
      }
    }
  } else if (log.field === 'comments') {
    if (key === 'newValue') {
      if (log[key]) {
        const value = log[key];
        if (value) {
          return <p>{value}</p>;
        }
      }

      return log[key];
    }
  } else if (Object.keys(log)[0] === 'importantFlag') {
    if (key === 'newValue') {
      const [name, value] = Object.entries(log)[0];
      if (name) {
        return <p>{value.charAt(0).toUpperCase() + value.slice(1)}</p>;
      }

      return log[key];
    }
  } else if (log.field === 'dueDate') {
    return moment(log[key]).format('DD-MMM-YYYY');
  } else {
    const value = log[key];
    return Array.isArray(value) ? value.join(',') : value;
  }
  return '';
};

const ChangeLogTab = (props: { changeLog: any }) => {
  const { changeLog } = props;
  const logSorted =
    changeLog && changeLog.length > 0
      ? changeLog
          .map((log: { createdTimestamp: string }) => {
            const week = moment(log.createdTimestamp).week();
            const year = moment(log.createdTimestamp).year();
            return {
              ...log,
              week: moment({
                y: year,
              })
                .week(week)
                .startOf('week')
                .format('MM/DD/YYYY'),
            };
          })
          .sort(sortChangeLogDates)
      : [];

  const logGrouped =
    logSorted && logSorted.length > 0 ? groupBy(logSorted, a => a.week) : [];

  const logGrpArray =
    Object.keys(logGrouped).length > 0
      ? Object.entries(logGrouped).map(([key, value]) => {
          const changeList: any[] = value;
          return {
            week: key,
            list: changeList.filter((item: any) => {
              if (
                item.newValue === '' &&
                item.originalValue === undefined &&
                item.action === 'Update'
              ) {
                return '';
              }
              return (
                item.field !== 'modelName' &&
                item.field !== 'productOfferingIdentifier'
              );
            }),
          };
        })
      : [];

  return (
    <div className="change-log-container">
      {logGrpArray && logGrpArray.length > 0 ? (
        logGrpArray.map(logGrpItem => (
          <div
            className="travelcompany-input eds-spacing--pt-32"
            key={`log${Math.random()}${logGrpItem}`}
          >
            <h4 className="input-label">Week of {logGrpItem.week}</h4>
            <div className="change-log-table eds-spacing--mt-32 eds-grid eds-grid--m-cols-6">
              <div className="eds-type--subtitle-1 eds-grid--m-col-2 header-item">
                Field
              </div>
              <div className="eds-type--subtitle-1 eds-grid--m-col-2 header-item">
                Original Value
              </div>
              <div className="eds-type--subtitle-1 eds-grid--m-col-2 header-item">
                New Value
              </div>

              {logGrpItem.list &&
                logGrpItem.list.length > 0 &&
                logGrpItem.list.map((log: ITransferRecordItemChangeLog) => (
                  <Fragment key={`log${Math.random()}`}>
                    <div className="eds-grid--m-col-5">
                      <p className="eds-spacing--pb-8">
                        <span className="eds-type--subtitle-1 eds-spacing--mr-16">
                          {log.userEmail || log.modifiedBy}
                        </span>
                        <span className="eds-color--grey-1">
                          {moment
                            .utc(log.changeTimestamp || log.modifiedTimestamp)
                            .local()
                            .format('MM/DD/YYYY')}{' '}
                          at{' '}
                          {moment
                            .utc(log.changeTimestamp || log.modifiedTimestamp)
                            .local()
                            .format('hh:mm A')}
                        </span>
                      </p>
                    </div>

                    <div className="eds-grid--m-col-2">
                      <p className="eds-spacing--pb-8">
                        <span className="log-field-text">
                          {getLogFieldName(log)}
                        </span>
                      </p>
                    </div>
                    <div className="eds-grid--m-col-2">
                      <div className="eds-spacing--pb-8 log-o-value-text">
                        {getLogValueToShow(log, 'originalValue')}
                      </div>
                    </div>
                    <div className="eds-grid--m-col-2">
                      <div className="eds-spacing--pb-8 log-n-value-text">
                        {getLogValueToShow(log, 'newValue')}
                      </div>
                    </div>

                    <div className="eds-grid--m-col-5">
                      <hr />
                    </div>
                  </Fragment>
                ))}
            </div>
          </div>
        ))
      ) : (
        <div className="eds-spacing--p-16 eds-color--link-disable">
          No Logs Available
        </div>
      )}
    </div>
  );
};

export default ChangeLogTab;
