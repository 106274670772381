import { FunctionComponent } from 'react';
import { Button, ButtonGroup, Modal, Text } from '@nike/eds';

const ConfirmModal: FunctionComponent<any> = ({
  // remove any
  onConfirm,
  isOpen,
  onDismiss,
}) => (
  <Modal
    onDismiss={onDismiss}
    isOpen={isOpen}
    headerSlot={<>Are you sure?</>}
    footerSlot={
      <ButtonGroup>
        <Button onClick={onConfirm} size="small">
          Yes
        </Button>
        <Button onClick={onDismiss} size="small" variant="secondary">
          Close
        </Button>
      </ButtonGroup>
    }
  >
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
      }}
    >
      <Text font="body-1" as="p">
        Are you sure you want to proceed with this action?
      </Text>
    </div>
  </Modal>
);

export default ConfirmModal;
