// eslint-disable-next-line import/no-cycle
import { POST_TRANSFER_DATA_DASHBOARD_SUCCESS } from './newTransferTypes';

export const TR_LOADING_START = 'TR_LOADING_START';
export const TR_LOADING_ERROR = 'TR_LOADING_ERROR';
export const TR_LOADING_END = 'TR_LOADING_END';
export const TR_FETCH_SUCCESS = 'TR_FETCH_SUCCESS';
export const TR_CNT_FETCH_SUCCESS = 'TR_CNT_FETCH_SUCCESS';
export const DELETE_TRANSFER_DATA_DASHBOARD_SUCCESS =
  'DELETE_TRANSFER_DATA_DASHBOARD_SUCCESS';

export type TransferRecordType = {
  _id: string;
  importantFlag: boolean;
  status: string;
  createdBy: string;
  createdTimestamp: string;
  dueDate: Date;
  seasonYearCode: string;
  productPlanningGroup: string;
  styleNumber: string;
  productCode: string;
  styleName: string;
  outsoleCode: string;
  fromFactoryCode: string;
  toFactoryCode: string;
  fromFactoryGroupCode: string;
  toFactoryGroupCode: string;
  transferReason: string;
  modifiedTimestamp?: any;
  sort: [];
};

interface ITransferRecordLoadingStart {
  type: typeof TR_LOADING_START;
}

interface ITransferRecordLoadingError {
  type: typeof TR_LOADING_ERROR;
}

interface ITransferRecordLoadingEnd {
  type: typeof TR_LOADING_END;
}

interface ITransferRecordFetchSuccess {
  type: typeof TR_FETCH_SUCCESS;
  payload: TransferRecordType[];
}

interface IPostTransferDataDashBoardSuccess {
  type: typeof POST_TRANSFER_DATA_DASHBOARD_SUCCESS;
  payload: TransferRecordType;
}

interface IDeleteTransferDataDashBoardSuccess {
  type: typeof DELETE_TRANSFER_DATA_DASHBOARD_SUCCESS;
  payload: any;
}

export type TransferRecordDispatchType =
  | ITransferRecordLoadingStart
  | ITransferRecordLoadingEnd
  | ITransferRecordFetchSuccess
  | ITransferRecordLoadingError
  | IPostTransferDataDashBoardSuccess
  | IDeleteTransferDataDashBoardSuccess;

export enum ResponseCode {
  OKAY = 200,
  CREATED = 201,
}

export interface ISelectionAction {
  status: string;
  ids: string[];
}

export interface IActionConfirmModalProps {
  onConfirm: () => void;
  isOpen: boolean;
  onDismiss: () => void;
  actionToConfirm: ISelectionAction | null;
}

export interface IActionFailureModalProps {
  isOpen: boolean;
  onDismiss: () => void;
  actionData: any;
}

export interface IActionFailureResponseItem {
  styleNumber: string;
  seasonYearCode: string;
  colorwayCode: string;
  outsoleCode: string;
  reason: string;
}
