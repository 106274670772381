import { Dispatch } from 'redux';
import { toast } from 'react-toastify';
import { AxiosResponse } from 'axios';
import apiInstance from '../axios/index';

import apiConfig from '../../config/apiConfig';
import {
  TR_DOWNLOAD_ATTACHMENT_END,
  TR_DOWNLOAD_ATTACHMENT_FAILED,
  TR_DOWNLOAD_ATTACHMENT_START,
  TR_DOWNLOAD_ATTACHMENT_SUCCESS,
  TransferAttachmentDispatchType,
  ResponseCode,
  TR_DOWNLOAD_ATTACHMENT_RESET,
  TR_UPLOAD_ATTACHMENT_RESET,
  TR_DELETE_ATTACHMENT_RESET,
} from '../types/transferAttachmentTypes';

export const downloadTransferAttachment =
  (transferId: string, fileName: string) =>
  async (dispatch: Dispatch<TransferAttachmentDispatchType>) => {
    dispatch({ type: TR_DOWNLOAD_ATTACHMENT_START });

    const errorMsg = 'Something went wrong. Failed to download attachment';
    try {
      let downloadTransferAttachmentUrl = apiConfig.baseURL;
      if (fileName !== undefined) {
        downloadTransferAttachmentUrl += `${apiConfig.downloadTransferAttachments.url}${transferId}/${fileName}`;
      }
      const response = await apiInstance.get(
        `${downloadTransferAttachmentUrl}`,
        {}
      );

      if (response && response.status === ResponseCode.OKAY) {
        const downloadAttachmentsData = {
          fileName: response?.config?.url?.split('/').pop(),
          url: response.data[0],
        };
        dispatch({
          type: TR_DOWNLOAD_ATTACHMENT_SUCCESS,
          payload: [downloadAttachmentsData],
        });
      } else {
        dispatch({
          type: TR_DOWNLOAD_ATTACHMENT_FAILED,
          payload: response.status,
        });
        toast.error(errorMsg);
      }
      dispatch({ type: TR_DOWNLOAD_ATTACHMENT_END });
    } catch (err) {
      toast.error(errorMsg);
      dispatch({ type: TR_DOWNLOAD_ATTACHMENT_END });
    }
  };

export const downloadAllTransferAttachments =
  (transferId: string, fileNames: string[]) =>
  async (dispatch: Dispatch<TransferAttachmentDispatchType>) => {
    dispatch({ type: TR_DOWNLOAD_ATTACHMENT_START });

    const errorMsg = 'Something went wrong. Failed to download all attachments';
    try {
      const promises: Promise<AxiosResponse>[] = [];

      fileNames.forEach(fileName => {
        if (fileName !== undefined) {
          let downloadAllTransferAttachmentUrl = apiConfig.baseURL;
          downloadAllTransferAttachmentUrl += `${apiConfig.downloadTransferAttachments.url}${transferId}/${fileName}`;
          promises.push(
            apiInstance.get(`${downloadAllTransferAttachmentUrl}`, {})
          );
        }
      });
      const response: any = await Promise.all(promises);
      const downloadAllAttachmentsData: any = [];
      response.forEach((a: any) => {
        if (a.status === ResponseCode.OKAY) {
          downloadAllAttachmentsData.push({
            fileName: a.config.url.split('/').pop(),
            url: a.data[0],
          });
        }
      });

      if (downloadAllAttachmentsData.length) {
        dispatch({
          type: TR_DOWNLOAD_ATTACHMENT_SUCCESS,
          payload: downloadAllAttachmentsData,
        });
      } else {
        dispatch({
          type: TR_DOWNLOAD_ATTACHMENT_FAILED,
          payload: response.status,
        });
        toast.error(errorMsg);
      }
      dispatch({ type: TR_DOWNLOAD_ATTACHMENT_END });
    } catch (err) {
      toast.error(errorMsg);
      dispatch({ type: TR_DOWNLOAD_ATTACHMENT_END });
    }
  };

export const transferDownloadAttachmentReset = () => ({
  type: TR_DOWNLOAD_ATTACHMENT_RESET,
});
export const transferUploadAttachmentReset = () => ({
  type: TR_UPLOAD_ATTACHMENT_RESET,
});

export const transferDeleteAttachmentReset = () => ({
  type: TR_DELETE_ATTACHMENT_RESET,
});
