import { FunctionComponent } from 'react';
import { Modal } from '@nike/eds';

interface MessageModalProps {
  headerTitle: string;
  message: string;
  isOpen: boolean;
  onDismiss: () => void;
}
const MessageModal: FunctionComponent<MessageModalProps> = ({
  headerTitle = '',
  message = '',
  isOpen,
  onDismiss,
}) => {
  const headerSlot = <div>{headerTitle}</div>;
  return (
    <Modal onDismiss={onDismiss} isOpen={isOpen} headerSlot={headerSlot}>
      {Array.isArray(message) ? (
        <ul className="numList">
          {message.map(item => (
            <li key={item.toString()}>{item.toString()}</li>
          ))}
        </ul>
      ) : (
        message !== '' && message
      )}
    </Modal>
  );
};
export default MessageModal;
