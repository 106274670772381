import { FC, useEffect } from 'react';
import moment from 'moment';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import { fetchTransferRecordItem } from '../../redux/actions/transferRecordItemActions';

const TransferPrimaryDataBox: FC = () => {
  const [
    transferItemData,
    transferItemSuccess,
    transferItemLoading,
    transferItemId,
  ] = useAppSelector(state => [
    state.transferRecordItem.transferItemData.source,
    state.transferRecordItem.transferItemLoading,
    state.transferRecordItem.transferItemSuccess,
    state.transferRecordItem.transferItemData.id,
  ]);

  const dispatch = useAppDispatch();

  useEffect(() => {
    if (!transferItemLoading) {
      if (transferItemSuccess) {
        dispatch<any>(fetchTransferRecordItem(transferItemId));
      }
    }
  }, [transferItemData]);

  return (
    <div className="eds-background--white primary-data eds-spacing--mt-32 eds-spacing--p-32">
      <div className="eds-grid eds-grid--m-cols-4 eds-spacing--p-8">
        <div className="eds-type--body-3 eds-color--grey-7">Transfer Type</div>
        <div className="eds-type--body-2">
          {transferItemData?.transferType || '--'}
        </div>
        <div className="eds-type--body-3 eds-color--grey-7">Due Date</div>
        <div className="eds-type--body-2">
          {moment
            .utc(transferItemData?.dueDate)
            .local()
            .format('DD-MMM-YYYY') || '--'}
        </div>
      </div>

      <div className="eds-grid eds-grid--m-cols-4 eds-spacing--p-8">
        <div className="eds-type--body-3 eds-color--grey-7">Season</div>
        <div className="eds-type--body-2">
          {transferItemData?.seasonYearCode || '--'}
        </div>
        <div className="eds-type--body-3 eds-color--grey-7">Style Name</div>
        <div className="eds-type--body-2">
          {transferItemData?.styleName || '--'}
        </div>
      </div>

      <div className="eds-grid eds-grid--m-cols-4 eds-spacing--p-8">
        <div className="eds-type--body-3 eds-color--grey-7">Product Code</div>
        <div className="eds-type--body-2">
          {transferItemData?.productCode || '--'}
        </div>
        <div className="eds-type--body-3 eds-color--grey-7">
          Product Planning Group
        </div>
        <div className="eds-type--body-2">
          {transferItemData?.productPlanningGroup || '--'}
        </div>
        <div className="eds-type--body-3 eds-color--grey-7"> Style Number</div>
        <div className="eds-type--body-2">
          {transferItemData?.styleNumber || '--'}
        </div>
        <div className="eds-type--body-3 eds-color--grey-7">Outsole Code</div>
        <div className="eds-type--body-2">
          {transferItemData?.outsoleCode || '--'}
        </div>
      </div>

      <div className="eds-grid eds-grid--m-cols-4 eds-spacing--p-8">
        <div className="eds-type--body-3 eds-color--grey-7">
          {' '}
          Product Colorway Code
        </div>
        <div className="eds-type--body-2">
          {transferItemData?.colorwayCode || '--'}
        </div>
        <div className="eds-type--body-3 eds-color--grey-7">
          Product Creation Center Code
        </div>
        <div className="eds-type--body-2">
          {transferItemData?.productCreationCenterCode || '--'}
        </div>
      </div>

      <div className="eds-grid eds-grid--m-cols-4 eds-spacing--p-8">
        <div className="eds-type--body-3 eds-color--grey-7">From Factory</div>
        <div className="eds-type--body-2">
          {transferItemData?.fromFactoryCode || '--'}
        </div>
        <div className="eds-type--body-3 eds-color--grey-7">
          From Factory Group
        </div>
        <div className="eds-type--body-2">
          {transferItemData?.fromFactoryGroupCode || '--'}
        </div>
      </div>

      <div className="eds-grid eds-grid--m-cols-4 eds-spacing--p-8">
        <div className="eds-type--body-3 eds-color--grey-7">To Factory</div>
        <div className="eds-type--body-2">
          {transferItemData?.toFactoryCode || '--'}
        </div>
        <div className="eds-type--body-3 eds-color--grey-7">
          To Factory Group
        </div>
        <div className="eds-type--body-2">
          {transferItemData?.toFactoryGroupCode || '--'}
        </div>
      </div>

      <div className="eds-grid eds-grid--m-cols-4 eds-spacing--p-8">
        <div className="eds-type--body-3 eds-color--grey-7">Transfer Note</div>
        <div className="eds-type--body-2">
          <div className="eds-type--body-2">
            {transferItemData?.transferNotes || '--'}
          </div>
        </div>
      </div>

      <div className="eds-grid eds-grid--m-cols-4 eds-spacing--p-8">
        <div className="eds-type--title-6 eds-color--grey-7"> </div>
      </div>

      <div className="eds-grid eds-grid--m-cols-4 eds-spacing--p-8">
        <div className="eds-type--body-3 eds-color--grey-7">
          Engineering Transfer Type
        </div>
        <div className="eds-type--body-2">
          {transferItemData?.engineeringType || '--'}
        </div>
        <div className="eds-type--body-3 eds-color--grey-7">Transfer Geos</div>
        <div className="eds-type--body-2">
          {transferItemData?.transferGeos?.toString() || '--'}
        </div>
      </div>

      <div className="eds-grid eds-grid--m-cols-4 eds-spacing--p-8">
        <div className="eds-type--body-3 eds-color--grey-7">
          Transfer Reason
        </div>
        <div className="eds-type--body-2">
          {transferItemData?.transferReason || '--'}
        </div>
        <div className="eds-type--body-3 eds-color--grey-7">TD Code</div>
        <div className="eds-type--body-2">
          {transferItemData?.tdCode || '--'}
        </div>
      </div>
    </div>
  );
};
export default TransferPrimaryDataBox;
