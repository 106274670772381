const routes = [
  {
    icon: 'Cards',
    label: 'Sourcing Transfer Tracker',
    menu: true,
    route: true,
    subItems: [
      {
        icon: 'Data',
        label: 'Grid',
        path: '/',
        menu: true,
        route: true,
      },
    ],
  },
];
export default routes;
