/* eslint-disable import/no-cycle */
import {
  USER_ID_LOADING_START,
  USER_ID_LOADING_END,
  USER_ID_LOADING_SUCCESS,
  UserIdDispatchType,
} from '../types/userActionTypes';

interface IUserIdDefaultState {
  loading: boolean;
  id: string;
}

const defaultState: IUserIdDefaultState = {
  loading: false,
  id: '',
};

const userIdReducer = (
  state: IUserIdDefaultState = defaultState,
  action: UserIdDispatchType
): IUserIdDefaultState => {
  switch (action.type) {
    case USER_ID_LOADING_START:
      return { ...state, loading: true };
    case USER_ID_LOADING_END:
      return { ...state, loading: false };
    case USER_ID_LOADING_SUCCESS:
      return {
        ...state,
        id: action.payload,
      };
    default:
      return state;
  }
};

export default userIdReducer;
