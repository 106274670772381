import { TextField } from '@nike/eds';
import { FC } from 'react';
import { ITransferData } from '../NewTranferInterface';

interface FieldProps {
  transferData: any | ITransferData;
}

const TdCode: FC<FieldProps> = ({ transferData }) => {
  return (
    <TextField
      label="TD Code"
      type="text"
      value={transferData.tdCode}
      disabled
      id="td-code-group-input"
    />
  );
};

export default TdCode;
