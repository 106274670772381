import { Select } from '@nike/eds';
import { FC, useEffect, useMemo } from 'react';
import { Props as ReactSelectProps } from 'react-select';
import { useAppDispatch, useAppSelector } from '../../../redux/hooks';
import { updateInputTouched } from '../../../redux/actions/inputActions';
import {
  TRANSFER_TYPE_OUTSOLE_COMPLETE,
  TRANSFER_TYPE_TOOLING,
  ITransferData,
} from '../NewTranferInterface';
import { validateOnChange } from '../Validation';
import {
  errorActionCreator,
  updateHasBlockingError,
} from '../../../redux/actions/errorActions';
import { roles } from '../../common/RolesConstants';

interface FieldProps {
  transferData: any | ITransferData;
  setTransferData: (params: any) => any;
  enableAutoPopulateFields: boolean;
}

const ProductPlanningGroup: FC<FieldProps> = ({
  transferData,
  setTransferData,
  enableAutoPopulateFields = true,
}) => {
  const [transferItemData, userRole, ppgList, isEditMode, error, ppg] =
    useAppSelector(state => [
      state.transferRecordItem.transferItemData.source,
      state.userRole.role,
      state.newTransfer.productPlanningGroupList,
      state.input.isEditMode,
      state.error,
      state.newTransfer.ppg,
    ]);

  const dispatch = useAppDispatch();

  const productPlanningGroupList = useMemo(() => {
    return ppgList;
  }, [ppgList]);

  useEffect(() => {
    if (
      enableAutoPopulateFields &&
      transferData.transferType?.value !== TRANSFER_TYPE_TOOLING &&
      transferData.transferType?.value !== TRANSFER_TYPE_OUTSOLE_COMPLETE
    ) {
      if (!isEditMode) {
        setTransferData({
          ...transferData,
          productPlanningGroup: ppg,
        });
      } else if (isEditMode) {
        if (
          transferData.productPlanningGroup !== undefined &&
          transferData.productPlanningGroup !== ''
        ) {
          setTransferData({
            ...transferData,
            productPlanningGroup: ppg,
          });
        } else if (
          ppg === null &&
          transferItemData.productPlanningGroup !== undefined
        ) {
          setTransferData({
            ...transferData,
            productPlanningGroup: {
              value: transferItemData.productPlanningGroup,
              label: transferItemData.productPlanningGroup,
            },
          });
        } else {
          setTransferData({
            ...transferData,
            productPlanningGroup: ppg,
          });
        }
      } else {
        setTransferData({
          ...transferData,
          productPlanningGroup: ppg,
        });
      }
    }
  }, [ppg]);

  const handleSelectInputChange: ReactSelectProps['onChange'] = (
    value: any,
    actionMeta: any
  ) => {
    const inputName: string = actionMeta?.name || '';
    const validationData = {
      ppg,
      transferData,
    };

    setTransferData({ ...transferData, productPlanningGroup: value });

    dispatch<any>(updateHasBlockingError(false));

    const validation = validateOnChange(
      actionMeta,
      value,
      userRole,
      validationData
    );

    dispatch<any>(errorActionCreator(inputName, validation.message));

    if (validation.blockingError) {
      dispatch<any>(updateHasBlockingError(true));
    }

    dispatch<any>(updateInputTouched(true));
  };

  return (
    <Select
      options={productPlanningGroupList}
      id="product-planning-group-input"
      label="Planning Product Group"
      name="productPlanningGroup"
      onChange={handleSelectInputChange}
      value={transferData.productPlanningGroup}
      isDisabled={
        !transferData?.transferType?.value ||
        ((userRole === roles.factory || userRole === roles.pmo) &&
          isEditMode &&
          transferItemData.status !== 'Factory' &&
          transferItemData.status !== 'Draft')
      }
      hasErrors={error.productPlanningGroupError}
      errorMessage={error.productPlanningGroupErrorMessage}
    />
  );
};

export default ProductPlanningGroup;
