import { toast } from 'react-toastify';
import { Dispatch } from 'redux';
import {
  TR_FETCH_SUCCESS,
  TR_LOADING_END,
  TR_LOADING_START,
  TransferRecordDispatchType,
  TR_LOADING_ERROR,
} from '../types/transferRecordsTypes';
import apiConfig from '../../config/apiConfig';
import apiInstance from '../axios/index';

// eslint-disable-next-line import/prefer-default-export
export const fetchTransferRecords =
  (params?: any) => async (dispatch: Dispatch<TransferRecordDispatchType>) => {
    dispatch({ type: TR_LOADING_START });
    try {
      const getTransferUrl = apiConfig.baseURL + apiConfig.getTransfers.url;

      const response = await apiInstance.get(`${getTransferUrl}`, {
        params,
      });

      if (response.data.data.length !== 0) {
        const { sort } = response.data.data[response.data.data.length - 1];
        dispatch<any>(
          fetchTransferRecords({ size: 4000, from: JSON.stringify(sort) })
        );
        dispatch({
          type: TR_FETCH_SUCCESS,
          payload: response.data.data,
        });
      }

      if (response.data.data.length === 0) {
        dispatch({ type: TR_LOADING_END });
      }
    } catch (err) {
      toast.error('Something went wrong. Could not get Transfer Records');
      dispatch({ type: TR_LOADING_ERROR });
    }
  };
