import React, { FunctionComponent, useMemo } from 'react';
import { Button, ButtonGroup, Modal, Text } from '@nike/eds';

import {
  IActionFailureModalProps,
  IActionFailureResponseItem,
} from '../../redux/types/transferRecordsTypes';

const ActionFailureModal: FunctionComponent<IActionFailureModalProps> = ({
  isOpen,
  onDismiss,
  actionData,
}) => {
  const texts = useMemo(() => {
    if (actionData) {
      switch (actionData.status) {
        case 'confirm':
          return {
            headerText: 'Transfer Confirm Failed',
            message:
              'None of the selected transfers have been confirmed, see below for details',
          };

        case 'cancel':
          return {
            headerText: 'Transfer Cancel Failed',
            message:
              'None of the selected transfers have been cancelled, see below for details',
          };

        default:
          break;
      }
    }

    return {
      headerText: '',
      message: '',
      confirmText: '',
    };
  }, [actionData]);

  return (
    <Modal
      className="action-failure-modal"
      onDismiss={onDismiss}
      isOpen={isOpen}
      headerSlot={<span>{texts.headerText}</span>}
      footerSlot={
        <ButtonGroup>
          <Button onClick={onDismiss} size="small">
            Return to Dashboard
          </Button>
        </ButtonGroup>
      }
    >
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
        }}
      >
        <Text as="p">{texts.message}</Text>
        <div>
          {actionData?.response ? (
            <table className="responses-table">
              <thead>
                <tr>
                  <td>Summary</td>
                  <td>Season</td>
                  <td>Style Number</td>
                  <td>Color</td>
                  <td>Outsole</td>
                </tr>
              </thead>
              <tbody>
                {actionData.response.map((a: IActionFailureResponseItem) => (
                  <tr key={a.reason + a.styleNumber}>
                    <td>{a.reason}</td>
                    <td>{a.seasonYearCode}</td>
                    <td>{a.styleNumber}</td>
                    <td>{a.colorwayCode}</td>
                    <td>{a.outsoleCode}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          ) : null}
        </div>
      </div>
    </Modal>
  );
};

export default ActionFailureModal;
