export const TR_INPUT_TOUCHED_START = 'TR_INPUT_TOUCHED_START';
export const TR_INPUT_TOUCHED_END = 'TR_INPUT_TOUCHED_END';

export const TR_IS_EDIT_MODE_START = 'TR_IS_EDIT_MODE_START';
export const TR_IS_EDIT_MODE_END = 'TR_IS_EDIT_MODE_END';

interface ITransferItemSetInputTouchedStart {
  type: typeof TR_INPUT_TOUCHED_START;
  payload: boolean;
}

interface ITransferItemSetInputTouchedEnd {
  type: typeof TR_INPUT_TOUCHED_END;
}

interface IEditModeStart {
  type: typeof TR_IS_EDIT_MODE_START;
  payload: boolean;
}

interface IEditModeEnd {
  type: typeof TR_IS_EDIT_MODE_END;
}

export type InputDispatchType =
  | ITransferItemSetInputTouchedStart
  | ITransferItemSetInputTouchedEnd
  | IEditModeEnd
  | IEditModeStart;
