/* eslint-disable prettier/prettier */
const base = {
  appName: 'Sourcing Transfer Tracker',
  description: 'Nike GSM React App Sourcing Transfer Tracker',
  apiUrl: '',
  oAuth: {
    redirect_uri: window.location.origin,
    client_id: 'nike.gsm.sourcingtransfertracker',
    response_type: 'authorization_code',
    scope: 'profile openid email',
    issuer: '',
    qa: false,
  },
  logout: 'http://localhost:3000/'
};

const prodLinks = ['https://d2om49y1bhq3wl.cloudfront.net', 'https://stt-prod-ui.sourcing-prod.nikecloud.com','https://stt.nike.com'];
if (prodLinks.includes(window.location.origin)) {
  base.oAuth.issuer = 'https://nike.okta.com/oauth2/aus27z7p76as9Dz0H1t7';
  base.logout = 'https://nike.okta.com/login/default';
} else {
  base.oAuth.issuer =
    'https://nike-qa.oktapreview.com/oauth2/ausa0mcornpZLi0C40h7';
  base.oAuth.qa = true;
  base.logout = 'https://nike-qa.oktapreview.com/login/default';
}

const config = base;
export default config;
