import {
  HIDE_ERROR,
  ERROR_END,
  SET_COLORWAY_ERROR,
  SET_DUE_DATE_ERROR,
  SET_FROM_FACTORY_ERROR,
  SET_FROM_FACTORY_GROUP_ERROR,
  SET_OUTSOLE_ERROR,
  SET_PCC_ERROR,
  SET_PPG_ERROR,
  SET_STYLE_NUMBER_ERROR,
  SET_TO_FACTORY_ERROR,
  SET_TO_FACTORY_GROUP_ERROR,
  HIDE_COLORWAY_ERROR,
  HIDE_DUE_DATE_ERROR,
  HIDE_FROM_FACTORY_ERROR,
  HIDE_FROM_FACTORY_GROUP_ERROR,
  HIDE_OUTSOLE_ERROR,
  HIDE_PCC_ERROR,
  HIDE_PPG_ERROR,
  HIDE_STYLE_NUMBER_ERROR,
  HIDE_TO_FACTORY_ERROR,
  HIDE_TO_FACTORY_GROUP_ERROR,
  ErrorTransferDispatchType,
  HAS_BLOCKING_ERROR_START,
  HAS_BLOCKING_ERROR_END,
} from '../types/errorTypes';

interface ITransferErrorDefaultState {
  isBlocking: boolean;
  dueDateErrorMessage: string;
  styleNumberErrorMessage: string;
  productColorwayCodeErrorMessage: string;
  productPlanningGroupErrorMessage: string;
  fromFactoryErrorMessage: string;
  toFactoryErrorMessage: string;
  fromFactoryGroupErrorMessage: string;
  toFactoryGroupErrorMessage: string;
  outsoleErrorMessage: string;
  pccCodesErrorMessage: string;
  productColorwayCodeError: boolean;
  styleNumberError: boolean;
  dueDateError: boolean;
  productPlanningGroupError: boolean;
  fromFactoryError: boolean;
  toFactoryError: boolean;
  fromFactoryGroupError: boolean;
  toFactoryGroupError: boolean;
  outsoleError: boolean;
  pccCodesError: boolean;
  hasBlockingError: boolean;
}

const defaultState: ITransferErrorDefaultState = {
  isBlocking: false,
  productColorwayCodeErrorMessage: '',
  styleNumberErrorMessage: '',
  dueDateErrorMessage: '',
  productPlanningGroupErrorMessage: '',
  fromFactoryErrorMessage: '',
  toFactoryErrorMessage: '',
  fromFactoryGroupErrorMessage: '',
  toFactoryGroupErrorMessage: '',
  outsoleErrorMessage: '',
  pccCodesErrorMessage: '',
  productColorwayCodeError: false,
  styleNumberError: false,
  dueDateError: false,
  productPlanningGroupError: false,
  fromFactoryError: false,
  toFactoryError: false,
  fromFactoryGroupError: false,
  toFactoryGroupError: false,
  outsoleError: false,
  pccCodesError: false,
  hasBlockingError: false,
};

const errorReducer = (
  state: ITransferErrorDefaultState = defaultState,
  action: ErrorTransferDispatchType
) => {
  switch (action.type) {
    case HAS_BLOCKING_ERROR_START:
      return { ...state, hasBlockingError: action.payload };
    case HAS_BLOCKING_ERROR_END:
      return { ...state, hasBlockingError: false };
    case SET_DUE_DATE_ERROR:
      return {
        ...state,
        dueDateErrorMessage: action.payload,
        dueDateError: true,
        isBlocking: false,
      };
    case SET_COLORWAY_ERROR:
      return {
        ...state,
        productColorwayCodeErrorMessage: action.payload,
        productColorwayCodeError: true,
        isBlocking: false,
      };
    case SET_FROM_FACTORY_ERROR:
      return {
        ...state,
        fromFactoryErrorMessage: action.payload,
        fromFactoryError: true,
        isBlocking: false,
      };
    case SET_FROM_FACTORY_GROUP_ERROR:
      return {
        ...state,
        fromFactoryGroupErrorMessage: action.payload,
        fromFactoryGroupError: true,
        isBlocking: false,
      };
    case SET_OUTSOLE_ERROR:
      return {
        ...state,
        outsoleErrorMessage: action.payload,
        outsoleError: true,
        isBlocking: false,
      };
    case SET_PCC_ERROR:
      return {
        ...state,
        pccCodesErrorMessage: action.payload,
        pccCodesError: true,
        isBlocking: false,
      };
    case SET_PPG_ERROR:
      return {
        ...state,
        productPlanningGroupErrorMessage: action.payload,
        productPlanningGroupError: true,
        isBlocking: false,
      };
    case SET_STYLE_NUMBER_ERROR:
      return {
        ...state,
        styleNumberErrorMessage: action.payload,
        styleNumberError: true,
        isBlocking: false,
      };
    case SET_TO_FACTORY_ERROR:
      return {
        ...state,
        toFactoryErrorMessage: action.payload,
        toFactoryError: true,
        isBlocking: false,
      };
    case SET_TO_FACTORY_GROUP_ERROR:
      return {
        ...state,
        toFactoryGroupErrorMessage: action.payload,
        toFactoryGroupError: true,
        isBlocking: false,
      };
    case HIDE_DUE_DATE_ERROR:
      return {
        ...state,
        dueDateErrorMessage: '',
        dueDateError: false,
        isBlocking: false,
      };
    case HIDE_COLORWAY_ERROR:
      return {
        ...state,
        productColorwayCodeErrorMessage: '',
        productColorwayCodeError: false,
        isBlocking: false,
      };
    case HIDE_FROM_FACTORY_ERROR:
      return {
        ...state,
        fromFactoryErrorMessage: '',
        fromFactoryError: false,
        isBlocking: false,
      };
    case HIDE_FROM_FACTORY_GROUP_ERROR:
      return {
        ...state,
        fromFactoryGroupErrorMessage: '',
        fromFactoryGroupError: false,
        isBlocking: false,
      };
    case HIDE_OUTSOLE_ERROR:
      return {
        ...state,
        outsoleErrorMessage: '',
        outsoleError: false,
        isBlocking: false,
      };
    case HIDE_PCC_ERROR:
      return {
        ...state,
        pccCodesErrorMessage: '',
        pccCodesError: false,
        isBlocking: false,
      };
    case HIDE_PPG_ERROR:
      return {
        ...state,
        productPlanningGroupErrorMessage: '',
        productPlanningGroupError: false,
        isBlocking: false,
      };
    case HIDE_STYLE_NUMBER_ERROR:
      return {
        ...state,
        styleNumberErrorMessage: '',
        styleNumberError: false,
        isBlocking: false,
      };
    case HIDE_TO_FACTORY_ERROR:
      return {
        ...state,
        toFactoryErrorMessage: '',
        toFactoryError: false,
        isBlocking: false,
      };
    case HIDE_TO_FACTORY_GROUP_ERROR:
      return {
        ...state,
        toFactoryGroupErrorMessage: '',
        toFactoryGroupError: false,
        isBlocking: false,
      };
    case HIDE_ERROR:
      return {
        ...state,
        isBlocking: false,
        dueDateErrorMessage: '',
        styleNumberErrorMessage: '',
        productColorwayCodeErrorMessage: '',
        productPlanningGroupErrorMessage: '',
        fromFactoryErrorMessage: '',
        toFactoryErrorMessage: '',
        fromFactoryGroupErrorMessage: '',
        toFactoryGroupErrorMessage: '',
        outsoleErrorMessage: '',
        pccCodesErrorMessage: '',
        productColorwayCodeError: false,
        styleNumberError: false,
        dueDateError: false,
        productPlanningGroupError: false,
        fromFactoryError: false,
        toFactoryError: false,
        fromFactoryGroupError: false,
        toFactoryGroupError: false,
        outsoleError: false,
        pccCodesError: false,
      };
    case ERROR_END:
      return state;
    default:
      return state;
  }
};

export default errorReducer;
