import {
  TR_DELETE_START,
  TR_DELETE_END,
  TR_DELETE_SUCCESS,
  TR_DELETE_FAILED,
  DeleteTransferDispatchType,
  TR_DELETE_RESET,
} from '../types/deleteTransferRecordTypes';

interface ITransferCommentDefaultState {
  deleteLoading: boolean;
  deleteSuccess: boolean;
  deleteError: boolean;
}

const defaultState: ITransferCommentDefaultState = {
  deleteLoading: false,
  deleteSuccess: false,
  deleteError: false,
};

const transferDeleteReducer = (
  state: ITransferCommentDefaultState = defaultState,
  action: DeleteTransferDispatchType
): ITransferCommentDefaultState => {
  switch (action.type) {
    case TR_DELETE_START:
      return { ...state, deleteLoading: true };
    case TR_DELETE_SUCCESS:
      return {
        ...state,
        deleteLoading: false,
        deleteSuccess: true,
      };
    case TR_DELETE_END:
      return {
        ...state,
        deleteLoading: false,
      };

    case TR_DELETE_FAILED:
      return {
        ...state,
        deleteError: true,
      };

    case TR_DELETE_RESET:
      return {
        ...defaultState,
      };
    default:
      return state;
  }
};

export default transferDeleteReducer;
