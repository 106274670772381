import { Select } from '@nike/eds';
import { FC } from 'react';
import { Props as ReactSelectProps } from 'react-select';
import { useAppDispatch, useAppSelector } from '../../../redux/hooks';
import { roles } from '../../common/RolesConstants';
import { updateInputTouched } from '../../../redux/actions/inputActions';
import { ITransferData } from '../NewTranferInterface';

interface FieldProps {
  transferData: any | ITransferData;
  setTransferData: (params: any) => any;
}

const Season: FC<FieldProps> = ({ transferData, setTransferData }) => {
  const [transferItemData, userRole, seasonList, isEditMode] = useAppSelector(
    state => [
      state.transferRecordItem.transferItemData.source,
      state.userRole.role,
      state.newTransfer.seasonList,
      state.input.isEditMode,
    ]
  );

  const dispatch = useAppDispatch();

  const handleSelectInputChange: ReactSelectProps['onChange'] = (
    value: any
  ) => {
    setTransferData({ ...transferData, season: value });

    dispatch<any>(updateInputTouched(true));
  };

  return (
    <Select
      options={seasonList}
      id="season-select"
      label="Season"
      name="season"
      onChange={handleSelectInputChange}
      value={transferData.season}
      isDisabled={
        !transferData?.transferType?.value ||
        ((userRole === roles.factory || userRole === roles.pmo) &&
          isEditMode &&
          transferItemData.status !== 'Factory' &&
          transferItemData.status !== 'Draft')
      }
    />
  );
};

export default Season;
