import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import { LicenseManager } from 'ag-grid-enterprise';
import App from './root/App';
import './index.scss';
import store from './redux/store';
import reportWebVitals from './reportWebVitals';
import '@nike/eds/dist/index.css';
import 'react-sliding-pane/dist/react-sliding-pane.css';

LicenseManager.setLicenseKey(
  'CompanyName=Insight (Orem, UT)_on_behalf_of_Nike-Suntech 1,LicensedApplication=M&S STT,LicenseType=SingleApplication,LicensedConcurrentDeveloperCount=1,LicensedProductionInstancesCount=0,AssetReference=AG-035909,SupportServicesEnd=20_December_2023_[v2]_MTcwMzAzMDQwMDAwMA==41f9dfd17963252fd8ef6dac3a2aac6e'
);

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <Provider store={store}>
    <App />
  </Provider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.warn))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
