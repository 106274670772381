import {
  SEASON_LIST_FETCH_SUCCESS,
  NewTransferDispatchType,
  STYLE_NUMBER_LIST_FETCH_SUCCESS,
  STYLE_NUMBER_LIST_BY_SEASON_FETCH_SUCCESS,
  PRODUCT_COLORWAY_CODE_LIST_FETCH_SUCCESS,
  PRODUCT_PLANNING_GROUP_FETCH_SUCCESS,
  PRODUCT_PLANNING_GROUP_FETCH_CLEAR,
  PRODUCT_PLANNING_GROUP_LIST_FETCH_SUCCESS,
  IFactoryItem,
  TO_FACTORY_LIST_FETCH_SUCCESS,
  FROM_FACTORY_LIST_FETCH_SUCCESS,
  IProductInfoType,
  PRODUCT_INFO_FETCH_SUCCESS,
  CLEAR_PRODUCT_INFO,
  PCC_CODES_FETCH_SUCCESS,
  IPccCodesType,
  POST_TRANSFER_DATA_START,
  POST_TRANSFER_DATA_SUCCESS,
  POST_TRANSFER_DATA_END,
  POST_TRANSFER_DATA_ERROR,
  POST_TRANSFER_DATA_RESET,
  OUTSOLE_CODES_FETCH_SUCCESS,
  OUTSOLE_CODES_FETCH_LOADING,
  RESET_NEW_TRANSFER_DATA,
  PRODUCT_CODE_LIST_FETCH_SUCCESS,
  STYLE_NAME_FETCH_SUCCESS,
  STYLE_NAME_FETCH_CLEAR,
  ALL_TO_FACTORY_LIST_FETCH_SUCCESS,
  PCC_CODES_FETCH_LOADING,
} from '../types/newTransferTypes';
import { sortByQuarterYear } from '../../utils/sortFunctions';

interface ITransferRecordDefaultState {
  loading: boolean;
  pccLoading: boolean;
  seasonList: { value: string; label: string }[];
  productCodeList: { value: string; label: string }[];
  styleNumberList: { value: string; label: string }[];
  styleName: string;
  productColorwayCodeList: { value: string; label: string }[];
  productInfo: IProductInfoType | null;
  ppg: { value: string; label: string } | null;
  productPlanningGroupList: { value: string; label: string }[];
  toFactoryList: IFactoryItem[];
  fromFactoryList: IFactoryItem[];
  pccCodes: IPccCodesType | null;
  outsoleCodes: string[];
  postTransferData: {
    success: boolean;
    error: boolean;
    alreadyExists: boolean;
    data: any;
  };
  newTransferDone: boolean;
  styleListBySeason: string[];
  allFactoryList: IFactoryItem[];
  isLoadingScreen: boolean;
}

const defaultState: ITransferRecordDefaultState = {
  pccLoading: false,
  loading: false,
  seasonList: [],
  productCodeList: [],
  styleNumberList: [],
  styleName: '',
  productColorwayCodeList: [],
  productInfo: null,
  ppg: null,
  productPlanningGroupList: [],
  toFactoryList: [],
  fromFactoryList: [],
  pccCodes: null,
  outsoleCodes: [],
  postTransferData: {
    success: false,
    error: false,
    alreadyExists: false,
    data: {},
  },
  newTransferDone: false,
  styleListBySeason: [],
  allFactoryList: [],
  isLoadingScreen: false,
};

const newTransferReducer = (
  state: ITransferRecordDefaultState = defaultState,
  action: NewTransferDispatchType
): ITransferRecordDefaultState => {
  switch (action.type) {
    case SEASON_LIST_FETCH_SUCCESS:
      return {
        ...state,
        seasonList: action.payload
          .sort(sortByQuarterYear)
          .map((item: any) => ({ value: item, label: item })),
      };
    case PRODUCT_CODE_LIST_FETCH_SUCCESS:
      return {
        ...state,
        productCodeList: action.payload
          .map((item: any) => ({ value: item, label: item }))
          .sort(),
      };
    case STYLE_NUMBER_LIST_FETCH_SUCCESS:
      return {
        ...state,
        styleNumberList: action.payload
          .map((item: any) => ({ value: item, label: item }))
          .sort(),
      };
    case STYLE_NUMBER_LIST_BY_SEASON_FETCH_SUCCESS:
      return {
        ...state,
        styleListBySeason: action.payload.sort(),
      };
    case STYLE_NAME_FETCH_SUCCESS:
      return {
        ...state,
        styleName: action.payload,
      };
    case STYLE_NAME_FETCH_CLEAR:
      return {
        ...state,
        styleName: '',
      };
    case PRODUCT_COLORWAY_CODE_LIST_FETCH_SUCCESS:
      return {
        ...state,
        productColorwayCodeList: action.payload
          .filter((item: any) => item !== '')
          .map((item: any) => ({ value: item, label: item }))
          .sort(),
      };
    case PRODUCT_INFO_FETCH_SUCCESS:
      return {
        ...state,
        productInfo: action.payload,
      };
    case CLEAR_PRODUCT_INFO:
      return {
        ...state,
        productInfo: null,
      };
    case PRODUCT_PLANNING_GROUP_FETCH_SUCCESS:
      return {
        ...state,
        ppg: {
          value: action.payload,
          label: action.payload,
        },
      };
    case PRODUCT_PLANNING_GROUP_FETCH_CLEAR:
      return {
        ...state,
        ppg: {
          value: '',
          label: '',
        },
      };
    case PRODUCT_PLANNING_GROUP_LIST_FETCH_SUCCESS:
      return {
        ...state,
        productPlanningGroupList: action.payload
          .map((item: any) => ({ value: item, label: item }))
          .sort(),
      };
    case TO_FACTORY_LIST_FETCH_SUCCESS:
      return {
        ...state,
        toFactoryList: action.payload.toFactoryAssignments,
      };
    case ALL_TO_FACTORY_LIST_FETCH_SUCCESS:
      return {
        ...state,
        allFactoryList: action.payload.all,
      };
    case FROM_FACTORY_LIST_FETCH_SUCCESS:
      return {
        ...state,
        fromFactoryList: action.payload.fromFactoryAssignments,
      };
    case PCC_CODES_FETCH_LOADING:
      return {
        ...state,
        pccLoading: true,
      };
    case PCC_CODES_FETCH_SUCCESS:
      return {
        ...state,
        pccLoading: false,
        pccCodes: action.payload,
      };
    case POST_TRANSFER_DATA_START:
      return {
        ...state,
        isLoadingScreen: true,
      };
    case POST_TRANSFER_DATA_SUCCESS:
      return {
        ...state,
        postTransferData: {
          success: true,
          error: false,
          alreadyExists: false,
          data: action.payload,
        },
        isLoadingScreen: false,
      };
    case POST_TRANSFER_DATA_END:
      return {
        ...state,
        newTransferDone: true,
        isLoadingScreen: false,
      };
    case POST_TRANSFER_DATA_ERROR:
      return {
        ...state,
        postTransferData: {
          ...state.postTransferData,
          error: true,
          alreadyExists: true,
          data: action.payload,
        },
        isLoadingScreen: false,
      };
    case POST_TRANSFER_DATA_RESET:
      return {
        ...state,
        postTransferData: defaultState.postTransferData,
      };
    case RESET_NEW_TRANSFER_DATA:
      return {
        ...defaultState,
      };
    case OUTSOLE_CODES_FETCH_SUCCESS:
      return {
        ...state,
        loading: false,
        outsoleCodes: action.payload,
      };
    case OUTSOLE_CODES_FETCH_LOADING:
      return {
        ...state,
        loading: true,
      };
    default:
      return state;
  }
};

export default newTransferReducer;
