import { IconButton } from '@nike/eds';
import { useNavigate } from 'react-router-dom';

import { ReactComponent as Flag } from '../../assets/images/flag.svg';

interface IGridLinksCellProps {
  value: string;
  colDef: {
    field: string;
    filter: string;
    headerName?: string;
  };
}

const GridLinksCellRenderer = (props: IGridLinksCellProps) => {
  const navigate = useNavigate();

  const { colDef, value } = props;
  const tranferViewBtnClick = () => {
    navigate(`/transfer-view/${value}`);
  };
  return (
    <span>
      {colDef.field === '_id' && (
        <IconButton
          icon="Edit"
          variant="ghost"
          className="eds-color--link"
          onClick={() => tranferViewBtnClick()}
          label={`Link to transfer ${value}`}
        />
      )}
      {colDef.field === 'importantFlag' && value && <Flag />}
    </span>
  );
};

export default GridLinksCellRenderer;
