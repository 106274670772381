import {
  Table,
  TableHeading,
  TableCell,
  Icon,
  TextField,
  Button,
  Text,
  Tooltip,
} from '@nike/eds';
import { useEffect, useRef, useState } from 'react';
import moment from 'moment';
import { toast } from 'react-toastify';
import { useAppSelector, useAppDispatch } from '../../redux/hooks';
import { roles } from '../common/RolesConstants';
import {
  downloadAllTransferAttachments,
  downloadTransferAttachment,
  transferDownloadAttachmentReset,
} from '../../redux/actions/transferAttachmentActions';
import DeleteActionModal from './DeleteAttachmentModal';
import {
  deleteTransferAttachment,
  uploadTransferAttachment,
} from '../../redux/actions/transferRecordItemActions';
import { TransferRecordItemAttachment } from '../../redux/types/transferRecordItemTypes';

const AttachmentsTab = (props: {
  attachments: TransferRecordItemAttachment[];
}) => {
  const dispatch = useAppDispatch();
  const fileInput = useRef<HTMLInputElement>(null);
  const [uploadNewFileName, setUploadNewFileName] = useState<string>('');
  const [downloadButtonClicked, setDownloadButtonClicked] =
    useState<boolean>(false);
  const [isDeleteAttachmentModalOpen, setIsDeleteAttachmentModalOpen] =
    useState<boolean>(false);

  const [currentAttachment, setCurrentAttachment] =
    useState<TransferRecordItemAttachment>();
  const { attachments } = props;

  const [
    userRole,
    userId,
    transferId,
    transferAttachmentsData,
    isEditMode,
    transferItemData,
  ] = useAppSelector(state => [
    state.userRole.role,
    state.userId.id,
    state.transferRecordItem.transferItemData.source?.transferId,
    state.transferAttachments,
    state.input.isEditMode,
    state.transferRecordItem.transferItemData.source,
  ]);

  useEffect(() => {
    if (
      downloadButtonClicked &&
      transferAttachmentsData.downloadTransferAttachmentData.length > 0
    ) {
      const urlsInfo: any = [
        ...transferAttachmentsData.downloadTransferAttachmentData,
      ];
      const interval = setInterval(() => {
        const downloadUrl = urlsInfo.pop();

        const link = document.createElement('a');
        link.href = downloadUrl.url;
        link.setAttribute('download', downloadUrl.fileName); // change "file.pdf" according to saved name you want, give extension according to filetype
        document.body.appendChild(link);
        link.click();
        link.remove();
        if (urlsInfo.length === 0) {
          clearInterval(interval);
          dispatch<any>(transferDownloadAttachmentReset());
        }
      }, 1000);
    }
  }, [
    transferAttachmentsData.downloadTransferAttachmentData,
    downloadButtonClicked,
  ]);

  const onDeleteAttachmentBtnClick = (
    attachment: TransferRecordItemAttachment
  ) => {
    // delete action
    setCurrentAttachment(attachment);
    setIsDeleteAttachmentModalOpen(true);
  };

  const handleDeleteAttachmentConfirm = () => {
    if (currentAttachment?.createdBy === userId) {
      dispatch<any>(
        deleteTransferAttachment(
          transferId,
          currentAttachment?.attachmentUrl || ''
        )
      );
    } else {
      toast.error('Only the user who uploaded the document can delete it.');
      setIsDeleteAttachmentModalOpen(false);
    }
  };

  const handleDeleteAttachmentCancel = () => {
    setIsDeleteAttachmentModalOpen(false);
  };

  const onDownloadAttachmentBtnClick = (
    transferId: string,
    attachment: TransferRecordItemAttachment
  ) => {
    dispatch<any>(
      downloadTransferAttachment(transferId, attachment.attachmentUrl)
    );
    setDownloadButtonClicked(true);
    setCurrentAttachment(attachment);
  };

  const onDownloadAllAttachmentBtnClick = (
    transferId: string,
    fileNames: string[]
  ) => {
    dispatch<any>(downloadAllTransferAttachments(transferId, fileNames));
    setDownloadButtonClicked(true);
  };

  const onUploadAttachmentBtnClick = () => {
    fileInput?.current?.click();
  };

  const handleFileChange = (e: any) => {
    setUploadNewFileName(e.target.files[0].name);
    if (e.target.files[0]) {
      if (
        !transferItemData.attachments?.some(
          element => element.attachmentUrl === e.target.files[0].name
        )
      ) {
        dispatch<any>(uploadTransferAttachment(transferId, e.target.files[0]));
        setUploadNewFileName('');
      } else {
        toast.error(
          'This filename already exists, please change filename and upload again'
        );
        setUploadNewFileName('');
      }
    }
  };

  return (
    <div>
      <DeleteActionModal
        onConfirm={handleDeleteAttachmentConfirm}
        isOpen={isDeleteAttachmentModalOpen}
        onDismiss={handleDeleteAttachmentCancel}
      />
      {userRole !== roles.planning && (
        <div
          className="eds-grid eds-grid--m-cols-6"
          style={{ paddingBottom: '50px' }}
        >
          <div className="eds-grid--m-col-5">
            <input
              type="file"
              ref={fileInput}
              id="inputFileField"
              hidden
              onChange={e => handleFileChange(e)}
              onClick={(e: any) => {
                e.target.value = null;
              }}
            />
            <TextField
              id="uploadFileField"
              label=""
              value={uploadNewFileName}
              placeholder="Upload New Attachment"
            />
          </div>
          <div className="eds-grid--m-col-1">
            {isEditMode ? (
              <Tooltip
                bodySlot="Button is disabled when editing form above"
                placement="right"
                enableFocus
              >
                <Button
                  size="medium"
                  variant="primary"
                  className="action-buttons"
                  style={{ marginTop: '15px' }}
                  onClick={() => onUploadAttachmentBtnClick()}
                  disabled={isEditMode}
                >
                  Browse
                </Button>
              </Tooltip>
            ) : (
              <Button
                size="medium"
                variant="primary"
                className="action-buttons"
                style={{ marginTop: '15px' }}
                onClick={() => onUploadAttachmentBtnClick()}
                disabled={isEditMode}
              >
                Browse
              </Button>
            )}
          </div>
        </div>
      )}
      <hr />
      <div
        className="eds-grid eds-grid--m-cols-6"
        style={{ paddingTop: '50px' }}
      >
        <div className="eds-grid--m-col-5">
          <Text font="title-4" as="h4">
            Uploaded Attachments
          </Text>
        </div>
        <div className="eds-grid--m-col-1">
          <Button
            size="medium"
            variant="primary"
            className="action-buttons"
            onClick={() =>
              onDownloadAllAttachmentBtnClick(
                transferId || '',
                attachments.map(attachment => attachment.attachmentUrl)
              )
            }
          >
            Download All
          </Button>
        </div>
      </div>
      <br />
      {attachments && attachments.length > 0 ? (
        <div className="eds-grid eds-grid--m-cols-4">
          <div className="eds-grid--m-col-4">
            <Table style={{ backgroundColor: 'transparent' }}>
              <thead>
                <tr>
                  <TableHeading>File</TableHeading>
                  <TableHeading>Date Uploaded</TableHeading>
                  <TableHeading>Uploaded By</TableHeading>
                  <TableHeading>Delete</TableHeading>
                </tr>
              </thead>
              <tbody>
                {attachments.map((row: TransferRecordItemAttachment) => (
                  <tr key={`${row.createdTimestamp}${Math.random()}`}>
                    <TableCell
                      style={{ cursor: 'pointer', textDecoration: 'underline' }}
                      onClick={() =>
                        onDownloadAttachmentBtnClick(transferId || '', row)
                      }
                    >
                      {row.fileName}
                    </TableCell>
                    <TableCell>
                      {moment
                        .utc(row.createdTimestamp)
                        .local()
                        .format('MM/DD/YYYY [at] hh:mm A')}
                    </TableCell>
                    <TableCell>{row.createdBy}</TableCell>

                    <TableCell>
                      <Icon
                        name="Close"
                        onClick={() => onDeleteAttachmentBtnClick(row)}
                        enableFocus
                      />
                    </TableCell>
                  </tr>
                ))}
              </tbody>
            </Table>
          </div>
        </div>
      ) : (
        <div className="eds-spacing--p-16 eds-color--link-disable">
          No Attachments
        </div>
      )}
    </div>
  );
};

export default AttachmentsTab;
