export const TR_DOWNLOAD_ATTACHMENT_START = 'TR_DOWNLOAD_ATTACHMENT_START';
export const TR_DOWNLOAD_ATTACHMENT_END = 'TR_DOWNLOAD_ATTACHMENT_END';
export const TR_DOWNLOAD_ATTACHMENT_SUCCESS = 'TR_DOWNLOAD_ATTACHMENT_SUCCESS';
export const TR_DOWNLOAD_ATTACHMENT_FAILED = 'TR_DOWNLOAD_ATTACHMENT_FAILED';

export const TR_DOWNLOAD_ATTACHMENT_RESET = 'TR_DOWNLOAD_ATTACHMENT_RESET';
export const TR_UPLOAD_ATTACHMENT_RESET = 'TR_UPLOAD_ATTACHMENT_RESET';
export const TR_DELETE_ATTACHMENT_RESET = 'TR_DELETE_ATTACHMENT_RESET';

interface ITransferDownloadAttachmentLoadingStart {
  type: typeof TR_DOWNLOAD_ATTACHMENT_START;
}
interface ITransferDownloadAttachmentLoadingEnd {
  type: typeof TR_DOWNLOAD_ATTACHMENT_END;
}
interface ITransferDownloadAttachmentSuccess {
  type: typeof TR_DOWNLOAD_ATTACHMENT_SUCCESS;
  payload: any;
}
interface ITransferDownloadAttachmentFailed {
  type: typeof TR_DOWNLOAD_ATTACHMENT_FAILED;
  payload: number;
}
interface ITransferDownloadAttachmentReset {
  type: typeof TR_DOWNLOAD_ATTACHMENT_RESET;
}
interface ITransfeUploadAttachmentReset {
  type: typeof TR_UPLOAD_ATTACHMENT_RESET;
}
interface ITransferDeleteAttachmentReset {
  type: typeof TR_DELETE_ATTACHMENT_RESET;
}

export interface IActionConfirmModalProps {
  onConfirm: () => void;
  isOpen: boolean;
  onDismiss: () => void;
}

export type TransferAttachmentDispatchType =
  | ITransferDownloadAttachmentFailed
  | ITransferDownloadAttachmentReset
  | ITransferDownloadAttachmentLoadingEnd
  | ITransferDownloadAttachmentLoadingStart
  | ITransferDownloadAttachmentSuccess
  | ITransfeUploadAttachmentReset
  | ITransferDeleteAttachmentReset;

export enum ResponseCode {
  OKAY = 200,
  CREATED = 201,
}
