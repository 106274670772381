/* eslint-disable import/no-cycle */
import {
  TR_INPUT_TOUCHED_START,
  TR_INPUT_TOUCHED_END,
  InputDispatchType,
  TR_IS_EDIT_MODE_START,
  TR_IS_EDIT_MODE_END,
} from '../types/inputTypes';

interface IInputDefaultState {
  isTouched: boolean;
  isEditMode: boolean;
}

const defaultState: IInputDefaultState = {
  isTouched: false,
  isEditMode: false,
};

const inputReducer = (
  state: IInputDefaultState = defaultState,
  action: InputDispatchType
): IInputDefaultState => {
  switch (action.type) {
    case TR_INPUT_TOUCHED_START:
      return { ...state, isTouched: action.payload };
    case TR_INPUT_TOUCHED_END:
      return { ...state, isTouched: false };
    case TR_IS_EDIT_MODE_START:
      if (action.payload === false && action.payload !== undefined) {
        return { ...state, isEditMode: false };
      }
      return { ...state, isEditMode: !state.isEditMode };
    case TR_IS_EDIT_MODE_END:
      return { ...state, isEditMode: false };
    default:
      return state;
  }
};

export default inputReducer;
