import { combineReducers } from 'redux';

import transferRecordReducer from './reducers/transferRecordReducer';
import newTransferReducer from './reducers/newTransferReducer';
import transferRecordItemReducer from './reducers/transferRecordItemReducer';
import userRoleReducer from './reducers/userRoleReducers';
import userIdReducer from './reducers/userIdReducers';
import updateTransferStatusReducer from './reducers/updateTransferStatusReducer';
import transferDeleteReducer from './reducers/deleteTransferRecordReducer';
import transferAttachmentReducer from './reducers/transferAttachmentReducer';
import errorReducer from './reducers/errorReducer';
import inputReducer from './reducers/inputReducer';
import userFilterReducer from './reducers/userFilterReducer';

const RootReducer = combineReducers({
  transferRecords: transferRecordReducer,
  newTransfer: newTransferReducer,
  transferRecordItem: transferRecordItemReducer,
  updateTransferStatus: updateTransferStatusReducer,
  userRole: userRoleReducer,
  userId: userIdReducer,
  transferDelete: transferDeleteReducer,
  transferAttachments: transferAttachmentReducer,
  error: errorReducer,
  input: inputReducer,
  filter: userFilterReducer,
});

export default RootReducer;
