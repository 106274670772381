import { Select } from '@nike/eds';
import { FC } from 'react';
import { Props as ReactSelectProps } from 'react-select';
import { useAppDispatch, useAppSelector } from '../../../redux/hooks';
import { roles } from '../../common/RolesConstants';
import {
  TRANSFER_TYPE_OUTSOLE_COMPLETE,
  TRANSFER_TYPE_TOOLING,
  ITransferData,
} from '../NewTranferInterface';
import {
  fetchSourcingStyleNumberListBySeason,
  fetchStyleNumberList,
} from '../../../redux/actions/newTransferActions';
import { updateInputTouched } from '../../../redux/actions/inputActions';
import {
  errorActionCreator,
  updateHasBlockingError,
} from '../../../redux/actions/errorActions';
import { validateOnChange } from '../Validation';

interface FieldProps {
  transferData: any | ITransferData;
  setTransferData: (params: any) => any;
}

const StyleNumber: FC<FieldProps> = ({ transferData, setTransferData }) => {
  const [
    transferItemData,
    userRole,
    isEditMode,
    styleNumberList,
    error,
    styleListBySeason,
  ] = useAppSelector(state => [
    state.transferRecordItem.transferItemData.source,
    state.userRole.role,
    state.input.isEditMode,
    state.newTransfer.styleNumberList,
    state.error,
    state.newTransfer.styleListBySeason,
  ]);

  const dispatch = useAppDispatch();

  const handleSelectInputChange: ReactSelectProps['onChange'] = (
    value: any,
    actionMeta: any
  ) => {
    const inputName: string = actionMeta?.name || '';
    const validationData = {
      transferData,
      styleListBySeason,
    };

    setTransferData({ ...transferData, styleNumber: value });

    dispatch<any>(updateHasBlockingError(false));

    const validation = validateOnChange(
      actionMeta,
      value,
      userRole,
      validationData
    );

    dispatch<any>(errorActionCreator(inputName, validation.message));

    if (validation.blockingError) {
      dispatch<any>(updateHasBlockingError(true));
    }

    dispatch<any>(updateInputTouched(true));
  };

  const restoreStyleNumberList: any = () => {
    if (
      (userRole === roles.factory || userRole === roles.pmo) &&
      transferItemData.seasonYearCode
    ) {
      dispatch<any>(
        fetchSourcingStyleNumberListBySeason(transferItemData.seasonYearCode)
      );
    } else {
      dispatch<any>(fetchStyleNumberList());
    }
  };

  const getSpecificStyleNumList: ReactSelectProps['onInputChange'] = (
    value: any
  ) => {
    if (value.length > 2) {
      if (
        (userRole === roles.factory || userRole === roles.pmo) &&
        transferItemData.seasonYearCode
      ) {
        dispatch<any>(
          fetchSourcingStyleNumberListBySeason(
            transferItemData.seasonYearCode,
            value
          )
        );
      } else {
        dispatch<any>(fetchStyleNumberList(value));
      }
    }
    dispatch<any>(updateInputTouched(true));
  };

  return (
    <Select
      isClearable
      options={styleNumberList}
      id="style-number-select"
      label="Style Number"
      name="styleNumber"
      onChange={handleSelectInputChange}
      onInputChange={getSpecificStyleNumList}
      onBlur={restoreStyleNumberList}
      value={transferData.styleNumber}
      isDisabled={
        !transferData?.season?.value ||
        !transferData?.transferType?.value ||
        transferData?.transferType?.value === TRANSFER_TYPE_TOOLING ||
        transferData?.transferType?.value === TRANSFER_TYPE_OUTSOLE_COMPLETE ||
        ((userRole === roles.factory || userRole === roles.pmo) &&
          isEditMode &&
          isEditMode &&
          transferItemData.status !== 'Factory' &&
          transferItemData.status !== 'Draft')
      }
      hasErrors={error.styleNumberError}
      errorMessage={error.styleNumberErrorMessage}
    />
  );
};

export default StyleNumber;
