import { Select } from '@nike/eds';
import { FC } from 'react';
import { Props as ReactSelectProps } from 'react-select';
import { useAppDispatch, useAppSelector } from '../../../redux/hooks';
import { roles } from '../../common/RolesConstants';
import {
  TRANSFER_TYPE_OUTSOLE_COMPLETE,
  TRANSFER_TYPE_TOOLING,
  ITransferData,
} from '../NewTranferInterface';
import {
  fetchProductCodeList,
  fetchSourcingProductCodeList,
} from '../../../redux/actions/newTransferActions';
import { updateInputTouched } from '../../../redux/actions/inputActions';

interface FieldProps {
  transferData: any | ITransferData;
  setTransferData: (params: any) => any;
}

const ProductCode: FC<FieldProps> = ({ transferData, setTransferData }) => {
  const [transferItemData, userRole, isEditMode, productCodeList] =
    useAppSelector(state => [
      state.transferRecordItem.transferItemData.source,
      state.userRole.role,
      state.input.isEditMode,
      state.newTransfer.productCodeList,
    ]);

  const dispatch = useAppDispatch();

  const handleSelectInputChange: ReactSelectProps['onChange'] = (
    value: any
  ) => {
    setTransferData({ ...transferData, productCode: value });

    dispatch<any>(updateInputTouched(true));
  };

  const getSpecificProductCodeList: ReactSelectProps['onInputChange'] = (
    value: any
  ) => {
    if (value.length > 2) {
      if (userRole === roles.factory && transferData.season?.value) {
        dispatch<any>(
          fetchSourcingProductCodeList(transferData.season.value, value)
        );
      } else if (transferData.season?.value) {
        dispatch<any>(fetchProductCodeList(transferData.season.value, value));
      }
    }
    dispatch<any>(updateInputTouched(true));
  };

  const restoreProductCodeList: any = () => {
    if (userRole === roles.factory && transferItemData.seasonYearCode) {
      dispatch<any>(
        fetchSourcingProductCodeList(transferItemData.seasonYearCode)
      );
    } else if (transferItemData.seasonYearCode) {
      dispatch<any>(fetchProductCodeList(transferItemData.seasonYearCode));
    }
  };

  return (
    <Select
      options={productCodeList}
      id="product-code-select"
      label="Product Code"
      name="productCode"
      onChange={handleSelectInputChange}
      onInputChange={getSpecificProductCodeList}
      onBlur={restoreProductCodeList}
      value={transferData.productCode}
      isDisabled={
        transferData?.transferType?.value === TRANSFER_TYPE_OUTSOLE_COMPLETE ||
        transferData?.transferType?.value === TRANSFER_TYPE_TOOLING ||
        !transferData?.season?.value ||
        ((userRole === roles.factory || userRole === roles.pmo) &&
          isEditMode &&
          isEditMode &&
          transferItemData.status !== 'Factory' &&
          transferItemData.status !== 'Draft')
      }
      isClearable
    />
  );
};

export default ProductCode;
