interface IGridLinksCellProps {
  value: string;
}

const StatusCellRenderer = (props: IGridLinksCellProps) => {
  const { value } = props;
  if (value === 'Confirmed') {
    return <span className="cell-status cell-confirmed"> {value} </span>;
  }
  if (value === 'Sourcing') {
    return <span className="cell-status cell-sourcing"> {value} </span>;
  }
  if (value === 'Factory') {
    return <span className="cell-status cell-factory"> {value} </span>;
  }
  if (value === 'Cancelled') {
    return <span className="cell-status cell-cancelled"> {value} </span>;
  }
  return <span className="cell-status cell-draft"> {value} </span>;
};

export default StatusCellRenderer;
