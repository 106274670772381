import { Dispatch } from 'redux';
import { toast } from 'react-toastify';
import {
  ErrorTransferDispatchType,
  ISubmitValidationObject,
  HIDE_ERROR,
  SET_COLORWAY_ERROR,
  SET_DUE_DATE_ERROR,
  SET_FROM_FACTORY_ERROR,
  SET_FROM_FACTORY_GROUP_ERROR,
  SET_OUTSOLE_ERROR,
  SET_PCC_ERROR,
  SET_PPG_ERROR,
  SET_STYLE_NUMBER_ERROR,
  SET_TO_FACTORY_ERROR,
  SET_TO_FACTORY_GROUP_ERROR,
  HIDE_COLORWAY_ERROR,
  HIDE_DUE_DATE_ERROR,
  HIDE_FROM_FACTORY_ERROR,
  HIDE_FROM_FACTORY_GROUP_ERROR,
  HIDE_OUTSOLE_ERROR,
  HIDE_PCC_ERROR,
  HIDE_PPG_ERROR,
  HIDE_STYLE_NUMBER_ERROR,
  HIDE_TO_FACTORY_ERROR,
  HIDE_TO_FACTORY_GROUP_ERROR,
  SET_SUBMIT_ERRORS,
  ERROR_END,
  HAS_BLOCKING_ERROR_START,
  HAS_BLOCKING_ERROR_END,
} from '../types/errorTypes';

export const updateHasBlockingError =
  (bool: boolean) => async (dispatch: Dispatch<ErrorTransferDispatchType>) => {
    try {
      dispatch({ type: HAS_BLOCKING_ERROR_START, payload: bool });
    } catch (error) {
      dispatch({ type: HAS_BLOCKING_ERROR_END });
    }
  };

export const hideError =
  () => async (dispatch: Dispatch<ErrorTransferDispatchType>) => {
    dispatch({
      type: HIDE_ERROR,
    });
  };

export const errorActionCreator =
  (input: string, message: string) =>
  async (dispatch: Dispatch<ErrorTransferDispatchType>) => {
    try {
      if (input === 'dueDate' && message !== '') {
        dispatch({
          type: SET_DUE_DATE_ERROR,
          payload: message,
        });
      }
      if (input === 'dueDate' && message === '') {
        dispatch({
          type: HIDE_DUE_DATE_ERROR,
        });
      }
      if (input === 'styleNumber' && message !== '') {
        dispatch({
          type: SET_STYLE_NUMBER_ERROR,
          payload: message,
        });
      }
      if (input === 'styleNumber' && message === '') {
        dispatch({
          type: HIDE_STYLE_NUMBER_ERROR,
        });
      }
      if (input === 'productColorwayCode' && message !== '') {
        dispatch({
          type: SET_COLORWAY_ERROR,
          payload: message,
        });
      }
      if (input === 'productColorwayCode' && message === '') {
        dispatch({
          type: HIDE_COLORWAY_ERROR,
        });
      }
      if (input === 'productPlanningGroup' && message !== '') {
        dispatch({
          type: SET_PPG_ERROR,
          payload: message,
        });
      }
      if (input === 'productPlanningGroup' && message === '') {
        dispatch({
          type: HIDE_PPG_ERROR,
        });
      }
      if (input === 'toFactory' && message !== '') {
        dispatch({
          type: SET_TO_FACTORY_ERROR,
          payload: message,
        });
      }
      if (input === 'toFactory' && message === '') {
        dispatch({
          type: HIDE_TO_FACTORY_ERROR,
        });
      }
      if (input === 'fromFactory' && message !== '') {
        dispatch({
          type: SET_FROM_FACTORY_ERROR,
          payload: message,
        });
      }
      if (input === 'fromFactory' && message === '') {
        dispatch({
          type: HIDE_FROM_FACTORY_ERROR,
        });
      }
      if (input === 'fromFactoryGroup' && message !== '') {
        dispatch({
          type: SET_FROM_FACTORY_GROUP_ERROR,
          payload: message,
        });
      }
      if (input === 'fromFactoryGroup' && message === '') {
        dispatch({
          type: HIDE_FROM_FACTORY_GROUP_ERROR,
        });
      }
      if (input === 'toFactoryGroup' && message !== '') {
        dispatch({
          type: SET_TO_FACTORY_GROUP_ERROR,
          payload: message,
        });
      }
      if (input === 'toFactoryGroup' && message === '') {
        dispatch({
          type: HIDE_TO_FACTORY_GROUP_ERROR,
        });
      }
      if (input === 'outsole' && message !== '') {
        dispatch({
          type: SET_OUTSOLE_ERROR,
          payload: message,
        });
      }
      if (input === 'outsole' && message === '') {
        dispatch({
          type: HIDE_OUTSOLE_ERROR,
        });
      }
      if (input === 'pccCodes' && message !== '') {
        dispatch({
          type: SET_PCC_ERROR,
          payload: message,
        });
      }
      if (input === 'pccCodes' && message === '') {
        dispatch({
          type: HIDE_PCC_ERROR,
        });
      }
      dispatch({
        type: ERROR_END,
      });
    } catch (err) {
      toast.error('Could not create error for some reason');
    }
  };

export const hideErrorActionCreator =
  (input: string[]) =>
  async (dispatch: Dispatch<ErrorTransferDispatchType>) => {
    try {
      for (let i = 0; i < input.length; i += 1) {
        if (input[i] === 'dueDate') {
          dispatch({
            type: HIDE_DUE_DATE_ERROR,
          });
        }
        if (input[i] === 'styleNumber') {
          dispatch({
            type: HIDE_STYLE_NUMBER_ERROR,
          });
        }
        if (input[i] === 'productColorwayCode') {
          dispatch({
            type: HIDE_COLORWAY_ERROR,
          });
        }
        if (input[i] === 'productPlanningGroup') {
          dispatch({
            type: HIDE_PPG_ERROR,
          });
        }
        if (input[i] === 'toFactory') {
          dispatch({
            type: HIDE_TO_FACTORY_ERROR,
          });
        }
        if (input[i] === 'fromFactory') {
          dispatch({
            type: HIDE_FROM_FACTORY_ERROR,
          });
        }
        if (input[i] === 'fromFactoryGroup') {
          dispatch({
            type: HIDE_FROM_FACTORY_GROUP_ERROR,
          });
        }
        if (input[i] === 'toFactoryGroup') {
          dispatch({
            type: HIDE_TO_FACTORY_GROUP_ERROR,
          });
        }
        if (input[i] === 'outsole') {
          dispatch({
            type: HIDE_OUTSOLE_ERROR,
          });
        }
        if (input[i] === 'pccCodes') {
          dispatch({
            type: HIDE_PCC_ERROR,
          });
        }
      }
    } catch (err) {
      dispatch({
        type: HIDE_ERROR,
      });
    }
  };

export const submitErrorActionCreator = (input: ISubmitValidationObject) => {
  return {
    type: SET_SUBMIT_ERRORS,
    fromFactoryErrorMessage: input.fromFactory.message,
    fromFactoryError: input.fromFactory.error,
    toFactoryErrorMessage: input.toFactory.message,
    toFactoryError: input.toFactory.error,
    toFactoryGroupErrorMessage: input.toFactoryGroup.message,
    toFactoryGroupError: input.toFactoryGroup.error,
    fromFactoryGroupErrorMessage: input.fromFactoryGroup.message,
    fromFactoryGroupError: input.fromFactoryGroup.error,
    productColorwayCodeErrorMessage: input.productColorwayCode.message,
    productColorwayCodeError: input.productColorwayCode.error,
  };
};
