/* eslint-disable import/no-cycle */
import {
  TR_ITEM_FETCH_SUCCESS,
  TR_ITEM_LOADING_END,
  TR_ITEM_LOADING_START,
  TR_ITEM_FETCH_FAILED,
  TR_POST_COMMENT_SUCCESS,
  TR_POST_COMMENT_FAILED,
  TransferRecordItemDispatchType,
  ITransferRecordItemType,
  TransferRecordFailedType,
  TR_PATCH_FLAG_SUCCESS,
  TR_PUT_TRANSFER_DATA_START,
  TR_PUT_TRANSFER_DATA_SUCCESS,
  TR_PUT_TRANSFER_DATA_FAILED,
  TR_PUT_TRANSFER_DATA_END,
  TR_PATCH_TRANSFER_STATUS_SUCCESS,
  TR_PATCH_TRANSFER_STATUS_FAILED,
  TR_PATCH_TRANSFER_STATUS_START,
  TR_DELETE_ATTACHMENT_START,
  TR_DELETE_ATTACHMENT_SUCCESS,
  TR_DELETE_ATTACHMENT_FAILED,
  TR_DELETE_ATTACHMENT_END,
  TR_ADD_ATTACHMENT_START,
  TR_ADD_ATTACHMENT_SUCCESS,
  TR_ADD_ATTACHMENT_FAILED,
  TR_ADD_ATTACHMENT_END,
  TR_ITEM_DUPLICATE_START,
  TR_ITEM_DUPLICATE_END,
  TR_ITEM_DUPLICATE_SUCCESS,
  TR_ITEM_DUPLICATE_FAILED,
} from '../types/transferRecordItemTypes';

interface ITransferRecordItemDefaultState {
  transferItemLoading: boolean;
  transferItemData: ITransferRecordItemType;
  transferItemError: TransferRecordFailedType;
  transferItemSuccess: boolean;
  transferItemAttachmentSuccess: boolean;
  isLoadingScreen: boolean;
}

const defaultState: ITransferRecordItemDefaultState = {
  transferItemLoading: false,
  transferItemData: {
    id: '',
    source: {
      State: [],
      StateLog: [],
      attachments: [],
      changeLog: [],
      comments: [],
      message: '',
      importantFlag: false,
      seasonYearCode: null,
      fromFactoryCode: null,
      modelName: '',
      toFactoryCode: null,
      styleNumber: null,
      productCode: null,
      productPlanningGroup: null,
      outsoleCode: '',
      tdCode: '',
      engineeringType: null,
      transferReason: null,
      status: '',
      transferType: null,
      dueDate: null,
      colorwayCode: null,
      createdBy: '',
      createdTimestamp: '',
      fromFactoryGroupCode: '',
      modifiedBy: '',
      modifiedTimestamp: '',
      productCreationCenterCode: '',
      productOfferingIdentifier: '',
      stateLog: '',
      styleName: '',
      toFactoryGroupCode: '',
      transferGeos: ['ALL'],
      transferId: '',
      transferNotes: '',
    },
  },
  transferItemError: { status: undefined, message: undefined },
  transferItemSuccess: false,
  transferItemAttachmentSuccess: false,
  isLoadingScreen: false,
};

const trimTransferItemErrorGen = (payload: {
  id?: string;
  source?: object;
  code?: any;
  status?: any;
  data?: any;
  message?: any;
}) => {
  const newError = {
    status: payload.code || payload.status,
    message: payload.data || payload.message,
  };
  return newError;
};

const transferRecordItemReducer = (
  state: ITransferRecordItemDefaultState = defaultState,
  action: TransferRecordItemDispatchType
): ITransferRecordItemDefaultState => {
  switch (action.type) {
    case TR_ITEM_LOADING_START:
      return { ...state, transferItemLoading: true, isLoadingScreen: true };
    case TR_ITEM_LOADING_END:
      return {
        ...state,
        transferItemLoading: false,
        isLoadingScreen: false,
      };
    case TR_ITEM_FETCH_SUCCESS:
      return {
        ...state,
        transferItemData: action.payload || [],
        transferItemLoading: false,
        isLoadingScreen: false,
      };
    case TR_ITEM_FETCH_FAILED:
      return {
        ...state,
        transferItemLoading: false,
        transferItemError: trimTransferItemErrorGen(action.payload),
        isLoadingScreen: false,
      };
    // duplicate
    case TR_ITEM_DUPLICATE_START:
      return { ...state, transferItemLoading: true, isLoadingScreen: true };
    case TR_ITEM_DUPLICATE_END:
      return {
        ...state,
        transferItemLoading: false,
        transferItemData: action.payload || [],
        isLoadingScreen: false,
      };
    case TR_ITEM_DUPLICATE_SUCCESS:
      return {
        ...state,
        transferItemData: action.payload || [],
        transferItemLoading: false,
        isLoadingScreen: false,
      };
    case TR_ITEM_DUPLICATE_FAILED:
      return {
        ...state,
        transferItemLoading: false,
        transferItemError: trimTransferItemErrorGen(action.payload),
        isLoadingScreen: false,
      };
    // comment
    case TR_POST_COMMENT_SUCCESS:
      if (state.transferItemData.source.comments) {
        return {
          ...state,
          transferItemData: {
            ...state.transferItemData,
            source: {
              ...state.transferItemData.source,
              comments: [
                ...state.transferItemData.source.comments,
                action.payload,
              ],
              changeLog: action.payload.source.changeLog,
            },
          },
          isLoadingScreen: false,
        };
      }
      return {
        ...state,
        transferItemData: {
          ...state.transferItemData,
          source: {
            ...state.transferItemData.source,
            comments: [action.payload],
          },
        },
        isLoadingScreen: false,
      };
    case TR_POST_COMMENT_FAILED:
      return {
        ...state,
        isLoadingScreen: false,
      };
    // flag update
    case TR_PATCH_FLAG_SUCCESS:
      return {
        ...state,
        transferItemData: {
          ...state.transferItemData,
          source: {
            ...state.transferItemData.source,
            importantFlag: action.payload.importantFlag,
            changeLog: action.payload.changeLog,
          },
        },
        isLoadingScreen: false,
      };
    case TR_PUT_TRANSFER_DATA_START:
      return {
        ...state,
        transferItemSuccess: false,
        transferItemLoading: true,
        isLoadingScreen: true,
      };
    // save
    case TR_PUT_TRANSFER_DATA_SUCCESS:
      return {
        ...state,
        transferItemSuccess: true,
        transferItemLoading: false,
        transferItemData: {
          ...state.transferItemData,
          source: {
            ...state.transferItemData.source,
            seasonYearCode: action.payload.seasonYearCode,
            fromFactoryCode: action.payload.fromFactoryCode,
            toFactoryCode: action.payload.toFactoryCode,
            styleNumber: action.payload.styleNumber,
            productCode: action.payload.productCode,
            productPlanningGroup: action.payload.productPlanningGroup,
            outsoleCode: action.payload.outsoleCode,
            tdCode: action.payload.tdCode,
            engineeringType: action.payload.engineeringType,
            transferReason: action.payload.transferReason,
            transferType: action.payload.transferType,
            dueDate: action.payload.dueDate,
            colorwayCode: action.payload.colorwayCode,
            fromFactoryGroupCode: action.payload.fromFactoryGroupCode,
            productCreationCenterCode: action.payload.productCreationCenterCode,
            styleName: action.payload.styleName,
            toFactoryGroupCode: action.payload.toFactoryGroupCode,
            transferGeos: action.payload.transferGeos,
            transferNotes: action.payload.transferNotes,
            changeLog: action.payload.changeLog,
          },
        },
        isLoadingScreen: false,
      };
    case TR_PUT_TRANSFER_DATA_FAILED:
      return {
        ...state,
        transferItemSuccess: false,
        isLoadingScreen: false,
      };
    case TR_PUT_TRANSFER_DATA_END:
      return {
        ...state,
        transferItemSuccess: false,
        transferItemLoading: false,
        isLoadingScreen: false,
      };
    // status update
    case TR_PATCH_TRANSFER_STATUS_START:
      return {
        ...state,
        transferItemSuccess: false,
        isLoadingScreen: true,
      };
    case TR_PATCH_TRANSFER_STATUS_FAILED:
      return {
        ...state,
        transferItemSuccess: false,
        isLoadingScreen: false,
      };
    case TR_PATCH_TRANSFER_STATUS_SUCCESS:
      return {
        ...state,
        transferItemData: {
          ...state.transferItemData,
          id: action.payload.id,
          source: {
            ...state.transferItemData.source,
            ...action.payload.source,
          },
        },
        isLoadingScreen: false,
      };
    // attachments
    case TR_DELETE_ATTACHMENT_START:
      return {
        ...state,
        transferItemLoading: true,
        isLoadingScreen: true,
      };
    case TR_DELETE_ATTACHMENT_FAILED:
      return {
        ...state,
        transferItemLoading: false,
        isLoadingScreen: false,
      };
    case TR_DELETE_ATTACHMENT_END:
      return {
        ...state,
        transferItemLoading: false,
        isLoadingScreen: false,
      };
    case TR_DELETE_ATTACHMENT_SUCCESS:
      return {
        ...state,
        transferItemLoading: true,
        transferItemData: {
          ...state.transferItemData,
          source: {
            ...state.transferItemData.source,
            attachments: state.transferItemData.source.attachments.filter(
              (attachments: any) => attachments.attachmentUrl !== action.payload
            ),
          },
        },
        isLoadingScreen: false,
      };
    case TR_ADD_ATTACHMENT_START:
      return {
        ...state,
        transferItemAttachmentSuccess: false,
        isLoadingScreen: true,
      };
    case TR_ADD_ATTACHMENT_FAILED:
      return {
        ...state,
        transferItemLoading: false,
        isLoadingScreen: false,
      };
    case TR_ADD_ATTACHMENT_END:
      return {
        ...state,
        transferItemLoading: false,
        isLoadingScreen: false,
      };
    case TR_ADD_ATTACHMENT_SUCCESS:
      if (state.transferItemData.source.attachments) {
        return {
          ...state,
          transferItemData: {
            ...state.transferItemData,
            source: {
              ...state.transferItemData.source,
              attachments: [...action.payload.source.attachments],
              changeLog: action.payload.source.changeLog,
            },
          },
          isLoadingScreen: false,
        };
      }
      return {
        ...state,
        transferItemData: {
          ...state.transferItemData,
          source: {
            ...state.transferItemData.source,
            attachments: [...action.payload.source.attachments],
          },
        },
        isLoadingScreen: false,
      };
    default:
      return state;
  }
};

export default transferRecordItemReducer;
