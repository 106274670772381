export const SET_DUE_DATE_ERROR = 'SET_DUE_DATE_ERROR';
export const SET_STYLE_NUMBER_ERROR = 'SET_STYLE_NUMBER_ERROR';
export const SET_COLORWAY_ERROR = 'SET_COLORWAY_ERROR';
export const SET_PPG_ERROR = 'SET_PPG_ERROR';
export const SET_FROM_FACTORY_ERROR = 'SET_FROM_FACTORY_ERROR';
export const SET_TO_FACTORY_ERROR = 'SET_TO_FACTORY_ERROR';
export const SET_FROM_FACTORY_GROUP_ERROR = 'SET_FROM_FACTORY_GROUP_ERROR';
export const SET_TO_FACTORY_GROUP_ERROR = 'SET_TO_FACTORY_GROUP_ERROR';
export const SET_OUTSOLE_ERROR = 'SET_OUTSOLE_ERROR';
export const SET_PCC_ERROR = 'SET_PCC_ERROR';
export const HIDE_ERROR = 'HIDE_ERROR';
export const SET_SUBMIT_ERRORS = 'SET_SUBMIT_ERRORS';
export const HIDE_COLORWAY_ERROR = 'HIDE_COLORWAY_ERROR';
export const HIDE_DUE_DATE_ERROR = 'HIDE_DUE_DATE_ERROR';
export const HIDE_FROM_FACTORY_ERROR = 'HIDE_FROM_FACTORY_ERROR';
export const HIDE_FROM_FACTORY_GROUP_ERROR = 'HIDE_FROM_FACTORY_GROUP_ERROR';
export const HIDE_OUTSOLE_ERROR = 'HIDE_OUTSOLE_ERROR';
export const HIDE_PCC_ERROR = 'HIDE_PCC_ERROR';
export const HIDE_PPG_ERROR = 'HIDE_PPG_ERROR';
export const HIDE_STYLE_NUMBER_ERROR = 'HIDE_STYLE_NUMBER_ERROR';
export const HIDE_TO_FACTORY_ERROR = 'HIDE_TO_FACTORY_ERROR';
export const HIDE_TO_FACTORY_GROUP_ERROR = 'HIDE_TO_FACTORY_GROUP_ERROR';
export const ERROR_END = 'ERROR_END';
export const HAS_BLOCKING_ERROR_START = 'HAS_BLOCKING_ERROR_START';
export const HAS_BLOCKING_ERROR_END = 'HAS_BLOCKING_ERROR_END';

export interface IError {
  error: boolean;
  message: string;
  blockingError: boolean;
}

export interface ISubmitValidationObject {
  toFactory: IError;
  fromFactory: IError;
  toFactoryGroup: IError;
  fromFactoryGroup: IError;
  productColorwayCode: IError;
}

interface ISetDueDateError {
  type: typeof SET_DUE_DATE_ERROR;
  payload: string;
}

interface ISetStyleNumberError {
  type: typeof SET_STYLE_NUMBER_ERROR;
  payload: string;
}

interface ISetColorwayError {
  type: typeof SET_COLORWAY_ERROR;
  payload: string;
}

interface ISetPpgError {
  type: typeof SET_PPG_ERROR;
  payload: string;
}

interface ISetFromFactoryError {
  type: typeof SET_FROM_FACTORY_ERROR;
  payload: string;
}

interface ISetToFactoryError {
  type: typeof SET_TO_FACTORY_ERROR;
  payload: string;
}

interface ISetFromFactoryGroupError {
  type: typeof SET_FROM_FACTORY_GROUP_ERROR;
  payload: string;
}

interface ISetToFactoryGroupError {
  type: typeof SET_TO_FACTORY_GROUP_ERROR;
  payload: string;
}

interface ISetOutsoleError {
  type: typeof SET_OUTSOLE_ERROR;
  payload: string;
}

interface ISetPccError {
  type: typeof SET_PCC_ERROR;
  payload: string;
}

interface IHideDueDateError {
  type: typeof HIDE_DUE_DATE_ERROR;
}

interface IHideOutsoleError {
  type: typeof HIDE_OUTSOLE_ERROR;
}

interface IHideColorwayError {
  type: typeof HIDE_COLORWAY_ERROR;
}

interface IHideFromFactoryError {
  type: typeof HIDE_FROM_FACTORY_ERROR;
}

interface IHideFromFactoryGroupError {
  type: typeof HIDE_FROM_FACTORY_GROUP_ERROR;
}

interface IHidePccError {
  type: typeof HIDE_PCC_ERROR;
}

interface IHidePpgError {
  type: typeof HIDE_PPG_ERROR;
}

interface IHideStyleNumberError {
  type: typeof HIDE_STYLE_NUMBER_ERROR;
}

interface IHideToFactoryError {
  type: typeof HIDE_TO_FACTORY_ERROR;
}

interface IHideToFactroyGroupError {
  type: typeof HIDE_TO_FACTORY_GROUP_ERROR;
}

interface IErrorHide {
  type: typeof HIDE_ERROR;
}

interface IErrorEnd {
  type: typeof ERROR_END;
}

interface IHasBlockingErrorStart {
  type: typeof HAS_BLOCKING_ERROR_START;
  payload: boolean;
}

interface IHasBlockingErrorEnd {
  type: typeof HAS_BLOCKING_ERROR_END;
}

interface ISubmitErrors {
  type: typeof SET_SUBMIT_ERRORS;
  fromFactoryErrorMessage: string;
  fromFactoryError: boolean;
  toFactoryErrorMessage: string;
  toFactoryError: boolean;
  toFactoryGroupErrorMessage: string;
  toFactoryGroupError: boolean;
  fromFactoryGroupErrorMessage: string;
  fromFactoryGroupError: boolean;
  productColorwayCodeErrorMessage: string;
  productColorwayCodeError: boolean;
}

export type ErrorTransferDispatchType =
  | IErrorHide
  | ISetDueDateError
  | ISetStyleNumberError
  | ISetColorwayError
  | ISetPpgError
  | ISetFromFactoryError
  | ISetToFactoryError
  | ISetFromFactoryGroupError
  | ISetToFactoryGroupError
  | ISetOutsoleError
  | ISetPccError
  | ISubmitErrors
  | IHideColorwayError
  | IHideFromFactoryError
  | IHideToFactroyGroupError
  | IHideToFactoryError
  | IHideStyleNumberError
  | IHideFromFactoryGroupError
  | IHidePpgError
  | IHideDueDateError
  | IHidePccError
  | IHideOutsoleError
  | IErrorEnd
  | IHasBlockingErrorStart
  | IHasBlockingErrorEnd;
