import { TextArea } from '@nike/eds';
import { ChangeEventHandler, FC } from 'react';
import { useAppDispatch, useAppSelector } from '../../../redux/hooks';
import { roles } from '../../common/RolesConstants';
import { ITransferData } from '../NewTranferInterface';
import { updateInputTouched } from '../../../redux/actions/inputActions';

interface FieldProps {
  transferData: any | ITransferData;
  setTransferData: (params: any) => any;
}
const TransferNotes: FC<FieldProps> = ({ transferData, setTransferData }) => {
  const [transferItemData, userRole, isEditMode] = useAppSelector(state => [
    state.transferRecordItem.transferItemData.source,
    state.userRole.role,
    state.input.isEditMode,
  ]);

  const dispatch = useAppDispatch();

  const handleTextareaInputChange: ChangeEventHandler<
    HTMLTextAreaElement
  > = event => {
    const { value } = event.target;

    setTransferData({ ...transferData, transferNotes: value });

    dispatch<any>(updateInputTouched(true));
  };

  return (
    <TextArea
      placeholder="Write a short description about the transfer"
      name="transferNotes"
      onChange={handleTextareaInputChange}
      value={transferData.transferNotes}
      minRows={3}
      maxChars={500}
      id="transfer-reason-input"
      label="Transfer Note"
      showCount
      disabled={
        !transferData?.transferType?.value ||
        ((userRole === roles.factory || userRole === roles.pmo) &&
          isEditMode &&
          transferItemData.status !== 'Factory' &&
          transferItemData.status !== 'Draft')
      }
    />
  );
};

export default TransferNotes;
