import {
  UpdateTransferStatusDispatchType,
  UPDATE_TRANSFER_STATUS_FAILURE,
  UPDATE_TRANSFER_STATUS_RESET,
  UPDATE_TRANSFER_STATUS_START,
  UPDATE_TRANSFER_STATUS_SUCCESS,
} from '../types/updateTransferStatusTypes';

interface IUpdateTransferStatusDefaultState {
  loading: boolean;
  success: boolean;
  error: boolean;
  data: object;
}

const defaultState: IUpdateTransferStatusDefaultState = {
  loading: false,
  success: false,
  error: false,
  data: {},
};

const updateTransferStatusReducer = (
  state: IUpdateTransferStatusDefaultState = defaultState,
  action: UpdateTransferStatusDispatchType
): IUpdateTransferStatusDefaultState => {
  switch (action.type) {
    case UPDATE_TRANSFER_STATUS_START:
      return {
        ...state,
        loading: true,
        success: false,
        error: false,
        data: {},
      };

    case UPDATE_TRANSFER_STATUS_SUCCESS:
      return {
        ...state,
        loading: false,
        success: true,
        error: false,
        data: action.payload || {},
      };

    case UPDATE_TRANSFER_STATUS_FAILURE:
      return {
        ...state,
        loading: false,
        success: false,
        error: true,
        data: action.payload || {},
      };

    case UPDATE_TRANSFER_STATUS_RESET:
      return { ...defaultState };

    default:
      return state;
  }
};

export default updateTransferStatusReducer;
