export const USER_ROLE_LOADING_SUCCESS = 'LOGIN_SUCCESS';
export const USER_ROLE_LOADING_START = 'LOGIN_START';
export const USER_ROLE_LOADING_END = 'LOGIN_END';
export const USER_FACTORY_LOADING_SUCCESS = 'USER_FACTORY_LOADING_SUCCESS';
export const USER_FACTORY_LOADING_START = 'USER_FACTORY_LOADING_START';
export const USER_FACTORY_LOADING_END = 'USER_FACTORY_LOADING_END';
export const USER_ID_LOADING_SUCCESS = 'USER_ID_LOADING_SUCCESS';
export const USER_ID_LOADING_START = 'USER_ID_LOADING_START';
export const USER_ID_LOADING_END = 'USER_ID_LOADING_END';
export const SAVE_GRID_COLUMN_MODEL = 'SAVE_GRID_COLUMN_MODEL';
export const SAVE_GRID_FILTER_MODEL = 'SAVE_GRID_FILTER_MODEL';
export const SAVE_GRID_FILTER_MODEL_START = 'SAVE_GRID_COLUMN_MODEL_START';
export const SAVE_GRID_FILTER_MODEL_ERROR = 'SAVE_GRID_COLUMN_MODEL_ERROR';
export const SAVE_GRID_FILTER_MODEL_END = 'SAVE_GRID_FILTER_MODEL_END';

interface ISaveGridColumnModel {
  type: typeof SAVE_GRID_COLUMN_MODEL;
  payload: any;
}

interface ISaveGridFilterModel {
  type: typeof SAVE_GRID_FILTER_MODEL;
  payload: any;
}

interface ISaveGridColumnModelStart {
  type: typeof SAVE_GRID_FILTER_MODEL_START;
}

interface ISaveGridColumnModelError {
  type: typeof SAVE_GRID_FILTER_MODEL_ERROR;
}

interface ISaveGridColumnModelEnd {
  type: typeof SAVE_GRID_FILTER_MODEL_END;
}

interface IRoleLoadingStart {
  type: typeof USER_ROLE_LOADING_START;
}

interface IRoleLoadingSuccess {
  type: typeof USER_ROLE_LOADING_SUCCESS;
  payload: string;
}

interface IRoleLoadingEnd {
  type: typeof USER_ROLE_LOADING_END;
}

interface IFactoryAssignmentLoadingStart {
  type: typeof USER_FACTORY_LOADING_START;
}

interface IFactoryAssignmentLaodingSuccess {
  type: typeof USER_FACTORY_LOADING_SUCCESS;
  payload: object;
}

interface IFactoryAssignmentLoadingEnd {
  type: typeof USER_FACTORY_LOADING_END;
}

interface IIDLoadingStart {
  type: typeof USER_ID_LOADING_START;
}

interface IIDLoadingSuccess {
  type: typeof USER_ID_LOADING_SUCCESS;
  payload: string;
}

interface IIDLoadingEnd {
  type: typeof USER_ID_LOADING_END;
}

export type UserRoleDispatchType =
  | IRoleLoadingSuccess
  | IRoleLoadingStart
  | IRoleLoadingEnd;

export type UserIdDispatchType =
  | IIDLoadingStart
  | IIDLoadingSuccess
  | IIDLoadingEnd;

export type UserFactoryDispatchType =
  | IFactoryAssignmentLoadingStart
  | IFactoryAssignmentLaodingSuccess
  | IFactoryAssignmentLoadingEnd;

export type UserFilterDispatchType =
  | ISaveGridColumnModel
  | ISaveGridFilterModel
  | ISaveGridColumnModelStart
  | ISaveGridColumnModelError
  | ISaveGridColumnModelEnd;

export enum ResponseCode {
  OKAY = 200,
  CREATED = 201,
  NOT_FOUND = 404,
}
