import axios from 'axios';
import moment from 'moment';
import { useAuthStore } from '@nike/aegis-auth-react';
import { toast } from 'react-toastify';
import { fetchSttTokenHeaders } from '../../utils/sttToken';

const apiInstance = axios.create();

apiInstance.interceptors.request.use(
  async config => {
    const token = localStorage.getItem('access_token');
    const storeData = useAuthStore.getState();
    const idClaims = { groups: [''], ...storeData.idClaims };

    const userid = idClaims?.email?.toLowerCase() || '';
    const userrole =
      (idClaims?.groups && idClaims?.groups[0]?.split('.').at(-1)) || '';

    if (token) {
      // eslint-disable-next-line no-param-reassign
      config.headers = {
        Authorization: token,
        userid,
        userrole,
      };
    }
    return config;
  },
  error => {
    Promise.reject(error);
  }
);

apiInstance.interceptors.response.use(
  async response => {
    try {
      let expireTimestampMinusTen;
      const currentTime = moment();
      const refresh = localStorage.getItem('refresh_token');
      if (refresh) {
        expireTimestampMinusTen = moment(parseInt(atob(refresh), 10)).subtract(
          10,
          'minutes'
        );

        if (moment(currentTime).isSameOrAfter(expireTimestampMinusTen)) {
          const accessTokenHeaders = await fetchSttTokenHeaders();

          localStorage.setItem(
            'access_token',
            accessTokenHeaders.Authorization
          );
          localStorage.setItem(
            'refresh_token',
            accessTokenHeaders.RefreshToken
          );
        }

        return response;
      }
    } catch (error) {
      toast.info('Your session has expired, please refresh the screen', {
        autoClose: false,
      });
      return Promise.reject(error);
    }
    return response;
  },
  async error => {
    const originalRequest = error.config;
    if (error.response.status === 401 || !originalRequest.retry) {
      originalRequest.retry = true;

      try {
        const accessTokenHeaders = await fetchSttTokenHeaders();

        localStorage.setItem('access_token', accessTokenHeaders.Authorization);
        localStorage.setItem('refresh_token', accessTokenHeaders.RefreshToken);

        return await apiInstance(originalRequest);
      } catch (error) {
        return Promise.reject(error);
      }
    }
    return Promise.reject(error);
  }
);

export default apiInstance;
