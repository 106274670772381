import { TextField } from '@nike/eds';
import { ChangeEventHandler, FC, useEffect } from 'react';
import { useAppDispatch, useAppSelector } from '../../../redux/hooks';
import { ITransferData } from '../NewTranferInterface';
import {
  errorActionCreator,
  updateHasBlockingError,
} from '../../../redux/actions/errorActions';
import { validateOnChange } from '../Validation';
import { updateInputTouched } from '../../../redux/actions/inputActions';
import { roles } from '../../common/RolesConstants';

interface FieldProps {
  transferData: any | ITransferData;
  setTransferData: (params: any) => any;
  enableAutoPopulateFields: boolean;
}

const Outsole: FC<FieldProps> = ({
  transferData,
  setTransferData,
  enableAutoPopulateFields = true,
}) => {
  const [transferItemData, userRole, isEditMode, error, outsoleCodes] =
    useAppSelector(state => [
      state.transferRecordItem.transferItemData.source,
      state.userRole.role,
      state.input.isEditMode,
      state.error,
      state.newTransfer.outsoleCodes,
    ]);

  const dispatch = useAppDispatch();

  useEffect(() => {
    if (enableAutoPopulateFields) {
      if (outsoleCodes.length === 0 && !isEditMode) {
        if (transferItemData.outsoleCode === undefined) {
          setTransferData({
            ...transferData,
            outsole: '',
          });
        } else {
          setTransferData({
            ...transferData,
            outsole: transferItemData.outsoleCode,
          });
        }
      } else if (outsoleCodes.length === 0 && isEditMode) {
        setTransferData({
          ...transferData,
        });
      } else {
        setTransferData({
          ...transferData,
          outsole: outsoleCodes[0],
        });
        dispatch<any>(updateInputTouched(true));
      }
    }
  }, [outsoleCodes]);

  const handleTextInputChange: ChangeEventHandler<HTMLInputElement> = event => {
    const { name, value } = event.target;
    const validationParams = {
      outsoleCode: outsoleCodes[0],
      transferData,
    };

    setTransferData({
      ...transferData,
      outsole: value,
    });

    dispatch<any>(updateHasBlockingError(false));

    const validation = validateOnChange(
      name,
      value,
      userRole,
      validationParams
    );

    dispatch<any>(errorActionCreator(name, validation.message));

    if (validation.blockingError) {
      dispatch<any>(updateHasBlockingError(true));
    }

    dispatch<any>(updateInputTouched(true));
  };

  return (
    <TextField
      name="outsole"
      onChange={handleTextInputChange}
      label="Outsole"
      type="text"
      disabled={
        !transferData?.transferType?.value ||
        ((userRole === roles.factory || userRole === roles.pmo) &&
          isEditMode &&
          transferItemData.status !== 'Factory' &&
          transferItemData.status !== 'Draft')
      }
      value={transferData.outsole}
      id="outsole-input"
      hasErrors={error.outsoleError}
      errorMessage={error.outsoleErrorMessage}
    />
  );
};

export default Outsole;
