const fixColorwayCodeArray = (
  productColorwayCode:
    | {
        value: string;
        label: string;
      }
    | {
        value: string;
        label: string;
      }[]
    | null
) => {
  let result: string[] = [];
  if (productColorwayCode !== null && productColorwayCode !== undefined) {
    if (Array.isArray(productColorwayCode)) {
      result = productColorwayCode.map((a: { value: string }) => a.value);
    } else {
      result.push(productColorwayCode.value);
    }
  }
  return result;
};

export default fixColorwayCodeArray;
