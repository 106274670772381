/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { Dispatch } from 'redux';
import {
  TR_INPUT_TOUCHED_START,
  TR_INPUT_TOUCHED_END,
  InputDispatchType,
  TR_IS_EDIT_MODE_START,
  TR_IS_EDIT_MODE_END,
} from '../types/inputTypes';

export const updateInputTouched =
  (bool: boolean) => async (dispatch: Dispatch<InputDispatchType>) => {
    try {
      dispatch({ type: TR_INPUT_TOUCHED_START, payload: bool });
    } catch (error) {
      dispatch({ type: TR_INPUT_TOUCHED_END });
    }
  };

export const updateEditMode =
  (bool?: boolean) => async (dispatch: Dispatch<InputDispatchType>) => {
    try {
      dispatch({ type: TR_IS_EDIT_MODE_START, payload: bool! });
    } catch (error) {
      dispatch({ type: TR_IS_EDIT_MODE_END });
    }
  };
