/* eslint-disable import/no-cycle */
import {
  USER_ROLE_LOADING_START,
  USER_ROLE_LOADING_END,
  USER_ROLE_LOADING_SUCCESS,
  UserRoleDispatchType,
} from '../types/userActionTypes';

interface IUserRoleDefaultState {
  loading: boolean;
  role: string;
}

const defaultState: IUserRoleDefaultState = {
  loading: false,
  role: '',
};

const userRoleReducer = (
  state: IUserRoleDefaultState = defaultState,
  action: UserRoleDispatchType
): IUserRoleDefaultState => {
  switch (action.type) {
    case USER_ROLE_LOADING_START:
      return { ...state, loading: true };
    case USER_ROLE_LOADING_END:
      return { ...state, loading: false };
    case USER_ROLE_LOADING_SUCCESS:
      return {
        ...state,
        role: action.payload,
      };
    default:
      return state;
  }
};

export default userRoleReducer;
