export function sortByQuarterYear(a: string, b: string) {
  const quarterToMonthMap: { [char: string]: number } = {
    SU: 3,
    SP: 0,
    HO: 9,
    FA: 6,
  };

  if (a !== null && b !== null) {
    const lhsQuarterYearOne = a.slice(0, 2);
    const rhsQuarterYearOne = b.slice(0, 2);
    const lhsQuarterYearTwo = a.slice(2, 6);
    const rhsQuarterYearTwo = b.slice(2, 6);

    const lhsDate = new Date(
      Number(lhsQuarterYearTwo),
      quarterToMonthMap[lhsQuarterYearOne]
    );
    const rhsDate = new Date(
      Number(rhsQuarterYearTwo),
      quarterToMonthMap[rhsQuarterYearOne]
    );

    return rhsDate.getTime() - lhsDate.getTime();
  }
  return 0;
}

export function sortFactoryList(p1: any, p2: any) {
  if (p1.value > p2.value) {
    return 1;
  }
  if (p1.value < p2.value) {
    return -1;
  }
  return 0;
}

export function sortChangeLogDates(a: any, b: any) {
  return (
    Date.parse(b.changeTimestamp || b.modifiedTimestamp) -
    Date.parse(a.changeTimestamp || a.modifiedTimestamp)
  );
}
