import React, { FunctionComponent, useMemo } from 'react';
import { Button, ButtonGroup, Modal, Text } from '@nike/eds';

import { IActionConfirmModalProps } from '../../redux/types/transferAttachmentTypes';

const DeleteActionModal: FunctionComponent<IActionConfirmModalProps> = ({
  onConfirm,
  isOpen,
  onDismiss,
}) => {
  const texts = useMemo(() => {
    return {
      headerText: 'Are you sure you want to delete the selected attachment?',
      message: '',
      confirmText: 'Yes, Delete attachment',
    };
  }, []);

  return (
    <Modal
      onDismiss={onDismiss}
      isOpen={isOpen}
      headerSlot={<span>{texts.headerText}</span>}
      footerSlot={
        <ButtonGroup>
          <Button onClick={onConfirm} size="small">
            {texts.confirmText}
          </Button>
          <Button onClick={onDismiss} size="small" variant="secondary">
            Cancel
          </Button>
        </ButtonGroup>
      }
    >
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
        }}
      >
        <Text font="body-1" as="p">
          {texts.message}
        </Text>
      </div>
    </Modal>
  );
};

export default DeleteActionModal;
