import moment from 'moment';
import { roles } from '../common/RolesConstants';
import { IError, ISubmitValidationObject } from '../../redux/types/errorTypes';

const DATE_IN_SEVEN_DAYES = 'Date must be more than 7 days in the future';
const THREE_DIGIT_COLORWAYCODE = 'Colorway code must be 3 digits';
const OUTSIDE_LINEPLANNING_COLORWAYCODE =
  'You have entered a colorway that is not part of line planning for the selected season and style';
const OUTSIDE_LINEPLANNING_STYLE_NUMBER =
  'You have selected a style that is not part of line planning for the selected season';
const OUTSIDE_LINEPLANNING_OUTSOLE_CODE =
  'You have entered an Outsole Tooling code different than the one on the BOM';
const OUTSIDE_LINEPLANNING_PPG =
  'You have selected a PPG value that is outside of the mapping logic';
const OUTSIDE_FACTORY_CODE_CREATED =
  'You have entered a factory code that is not in the Aravo active factory list';
const FACTORY_CODE_CHAR_COUNT = 'Factory Codes must be 2 characters long';
const FACTORY_CODE_UPPERCASE = 'Factory Codes must be uppercase';
const FACTORY_GROUP_INVALID =
  'You have entered a factory group code that cannot be validated';
const PCC_INVALID = 'You have entered a PCC value that is not listed in PCX';
const PCC_TOO_LONG = 'PCC Code should be 2 to 3 characters';
const ITEM_TOO_LONG_OR_SHORT_COLORWAYCODE =
  'Colorway Codes are limited to 3 characters';

const isUpperCase = (input: string) => {
  return input === String(input).toUpperCase();
};

const submitValidationObject: ISubmitValidationObject = {
  toFactory: {
    error: false,
    message: '',
    blockingError: false,
  },
  fromFactory: {
    error: false,
    message: '',
    blockingError: false,
  },
  toFactoryGroup: {
    error: false,
    message: '',
    blockingError: false,
  },
  fromFactoryGroup: {
    error: false,
    message: '',
    blockingError: false,
  },
  productColorwayCode: {
    error: false,
    message: '',
    blockingError: false,
  },
};

export const validateOnSubmit = (transferObject: any) => {
  if (transferObject.toFactory) {
    if (transferObject.toFactory.value) {
      if (
        transferObject.toFactory.value.length === 1 ||
        transferObject.toFactory.value.length > 3
      ) {
        submitValidationObject.toFactory.message = FACTORY_CODE_CHAR_COUNT;
        submitValidationObject.toFactory.error = true;
      } else {
        submitValidationObject.toFactory.message = '';
        submitValidationObject.toFactory.error = false;
      }

      if (!isUpperCase(transferObject.toFactory.value)) {
        submitValidationObject.toFactory.message = FACTORY_CODE_UPPERCASE;
        submitValidationObject.toFactory.error = true;
      } else {
        submitValidationObject.toFactory.message = '';
        submitValidationObject.toFactory.error = false;
      }
    }
  }
  if (transferObject.fromFactory) {
    if (transferObject.fromFactory.value) {
      if (
        transferObject.fromFactory.value.length === 1 ||
        transferObject.fromFactory.value.length > 3
      ) {
        submitValidationObject.fromFactory.message = FACTORY_CODE_CHAR_COUNT;
        submitValidationObject.fromFactory.error = true;
      } else {
        submitValidationObject.fromFactory.message = '';
        submitValidationObject.fromFactory.error = false;
      }

      if (!isUpperCase(transferObject.fromFactory.value)) {
        submitValidationObject.fromFactory.message = FACTORY_CODE_UPPERCASE;
        submitValidationObject.fromFactory.error = true;
      } else {
        submitValidationObject.fromFactory.message = '';
        submitValidationObject.fromFactory.error = false;
      }
    }
  }

  if (transferObject.toFactoryGroup) {
    if (
      transferObject.toFactoryGroup?.length === 1 ||
      transferObject.toFactoryGroup?.length > 3
    ) {
      submitValidationObject.toFactoryGroup.message = FACTORY_CODE_CHAR_COUNT;
      submitValidationObject.toFactoryGroup.error = true;
    } else {
      submitValidationObject.toFactoryGroup.message = '';
      submitValidationObject.toFactoryGroup.error = false;
    }
    if (!isUpperCase(transferObject.toFactoryGroup)) {
      submitValidationObject.toFactoryGroup.message = FACTORY_CODE_UPPERCASE;
      submitValidationObject.toFactoryGroup.error = true;
    } else {
      submitValidationObject.toFactoryGroup.message = '';
      submitValidationObject.toFactoryGroup.error = false;
    }
  }

  if (transferObject.fromFactoryGroup) {
    if (
      transferObject.fromFactoryGroup?.length === 1 ||
      transferObject.fromFactoryGroup?.length > 3
    ) {
      submitValidationObject.fromFactoryGroup.message = FACTORY_CODE_CHAR_COUNT;
      submitValidationObject.fromFactoryGroup.error = true;
    } else {
      submitValidationObject.fromFactoryGroup.message = '';
      submitValidationObject.fromFactoryGroup.error = false;
    }

    if (!isUpperCase(transferObject.fromFactoryGroup)) {
      submitValidationObject.fromFactoryGroup.message = FACTORY_CODE_UPPERCASE;
      submitValidationObject.fromFactoryGroup.error = true;
    } else {
      submitValidationObject.fromFactoryGroup.message = '';
      submitValidationObject.fromFactoryGroup.error = false;
    }
  }

  if (transferObject.productColorwayCode) {
    for (let i = 0; i < transferObject.productColorwayCode?.length; i += 1) {
      if (transferObject.productColorwayCode[i].value?.length !== 3) {
        submitValidationObject.productColorwayCode.message =
          ITEM_TOO_LONG_OR_SHORT_COLORWAYCODE;
        submitValidationObject.productColorwayCode.error = true;
      } else {
        submitValidationObject.productColorwayCode.message = '';
        submitValidationObject.productColorwayCode.error = false;
      }
    }
  }

  return submitValidationObject;
};

export const validateOnChange = (
  actionMeta: any,
  value: any,
  userRole: string,
  validationParams: any
) => {
  const changeValidationObject: IError = {
    error: false,
    message: '',
    blockingError: false,
  };

  let inputName: string;

  if (typeof actionMeta === 'string') {
    inputName = actionMeta;
  } else {
    inputName = actionMeta?.name;
  }

  if (inputName === 'dueDate') {
    // if date is in within seven days (factory users only)
    if (
      userRole === roles.factory &&
      moment(value).isBefore(moment(new Date()).add(7, 'days'))
    ) {
      changeValidationObject.message = DATE_IN_SEVEN_DAYES;
      changeValidationObject.error = true;
    }
  }

  /* Selectbox Inputs */

  // if the style number selected isn't in the list of styles for that season
  if (inputName === 'styleNumber') {
    if (value?.value) {
      if (
        validationParams.styleListBySeason?.length &&
        !validationParams.styleListBySeason?.includes(value?.value)
      ) {
        changeValidationObject.message = OUTSIDE_LINEPLANNING_STYLE_NUMBER;
        changeValidationObject.error = true;
      }
    }
  }

  const containsObject = (obj: any, list: any) => {
    let i;
    for (i = 0; i < list.length; i += 1) {
      if (list[i].value === obj) {
        return true;
      }
    }

    return false;
  };

  // if colorway code isn't in the list of colorway codes
  if (inputName === 'productColorwayCode') {
    // eslint-disable-next-line prefer-regex-literals
    const regex = new RegExp(/^\d{3}$/);

    if (value !== null && value !== undefined) {
      // if colorway is a list we use this validation
      if (Array.isArray(value) && value.length !== undefined) {
        for (let i = 0; i < value.length; i += 1) {
          if (
            !containsObject(
              value[i].value,
              validationParams.productColorwayCodeList
            )
          ) {
            changeValidationObject.message = OUTSIDE_LINEPLANNING_COLORWAYCODE;
            changeValidationObject.error = true;
          } else {
            changeValidationObject.message = '';
            changeValidationObject.error = false;
          }
        }
        if (!regex.test(value[0]?.value) && value[0]?.value) {
          changeValidationObject.message = THREE_DIGIT_COLORWAYCODE;
          changeValidationObject.error = true;
          changeValidationObject.blockingError = true;
        }
      }

      // if colorway is a single value we use this validation
      else if (
        !containsObject(value.value, validationParams.productColorwayCodeList)
      ) {
        changeValidationObject.message = OUTSIDE_LINEPLANNING_COLORWAYCODE;
        changeValidationObject.error = true;
      } else {
        changeValidationObject.message = '';
        changeValidationObject.error = false;
      }

      if (value.length === 0) {
        changeValidationObject.message = '';
        changeValidationObject.error = false;
      }

      if (!regex.test(value?.value) && value?.value) {
        changeValidationObject.message = THREE_DIGIT_COLORWAYCODE;
        changeValidationObject.error = true;
        changeValidationObject.blockingError = true;
      }
    }
  }

  // if you select a value that isn't auto derived.
  if (inputName === 'productPlanningGroup') {
    if (validationParams.transferData?.styleNumber?.value) {
      if (validationParams.ppg !== '') {
        if (value.value !== validationParams.ppg?.value) {
          changeValidationObject.message = OUTSIDE_LINEPLANNING_PPG;
          changeValidationObject.error = true;
        }
      }
    }
  }

  // if factory you typed isn't in the list at all
  if (inputName === 'toFactory') {
    const tofactoryList: any[] = [];

    if (validationParams.allFactoryList) {
      validationParams.allFactoryList.forEach((element: any) => {
        tofactoryList.push(element);
      });
      if (validationParams.toFactoryList) {
        validationParams.toFactoryList.forEach((element: any) => {
          tofactoryList.push(element);
        });
      }
    }

    if (value) {
      if (
        !tofactoryList.find(
          (e: { factory: string; group: string }) => e.factory === value.value
        )
      ) {
        changeValidationObject.message = OUTSIDE_FACTORY_CODE_CREATED;
        changeValidationObject.error = true;
      }
    }
  }

  // if factory you typed isn't in the list at all
  if (inputName === 'fromFactory') {
    const fromFactoryList: any[] = [];

    if (validationParams.allFactoryList) {
      validationParams.allFactoryList.forEach((element: any) => {
        fromFactoryList.push(element);
      });
      if (validationParams.fromFactoryList) {
        validationParams.fromFactoryList.forEach((element: any) => {
          fromFactoryList.push(element);
        });
      }
    }

    if (value) {
      if (
        !fromFactoryList.find(
          (e: { factory: string; group: string }) => e.factory === value.value
        )
      ) {
        changeValidationObject.message = OUTSIDE_FACTORY_CODE_CREATED;
        changeValidationObject.error = true;
      } else {
        changeValidationObject.message = '';
        changeValidationObject.error = false;
      }
    }
  }

  // if factory group gets typed in at all, throw error message
  if (inputName === 'fromFactoryGroup') {
    if (value) {
      changeValidationObject.message = FACTORY_GROUP_INVALID;
      changeValidationObject.error = true;
    } else {
      changeValidationObject.message = '';
      changeValidationObject.error = false;
    }
  }

  // if factory group gets typed in at all, throw error message
  if (inputName === 'toFactoryGroup') {
    if (value) {
      changeValidationObject.message = FACTORY_GROUP_INVALID;
      changeValidationObject.error = true;
    } else {
      changeValidationObject.message = '';
      changeValidationObject.error = false;
    }
  }

  /* String Inputs */

  // if the value of the outsole isn't
  if (inputName === 'outsole') {
    if (
      validationParams.transferData.transferType.value !== 'Tooling' &&
      validationParams.transferData.transferType.value !== 'Outsole Complete'
    ) {
      // if the value is auto derived
      if (
        value !== validationParams.outsoleCode &&
        validationParams.outsoleCode !== undefined
      ) {
        // if the value is matches the database
        if (value !== validationParams.outsoleCode) {
          changeValidationObject.message = OUTSIDE_LINEPLANNING_OUTSOLE_CODE;
          changeValidationObject.error = true;
        } else {
          changeValidationObject.message = '';
          changeValidationObject.error = false;
        }
      } else {
        changeValidationObject.message = '';
        changeValidationObject.error = false;
      }
    } else {
      changeValidationObject.message = '';
      changeValidationObject.error = false;
    }

    // if the value is blank, no error.
    if (value.length === 0) {
      changeValidationObject.message = '';
      changeValidationObject.error = false;
    }
  }

  if (inputName === 'pccCodes') {
    // if the value is auto derived
    if (
      value !== validationParams.pccCode &&
      validationParams.pccCode !== undefined
    ) {
      changeValidationObject.message = PCC_INVALID;
      changeValidationObject.error = true;
    } else {
      changeValidationObject.message = '';
      changeValidationObject.error = false;
    }
    // if the value is blank, no error.
    if (value.length === 0) {
      changeValidationObject.message = '';
      changeValidationObject.error = false;
    }
    // if the value is too long, error.
    if (value.length > 3) {
      changeValidationObject.message = PCC_TOO_LONG;
      changeValidationObject.error = true;
    }
  }

  return changeValidationObject;
};
