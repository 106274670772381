import { TextField } from '@nike/eds';
import { ChangeEventHandler, FC } from 'react';
import { useAppDispatch, useAppSelector } from '../../../redux/hooks';
import { roles } from '../../common/RolesConstants';
import { updateInputTouched } from '../../../redux/actions/inputActions';
import { ITransferData } from '../NewTranferInterface';
import {
  errorActionCreator,
  updateHasBlockingError,
} from '../../../redux/actions/errorActions';
import { validateOnChange } from '../Validation';

interface FieldProps {
  transferData: any | ITransferData;
  setTransferData: (params: any) => any;
  factoryIsFromOverwritten: boolean;
}

const FromFactoryGroup: FC<FieldProps> = ({
  transferData,
  setTransferData,
  factoryIsFromOverwritten,
}) => {
  const [transferItemData, userRole, isEditMode, error] = useAppSelector(
    state => [
      state.transferRecordItem.transferItemData.source,
      state.userRole.role,
      state.input.isEditMode,
      state.error,
    ]
  );

  const dispatch = useAppDispatch();

  const handleTextInputChange: ChangeEventHandler<HTMLInputElement> = event => {
    const { name, value } = event.target;
    const validationParams = {
      transferData,
    };

    setTransferData({
      ...transferData,
      fromFactoryGroup: value,
    });

    dispatch<any>(updateHasBlockingError(false));

    const validation = validateOnChange(
      name,
      value,
      userRole,
      validationParams
    );

    dispatch<any>(errorActionCreator(name, validation.message));

    if (validation.blockingError) {
      dispatch<any>(updateHasBlockingError(true));
    }

    dispatch<any>(updateInputTouched(true));
  };

  return (
    <TextField
      name="fromFactoryGroup"
      label="From Factory Group"
      type="text"
      onChange={handleTextInputChange}
      value={transferData.fromFactoryGroup}
      disabled={
        factoryIsFromOverwritten ||
        ((userRole === roles.factory || userRole === roles.pmo) &&
          isEditMode &&
          transferItemData.status !== 'Factory' &&
          transferItemData.status !== 'Draft')
      }
      id="from-factory-group-input"
      hasErrors={error.fromFactoryGroupError}
      errorMessage={error.fromFactoryGroupErrorMessage}
    />
  );
};

export default FromFactoryGroup;
