import { TextField } from '@nike/eds';
import { FC, useEffect } from 'react';
import { ITransferData } from '../NewTranferInterface';
import { useAppSelector } from '../../../redux/hooks';

interface FieldProps {
  transferData: any | ITransferData;
  setTransferData: (params: any) => any;
  enableAutoPopulateFields: boolean;
}

const StyleName: FC<FieldProps> = ({
  transferData,
  setTransferData,
  enableAutoPopulateFields = true,
}) => {
  const [styleName] = useAppSelector(state => [state.newTransfer.styleName]);

  useEffect(() => {
    if (enableAutoPopulateFields) {
      setTransferData({
        ...transferData,
        styleName,
      });
    }
  }, [styleName]);

  return (
    <TextField
      label="Style Name"
      type="text"
      value={transferData.styleName}
      disabled
      id="style-name-input"
    />
  );
};

export default StyleName;
