import { Label } from '@nike/eds';
import { FC } from 'react';
import DatePicker from 'react-datepicker';
import moment from 'moment';
import { useAppDispatch, useAppSelector } from '../../../redux/hooks';
import { roles } from '../../common/RolesConstants';
import { updateInputTouched } from '../../../redux/actions/inputActions';
import { ITransferData } from '../NewTranferInterface';

interface FieldProps {
  transferData: any | ITransferData;
  setTransferData: (params: any) => any;
}

const DueDate: FC<FieldProps> = ({ transferData, setTransferData }) => {
  const [transferItemData, userRole, isEditMode] = useAppSelector(state => [
    state.transferRecordItem.transferItemData.source,
    state.userRole.role,
    state.input.isEditMode,
  ]);

  const dispatch = useAppDispatch();

  const handleDateChange = (date: Date) => {
    if (date !== null) {
      setTransferData({
        ...transferData,
        dueDate: date,
      });
    }

    dispatch<any>(updateInputTouched(true));
  };

  return (
    <div className="form-input-container date-picker-input-container">
      <Label font="title-6" htmlFor="due-date-picker">
        Due Date
      </Label>
      <DatePicker
        id="due-date-picker"
        selected={
          new Date(
            moment.utc(transferData.dueDate).local().format('MM-DD-YYYY')
          )
        }
        dateFormat="MM/dd/yyyy"
        onChange={(date: Date) => {
          handleDateChange(date);
        }}
        className="eds-text-field__input"
        minDate={
          userRole === roles.factory
            ? new Date(moment(new Date()).add(7, 'days').toISOString())
            : new Date()
        }
        disabled={
          (userRole === roles.factory || userRole === roles.pmo) &&
          isEditMode &&
          transferItemData.status !== 'Factory' &&
          transferItemData.status !== 'Draft'
        }
      />
    </div>
  );
};

export default DueDate;
