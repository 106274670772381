import { TextGroup, Button, ButtonGroup, TextArea, Tooltip } from '@nike/eds';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { ITransferRecordItemCommentType } from '../../redux/types/transferRecordItemTypes';
import {
  fetchUserIdentifier,
  fetchUserRole,
} from '../../redux/actions/userActionActions';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import {
  fetchTransferRecordItem,
  postTransferComment,
} from '../../redux/actions/transferRecordItemActions';
import { roles } from '../common/RolesConstants';

const CommentsTab = (props: { transferId: any }) => {
  const { transferId } = props;

  const [textValue, setTextValue] = useState('');
  const [fullCommentList, setFullCommentList] = useState<
    ITransferRecordItemCommentType[]
  >([]);
  const dispatch = useAppDispatch();

  const [userRole, comments, isEditMode] = useAppSelector(state => [
    state.userRole.role,
    state.transferRecordItem.transferItemData.source?.comments,
    state.input.isEditMode,
  ]);

  useEffect(() => {
    setFullCommentList(comments);
    setTextValue('');
  }, [comments]);

  useEffect(() => {
    dispatch<any>(fetchUserRole());
    dispatch<any>(fetchUserIdentifier());
  }, []);

  const clickComment = () => {
    if (textValue && transferId) {
      dispatch<any>(
        postTransferComment(transferId, {
          comment: textValue,
        })
      ).then(dispatch<any>(fetchTransferRecordItem(transferId)));
    }
  };
  return (
    <div>
      <div className="eds-spacing--p-16">
        {fullCommentList && fullCommentList.length > 0 ? (
          fullCommentList.map(cGItem => (
            <div
              className="travelcompany-input"
              key={`${Date.parse(cGItem.createdTimestamp)}${cGItem.createdBy}${
                cGItem.userRole
              }`}
            >
              <div>
                <p className="eds-spacing--pt-12 eds-spacing--pb-8 eds-grid eds-grid--m-cols-2">
                  <span className="eds-type--subtitle-1">
                    {cGItem.userEmail} ({cGItem.commentType})
                  </span>
                  <span className="eds-color--grey-1 ">
                    {moment
                      .utc(cGItem.createdTimestamp)
                      .local()
                      .format('MM/DD/YY')}{' '}
                    at{' '}
                    {moment
                      .utc(cGItem.createdTimestamp)
                      .local()
                      .format('h:mm a')}
                  </span>
                </p>
                <p className="eds-spacing--pb-8">
                  <span className="eds-type--subtitle-1">{cGItem.comment}</span>
                </p>
              </div>
              <hr />
            </div>
          ))
        ) : (
          <div className="eds-spacing--p-16 eds-color--link-disable">
            {' '}
            No Comments{' '}
          </div>
        )}
        <div className="eds-spacing--p-16">
          {userRole !== roles.planning ? (
            <>
              <TextGroup>
                <TextArea
                  id="id"
                  label=""
                  placeholder="Add your comments here"
                  value={textValue}
                  autoResize
                  onChange={e => setTextValue(e.target.value)}
                  minRows={4}
                  maxRows={7}
                />{' '}
              </TextGroup>
              <ButtonGroup className="eds-spacing--pt-32 action-buttons">
                {isEditMode ? (
                  <Tooltip
                    bodySlot="Button is disabled when editing form above"
                    placement="right"
                    enableFocus
                  >
                    <Button
                      type="submit"
                      variant="primary"
                      size="small"
                      onClick={clickComment}
                      disabled={isEditMode}
                    >
                      Add Comment
                    </Button>
                  </Tooltip>
                ) : (
                  <Button
                    type="submit"
                    variant="primary"
                    size="small"
                    onClick={clickComment}
                    disabled={isEditMode}
                  >
                    Add Comment
                  </Button>
                )}
              </ButtonGroup>
            </>
          ) : null}
        </div>
      </div>
    </div>
  );
};

export default CommentsTab;
