export const SEASON_LIST_FETCH_SUCCESS = 'SEASON_LIST_FETCH_SUCCESS';
export const PRODUCT_CODE_LIST_FETCH_SUCCESS =
  'PRODUCT_CODE_LIST_FETCH_SUCCESS';
export const STYLE_NUMBER_LIST_FETCH_SUCCESS =
  'STYLE_NUMBER_LIST_FETCH_SUCCESS';
export const STYLE_NUMBER_LIST_BY_SEASON_FETCH_SUCCESS =
  'STYLE_NUMBER_LIST_BY_SEASON_FETCH_SUCCESS';
export const STYLE_NAME_FETCH_SUCCESS = 'STYLE_NAME_FETCH_SUCCESS';
export const STYLE_NAME_FETCH_CLEAR = 'STYLE_NAME_FETCH_CLEAR';
export const PRODUCT_COLORWAY_CODE_LIST_FETCH_SUCCESS =
  'PRODUCT_COLORWAY_CODE_LIST_FETCH_SUCCESS';
export const PRODUCT_PLANNING_GROUP_FETCH_SUCCESS =
  'PRODUCT_PLANNING_GROUP_FETCH_SUCCESS';
export const PRODUCT_PLANNING_GROUP_FETCH_CLEAR =
  'PRODUCT_PLANNING_GROUP_FETCH_CLEAR';
export const PRODUCT_PLANNING_GROUP_LIST_FETCH_SUCCESS =
  'PRODUCT_PLANNING_GROUP_LIST_FETCH_SUCCESS';
export const TO_FACTORY_LIST_FETCH_SUCCESS = 'TO_FACTORY_LIST_FETCH_SUCCESS';
export const ALL_TO_FACTORY_LIST_FETCH_SUCCESS =
  'ALL_TO_FACTORY_LIST_FETCH_SUCCESS';
export const FROM_FACTORY_LIST_FETCH_SUCCESS =
  'FROM_FACTORY_LIST_FETCH_SUCCESS';
export const PCC_CODES_FETCH_SUCCESS = 'PCC_CODES_FETCH_SUCCESS';
export const OUTSOLE_CODES_FETCH_SUCCESS = 'OUTSOLE_CODES_FETCH_SUCCESS';
export const OUTSOLE_CODES_FETCH_LOADING = 'OUTSOLE_CODES_FETCH_LOADING';
export const PCC_CODES_FETCH_LOADING = 'PCC_CODES_FETCH_LOADING';
export const POST_TRANSFER_DATA_START = 'POST_TRANSFER_DATA_START';
export const POST_TRANSFER_DATA_SUCCESS = 'POST_TRANSFER_DATA_SUCCESS';
export const POST_TRANSFER_DATA_END = 'POST_TRANSFER_DATA_END';
export const POST_TRANSFER_DATA_ERROR = 'POST_TRANSFER_DATA_ERROR';
export const POST_TRANSFER_DATA_RESET = 'POST_TRANSFER_DATA_RESET';
export const RESET_NEW_TRANSFER_DATA = 'RESET_NEW_TRANSFER_DATA';
export const NEW_TRANSFER_LIST_FETCH_START = 'NEW_TRANSFER_LIST_FETCH_START';
export const NEW_TRANSFER_LIST_FETCH_END = 'NEW_TRANSFER_LIST_FETCH_END';
export const PRODUCT_INFO_FETCH_SUCCESS = 'PRODUCT_INFO_FETCH_SUCCESS';
export const CLEAR_PRODUCT_INFO = 'CLEAR_PRODUCT_INFO';
export const POST_TRANSFER_DATA_DASHBOARD_SUCCESS =
  'POST_TRANSFER_DATA_DASHBOARD_SUCCESS';

export interface IProductInfoType {
  _index: string;
  _type: string;
  _id: string;
  _score: number;
  _source: {
    productIdentifier: number;
    productCode: string;
    productOfferingIdentifier: number;
    modelOfferingIdentifier: number;
    changeTimestamp: string;
    styleNumber: string;
    styleName: string;
    seasonYearDescription: string;
    modelName: string;
  };
}

interface INewTransferListFetchStart {
  type: typeof NEW_TRANSFER_LIST_FETCH_START;
  payload: PostTransferData[];
}

interface INewTransferListFetchEnd {
  type: typeof NEW_TRANSFER_LIST_FETCH_END;
  payload: PostTransferData[];
}

interface ISeasonListFetchSuccess {
  type: typeof SEASON_LIST_FETCH_SUCCESS;
  payload: string[];
}

interface IProductCodeListFetchSuccess {
  type: typeof PRODUCT_CODE_LIST_FETCH_SUCCESS;
  payload: string[];
}

interface IStyleNumberListFetchSuccess {
  type: typeof STYLE_NUMBER_LIST_FETCH_SUCCESS;
  payload: string[];
}

interface IStyleNumberListBySeasonFetchSuccess {
  type: typeof STYLE_NUMBER_LIST_BY_SEASON_FETCH_SUCCESS;
  payload: string[];
}

interface IStyleNameFetchSuccess {
  type: typeof STYLE_NAME_FETCH_SUCCESS;
  payload: string;
}

interface IStyleNameFetchClear {
  type: typeof STYLE_NAME_FETCH_CLEAR;
}

interface IProductInfoFetchSuccess {
  type: typeof PRODUCT_INFO_FETCH_SUCCESS;
  payload: IProductInfoType;
}

interface IClearProductInfo {
  type: typeof CLEAR_PRODUCT_INFO;
}

interface IProductColorwayCodeListFetchSuccess {
  type: typeof PRODUCT_COLORWAY_CODE_LIST_FETCH_SUCCESS;
  payload: string[];
}

interface IProductPlanningGroupListFetchSuccess {
  type: typeof PRODUCT_PLANNING_GROUP_LIST_FETCH_SUCCESS;
  payload: string[];
}

interface IProductPlanningGroupFetchSuccess {
  type: typeof PRODUCT_PLANNING_GROUP_FETCH_SUCCESS;
  payload: string;
}

interface IProductPlanningGroupFetchClear {
  type: typeof PRODUCT_PLANNING_GROUP_FETCH_CLEAR;
}

interface IProductPlanningGroupListFetchSuccess {
  type: typeof PRODUCT_PLANNING_GROUP_LIST_FETCH_SUCCESS;
  payload: string[];
}

export interface IPccCodesType {
  pccCodes: string[];
  tdCodes: string[];
}

interface IPccCodesFetchSuccess {
  type: typeof PCC_CODES_FETCH_SUCCESS;
  payload: IPccCodesType;
}

interface IOutsoleCodesFetchSuccess {
  type: typeof OUTSOLE_CODES_FETCH_SUCCESS;
  payload: string[];
}

interface IOutsoleCodesFetchLoading {
  type: typeof OUTSOLE_CODES_FETCH_LOADING;
}

interface IPCCCodesFetchLoading {
  type: typeof PCC_CODES_FETCH_LOADING;
}

interface IPostTransferDataStart {
  type: typeof POST_TRANSFER_DATA_START;
}

interface IPostTransferDataSuccess {
  type: typeof POST_TRANSFER_DATA_SUCCESS;
  payload: PostTransferData;
}

interface IPostTransferDataEnd {
  type: typeof POST_TRANSFER_DATA_END;
}

interface IPostTransferDataAlreadyExistsError {
  type: typeof POST_TRANSFER_DATA_ERROR;
  payload: { message: string };
}

interface IPostTransferDataFailureReset {
  type: typeof POST_TRANSFER_DATA_RESET;
}

interface IResetNewTransferData {
  type: typeof RESET_NEW_TRANSFER_DATA;
}

export interface IFactoryItem {
  factory: string;
  group: string;
  factoryCode?: string;
}

interface IToFactoryListFetchSuccess {
  type: typeof TO_FACTORY_LIST_FETCH_SUCCESS;
  payload: {
    toFactoryAssignments: IFactoryItem[];
  };
}

interface IAllFactoryListFetchSuccess {
  type: typeof ALL_TO_FACTORY_LIST_FETCH_SUCCESS;
  payload: {
    all: IFactoryItem[];
  };
}

interface IFromFactoryListFetchSuccess {
  type: typeof FROM_FACTORY_LIST_FETCH_SUCCESS;
  payload: {
    fromFactoryAssignments: IFactoryItem[];
  };
}

interface IPostTransferDataDashBoardSuccess {
  type: typeof POST_TRANSFER_DATA_DASHBOARD_SUCCESS;
  payload: DashboardValuesTransferData;
}

export interface PostTransferData {
  importantFlag?: boolean;
  styleName: string;
  colorwayCode: string[] | string | null;
  seasonYearCode: string;
  styleNumber: string;
  dueDate: Date;
  productPlanningGroup: string;
  engineeringType?: string | null;
  fromFactoryCode?: string;
  toFactoryCode?: string;
  transferGeos: string[];
  transferReason?: string | null;
  transferNotes?: string;
  transferType: string;
  outsoleCode?: string;
  toFactoryGroupCode?: string;
  fromFactoryGroupCode?: string;
  productCreationCenterCode?: string;
  tdCode?: string;
  status?: string;
  createdBy: string;
  createdTimestamp: string;
  productCode?: string;
  modelName?: string;
  productOfferingIdentifier?: string | number;
}

export interface DashboardValuesTransferData extends PostTransferData {
  _id: string;
}

export type NewTransferDispatchType =
  | ISeasonListFetchSuccess
  | IProductCodeListFetchSuccess
  | IStyleNumberListFetchSuccess
  | IStyleNameFetchSuccess
  | IStyleNameFetchClear
  | IProductColorwayCodeListFetchSuccess
  | IProductPlanningGroupFetchSuccess
  | IProductPlanningGroupFetchClear
  | IToFactoryListFetchSuccess
  | IAllFactoryListFetchSuccess
  | IFromFactoryListFetchSuccess
  | IPccCodesFetchSuccess
  | IOutsoleCodesFetchSuccess
  | IOutsoleCodesFetchLoading
  | IPCCCodesFetchLoading
  | IPostTransferDataStart
  | IPostTransferDataSuccess
  | IPostTransferDataAlreadyExistsError
  | IPostTransferDataFailureReset
  | IResetNewTransferData
  | INewTransferListFetchStart
  | INewTransferListFetchEnd
  | IProductPlanningGroupListFetchSuccess
  | IStyleNumberListBySeasonFetchSuccess
  | IProductInfoFetchSuccess
  | IClearProductInfo
  | IPostTransferDataEnd
  | IPostTransferDataDashBoardSuccess;

export enum ResponseCode {
  OKAY = 200,
  CREATED = 201,
  ALREADY_EXISTS = 400,
}

export interface IFetchProductInfoBySeasonResData {
  type: string;
  list: string[];
}
