import { Select } from '@nike/eds';
import { FC, useEffect, useState } from 'react';
import { Props as ReactSelectProps } from 'react-select';
import { useAppDispatch, useAppSelector } from '../../../redux/hooks';
import { roles } from '../../common/RolesConstants';
import { updateInputTouched } from '../../../redux/actions/inputActions';
import { ITransferData } from '../NewTranferInterface';
import { sortFactoryList } from '../../../utils/sortFunctions';
import {
  errorActionCreator,
  hideErrorActionCreator,
  updateHasBlockingError,
} from '../../../redux/actions/errorActions';
import { validateOnChange } from '../Validation';

const FACTORY_GROUP_MESSAGE =
  'You have selected a TO & FROM factory from the same Factory Group a transfer is NOT needed';

interface FieldProps {
  transferData: any | ITransferData;
  setTransferData: (params: any) => any;
  setFactoryIsFromOverwritten: (params: any) => any;
  setFactoryIsToOverwritten: (params: any) => any;
  enableAutoPopulateFields: boolean;
}

const ToFactory: FC<FieldProps> = ({
  transferData,
  setTransferData,
  setFactoryIsFromOverwritten,
  setFactoryIsToOverwritten,
  enableAutoPopulateFields = true,
}) => {
  const [toFactoryOptions, setToFactoryOptions] = useState<Array<any>>([]);
  const [
    transferItemData,
    userRole,
    isEditMode,
    toFactoryList,
    allFactoryList,
    error,
  ] = useAppSelector(state => [
    state.transferRecordItem.transferItemData.source,
    state.userRole.role,
    state.input.isEditMode,
    state.newTransfer.toFactoryList,
    state.newTransfer.allFactoryList,
    state.error,
  ]);

  const dispatch = useAppDispatch();

  useEffect(() => {
    const toFactory = toFactoryList?.find(
      item => item.factory === transferData.toFactory?.value
    );
    const allToFactory = allFactoryList?.find(
      item => item.factory === transferData.toFactory?.value
    );
    if (enableAutoPopulateFields) {
      if (toFactory) {
        setTransferData({
          ...transferData,
          toFactoryGroup: toFactory.group,
        });
      } else if (allToFactory) {
        setTransferData({
          ...transferData,
          toFactoryGroup: allToFactory.group,
        });
      }
    }

    // error if both factory groups are the same
    if (toFactory || allToFactory) {
      if (
        transferData.fromFactoryGroup === toFactory?.group ||
        transferData.fromFactoryGroup === allToFactory?.group
      ) {
        dispatch<any>(
          errorActionCreator('fromFactoryGroup', FACTORY_GROUP_MESSAGE)
        );
        dispatch<any>(
          errorActionCreator('toFactoryGroup', FACTORY_GROUP_MESSAGE)
        );
      } else {
        // reset error
        dispatch<any>(
          hideErrorActionCreator(['toFactoryGroup', 'fromFactoryGroup'])
        );
      }
    } else if (toFactory === undefined || allToFactory === undefined) {
      dispatch<any>(
        hideErrorActionCreator(['toFactoryGroup', 'fromFactoryGroup'])
      );
    }
  }, [transferData.toFactory, toFactoryList]);

  useEffect(() => {
    if (toFactoryList.length > 0) {
      setToFactoryOptions([
        {
          options: toFactoryList
            ?.map(item => ({
              value: item.factoryCode,
              label: item.factory,
            }))
            .sort(sortFactoryList),
        },
        {
          label: <hr />,
          options: allFactoryList
            ?.map(item => ({
              value: item.factory,
              label: item.factory,
            }))
            .sort(sortFactoryList),
        },
      ]);
    } else {
      setToFactoryOptions([
        {
          options: allFactoryList
            ?.map(item => ({
              value: item.factory,
              label: item.factory,
            }))
            .sort(sortFactoryList),
        },
      ]);
    }
  }, [toFactoryList, allFactoryList]);

  const handleSelectInputChange: ReactSelectProps['onChange'] = (
    value: any,
    actionMeta: any
  ) => {
    const inputName: string = actionMeta?.name || '';
    const validationData = {
      toFactoryList,
      transferData,
      allFactoryList,
    };

    setTransferData({ ...transferData, toFactory: value });

    // clears the factory group when to or from is deleted.
    if (inputName === 'fromFactory' || inputName === 'toFactory') {
      if (inputName === 'fromFactory' && value === null) {
        setTransferData({
          ...transferData,
          fromFactory: value,
          fromFactoryGroup: '',
        });
      }
      if (inputName === 'toFactory' && value === null) {
        setTransferData({
          ...transferData,
          toFactory: value,
          toFactoryGroup: '',
        });
      }
    }

    // makes factory group items editable
    if (inputName === 'fromFactory' && actionMeta.action === 'create-option') {
      setFactoryIsFromOverwritten(false);
    } else if (
      inputName === 'toFactory' &&
      actionMeta.action === 'create-option'
    ) {
      setFactoryIsToOverwritten(false);
    } else if (
      (inputName === 'toFactory' && actionMeta.action === 'clear') ||
      (inputName === 'toFactory' && actionMeta.action === 'select-option')
    ) {
      setFactoryIsToOverwritten(true);
    } else if (
      (inputName === 'fromFactory' && actionMeta.action === 'clear') ||
      (inputName === 'fromFactory' && actionMeta.action === 'select-option')
    ) {
      setFactoryIsFromOverwritten(true);
    }

    dispatch<any>(updateHasBlockingError(false));

    const validation = validateOnChange(
      actionMeta,
      value,
      userRole,
      validationData
    );

    dispatch<any>(errorActionCreator(inputName, validation.message));

    if (validation.blockingError) {
      dispatch<any>(updateHasBlockingError(true));
    }

    dispatch<any>(updateInputTouched(true));
  };

  return (
    <Select
      isClearable
      isCreatable={userRole === roles.analyst}
      options={toFactoryOptions}
      id="to-factory-input"
      label="To Factory"
      name="toFactory"
      onChange={handleSelectInputChange}
      value={transferData.toFactory}
      isDisabled={
        !transferData?.transferType?.value ||
        ((userRole === roles.factory || userRole === roles.pmo) &&
          isEditMode &&
          transferItemData.status !== 'Factory' &&
          transferItemData.status !== 'Draft')
      }
      hasErrors={error.toFactoryError}
      errorMessage={error.toFactoryErrorMessage}
    />
  );
};

export default ToFactory;
