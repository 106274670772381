import {
  SAVE_GRID_COLUMN_MODEL,
  SAVE_GRID_FILTER_MODEL,
  UserFilterDispatchType,
  SAVE_GRID_FILTER_MODEL_END,
  SAVE_GRID_FILTER_MODEL_START,
  SAVE_GRID_FILTER_MODEL_ERROR,
} from '../types/userActionTypes';

interface IUserFilterDefaultState {
  filter: any;
  column: any;
  loading?: boolean;
}

const defaultState: IUserFilterDefaultState = {
  filter: null,
  column: null,
  loading: false,
};

const userFilterReducer = (
  state: IUserFilterDefaultState = defaultState,
  action: UserFilterDispatchType
): IUserFilterDefaultState => {
  switch (action.type) {
    case SAVE_GRID_FILTER_MODEL:
      return { ...state, filter: action.payload };
    case SAVE_GRID_COLUMN_MODEL:
      return { ...state, column: action.payload };
    case SAVE_GRID_FILTER_MODEL_END:
      return { ...state, loading: false };
    case SAVE_GRID_FILTER_MODEL_START:
      return { ...state, loading: true };
    case SAVE_GRID_FILTER_MODEL_ERROR:
      return { ...state };
    default:
      return state;
  }
};

export default userFilterReducer;
