import {
  POST_TRANSFER_DATA_DASHBOARD_SUCCESS,
  PostTransferData,
} from './newTransferTypes';

export const TR_ITEM_LOADING_START = 'TR_ITEM_LOADING_START';
export const TR_ITEM_LOADING_END = 'TR_ITEM_LOADING_END';
export const TR_ITEM_FETCH_SUCCESS = 'TR_ITEM_FETCH_SUCCESS';
export const TR_ITEM_FETCH_FAILED = 'TR_ITEM_FETCH_FAILED';
export const TR_POST_COMMENT_SUCCESS = 'TR_POST_COMMENT_SUCCESS';
export const TR_POST_COMMENT_FAILED = 'TR_POST_COMMENT_FAILED';
export const TR_POST_COMMENT_END = 'TR_POST_COMMENT_END';
export const TR_POST_COMMENT_START = 'TR_POST_COMMENT_START';
export const TR_PUT_TRANSFER_DATA_START = 'TR_PUT_TRANSFER_DATA_START';
export const TR_PUT_TRANSFER_DATA_SUCCESS = 'TR_PUT_TRANSFER_DATA_SUCCESS';
export const TR_PUT_TRANSFER_DATA_END = 'TR_PUT_TRANSFER_DATA_END';
export const TR_PUT_TRANSFER_DATA_FAILED = 'TR_PUT_TRANSFER_DATA_FAILED';
export const TR_PATCH_FLAG_SUCCESS = 'TR_PATCH_FLAG_SUCCESS';
export const TR_PATCH_FLAG_FAILED = 'TR_PATCH_FLAG_FAILED';
export const TR_PATCH_TRANSFER_STATUS_SUCCESS =
  'TR_PATCH_TRANSFER_STATUS_SUCCESS';
export const TR_PATCH_TRANSFER_STATUS_START = 'TR_PATCH_TRANSFER_STATUS_START';
export const TR_PATCH_TRANSFER_STATUS_FAILED =
  'TR_PATCH_TRANSFER_STATUS_FAILED';

export const TR_DELETE_ATTACHMENT_START = 'TR_DELETE_ATTACHMENT_START';
export const TR_DELETE_ATTACHMENT_SUCCESS = 'TR_DELETE_ATTACHMENT_SUCCESS';
export const TR_DELETE_ATTACHMENT_FAILED = 'TR_DELETE_ATTACHMENT_FAILED';
export const TR_DELETE_ATTACHMENT_END = 'TR_DELETE_ATTACHMENT_END';
export const TR_ADD_ATTACHMENT_START = 'TR_ADD_ATTACHMENT_START';
export const TR_ADD_ATTACHMENT_SUCCESS = 'TR_ADD_ATTACHMENT_SUCCESS';
export const TR_ADD_ATTACHMENT_FAILED = 'TR_ADD_ATTACHMENT_FAILED';
export const TR_ADD_ATTACHMENT_END = 'TR_ADD_ATTACHMENT_FAILED';

export const TR_UPDATE_START = 'TR_UPDATE_START';
export const TR_UPDATE_END = 'TR_UPDATE_END';
export const TR_UPDATE_FAILED = 'TR_UPDATE_FAILED';

export const TR_ITEM_DUPLICATE_START = 'TR_ITEM_DUPLICATE_START';
export const TR_ITEM_DUPLICATE_END = 'TR_ITEM_DUPLICATE_END';
export const TR_ITEM_DUPLICATE_SUCCESS = 'TR_ITEM_DUPLICATE_SUCCESS';
export const TR_ITEM_DUPLICATE_FAILED = 'TR_ITEM_DUPLICATE_FAILED';

export interface ITransferRecordItemState {
  modifiedBy: string;
  modifiedTimestamp: string;
  status: string;
}

export interface ITransferRecordItemChangeLog {
  userEmail: string;
  changeTimestamp: string;
  changeText: string;
  field: string;
  originalValue: string;
  newValue: string;
  action: string;
  modifiedBy: string;
  modifiedTimestamp: string;
}

export interface ITransferRecordItemCommentType {
  createdBy: string;
  userRole: string;
  comment: string;
  commentType: string;
  userEmail: string;
  createdTimestamp: string;
}

export type TransferRecordItemSourceType = {
  State?: ITransferRecordItemState[];
  StateLog?: ITransferRecordItemState[];
  attachments: TransferRecordItemAttachment[];
  changeLog?: ITransferRecordItemChangeLog[];
  comments?: any;
  message?: any;
  importantFlag?: boolean;
  seasonYearCode?: string | null;
  fromFactoryCode?: string | null;
  modelName?: string;
  toFactoryCode?: string | null;
  styleNumber?: string | null;
  productCode?: string | null;
  productPlanningGroup?: string | null;
  outsoleCode?: string;
  tdCode?: string;
  engineeringType?: string | null;
  transferReason?: string | null;
  status?: string;
  transferType?: string | null;
  dueDate?: Date | null;
  colorwayCode?: string | string[] | null;
  createdBy?: string;
  createdTimestamp?: string;
  fromFactoryGroupCode?: string;
  modifiedBy?: string;
  modifiedTimestamp?: string;
  productCreationCenterCode?: string;
  productOfferingIdentifier?: string | number;
  stateLog?: string;
  styleName?: string;
  toFactoryGroupCode?: string;
  transferGeos?: string[];
  transferId?: string;
  transferNotes?: string;
};

export type TransferRecordFailedType = {
  status: any;
  message: any;
};

export type ITransferRecordItemType = {
  id: string;
  source: TransferRecordItemSourceType;
};

export interface IUpdateTransferStatusItemData {
  ids: string[];
}

export type TransferRecordItemAttachment = {
  fileName: string;
  attachmentUrl: string;
  createdBy: string;
  createdTimestamp: Date;
  fileType: string;
};

interface ITransferRecordItemLoadingStart {
  type: typeof TR_ITEM_LOADING_START;
}

interface ITransferRecordItemLoadingEnd {
  type: typeof TR_ITEM_LOADING_END;
}

interface ITransferRecordItemFetchSuccess {
  type: typeof TR_ITEM_FETCH_SUCCESS;
  payload: ITransferRecordItemType;
}

interface ITransferRecordItemFetchFailed {
  type: typeof TR_ITEM_FETCH_FAILED;
  payload: ITransferRecordItemType;
}

interface ITransferRecordItemDuplicateStart {
  type: typeof TR_ITEM_DUPLICATE_START;
}

interface ITransferRecordItemDuplicateEnd {
  type: typeof TR_ITEM_DUPLICATE_END;
  payload: ITransferRecordItemType;
}

interface ITransferRecordItemDuplicateSuccess {
  type: typeof TR_ITEM_DUPLICATE_SUCCESS;
  payload: ITransferRecordItemType;
}

interface ITransferRecordItemDuplicateFailed {
  type: typeof TR_ITEM_DUPLICATE_FAILED;
  payload: ITransferRecordItemType;
}

interface ITransferAddComment {
  type: typeof TR_POST_COMMENT_SUCCESS;
  payload: ITransferRecordItemType;
}

interface ITransferCommentLoadingStart {
  type: typeof TR_POST_COMMENT_START;
}

interface ITransferCommentLoadingFailed {
  type: typeof TR_POST_COMMENT_FAILED;
}

interface ITransferCommentLoadingEnd {
  type: typeof TR_POST_COMMENT_END;
}

interface ITransferCommentFetchFailed {
  type: typeof TR_POST_COMMENT_FAILED;
}

interface IUpdateTransferStart {
  type: typeof TR_PUT_TRANSFER_DATA_START;
}

interface IUpdateTransferSuccess {
  type: typeof TR_PUT_TRANSFER_DATA_SUCCESS;
  payload: TransferRecordItemSourceType;
}

interface IUpdateTransferEnd {
  type: typeof TR_PUT_TRANSFER_DATA_END;
}

interface IUpdateTransferFailed {
  type: typeof TR_PUT_TRANSFER_DATA_FAILED;
}

interface IUpdateTransferFlagSuccess {
  type: typeof TR_PATCH_FLAG_SUCCESS;
  payload: TransferRecordItemSourceType;
}

interface IUpdateTransferFlagFailed {
  type: typeof TR_PATCH_FLAG_FAILED;
}

interface IPatchTransferStatusStart {
  type: typeof TR_PATCH_TRANSFER_STATUS_START;
}

interface IPatchTransferStatusSuccess {
  type: typeof TR_PATCH_TRANSFER_STATUS_SUCCESS;
  payload: ITransferRecordItemType;
}

interface IPatchTransferStatusFailed {
  type: typeof TR_PATCH_TRANSFER_STATUS_FAILED;
}

interface ITransferDeleteAttachmentSuccess {
  type: typeof TR_DELETE_ATTACHMENT_SUCCESS;
  payload: string;
}

interface ITransferDeleteAttachmentFailed {
  type: typeof TR_DELETE_ATTACHMENT_FAILED;
}

interface ITransferDeleteAttachmentStart {
  type: typeof TR_DELETE_ATTACHMENT_START;
}

interface ITransferDeleteAttachmentEnd {
  type: typeof TR_DELETE_ATTACHMENT_END;
}

interface ITransferAddAttachmentSuccess {
  type: typeof TR_ADD_ATTACHMENT_SUCCESS;
  payload: ITransferRecordItemType;
}

interface ITransferAddAttachmentFailed {
  type: typeof TR_ADD_ATTACHMENT_FAILED;
}

interface ITransferAddAttachmentStart {
  type: typeof TR_ADD_ATTACHMENT_START;
}

interface ITransferAddAttachmentEnd {
  type: typeof TR_ADD_ATTACHMENT_END;
}

interface ITransferDataUpdateStart {
  type: typeof TR_UPDATE_START;
}

interface ITransferDataUpdateEnd {
  type: typeof TR_UPDATE_END;
}

interface ITransferDataUpdateFailed {
  type: typeof TR_UPDATE_FAILED;
}

interface IPostTransferDataDashBoardSuccess {
  type: typeof POST_TRANSFER_DATA_DASHBOARD_SUCCESS;
  payload: DashboardValuesTransferData;
}

export interface DashboardValuesTransferData extends PostTransferData {
  _id: string;
}

export type TransferRecordItemDispatchType =
  | ITransferRecordItemLoadingStart
  | ITransferRecordItemLoadingEnd
  | ITransferRecordItemFetchSuccess
  | ITransferRecordItemFetchFailed
  | ITransferAddComment
  | ITransferCommentLoadingStart
  | ITransferCommentLoadingEnd
  | ITransferCommentFetchFailed
  | IUpdateTransferStart
  | IUpdateTransferSuccess
  | IUpdateTransferEnd
  | IUpdateTransferFailed
  | IUpdateTransferFlagSuccess
  | IUpdateTransferFlagFailed
  | ITransferCommentLoadingFailed
  | IPatchTransferStatusFailed
  | IPatchTransferStatusSuccess
  | IPatchTransferStatusStart
  | ITransferDeleteAttachmentSuccess
  | ITransferDeleteAttachmentFailed
  | ITransferDeleteAttachmentStart
  | ITransferDeleteAttachmentEnd
  | ITransferAddAttachmentFailed
  | ITransferAddAttachmentStart
  | ITransferAddAttachmentEnd
  | ITransferAddAttachmentSuccess
  | ITransferRecordItemDuplicateStart
  | ITransferRecordItemDuplicateEnd
  | ITransferRecordItemDuplicateSuccess
  | ITransferRecordItemDuplicateFailed
  | ITransferDataUpdateStart
  | ITransferDataUpdateEnd
  | ITransferDataUpdateFailed
  | IPostTransferDataDashBoardSuccess;

export enum ResponseCode {
  OKAY = 200,
  CREATED = 201,
}
