import React, { useState, FunctionComponent, FC } from 'react';
import SlidingPane from 'react-sliding-pane';
import { AppHeader, Icon, Button, Text, Popover } from '@nike/eds';
import { NikeApp } from '@nike/nike-design-system-icons';
import { Link as RouterLink, To } from 'react-router-dom';

type Props = {
  appName: string;
  username: string;
  routes: any;
  handleLogout: (params: any) => void;
  clearPreferences: (params: any) => void;
};

type LogoutProps = {
  handleLogout: (params: any) => any;
  clearPreferences: (params: any) => void;
};

const ButtonComponent: FC<LogoutProps> = ({
  handleLogout,
  clearPreferences,
}) => {
  return (
    <div>
      <Button onClick={handleLogout}>Logout</Button>;
      <Button onClick={clearPreferences}>Clear Preferences</Button>;
    </div>
  );
};

const ToolbarPlaceHolder: FC<LogoutProps> = ({
  handleLogout,
  clearPreferences,
}) => {
  const [popoverShown, setPopoverShown] = useState(false);

  return (
    <div>
      <Popover
        onClickOutside={() => setPopoverShown(false)}
        isOpen={popoverShown}
        bodySlot={
          <ButtonComponent
            handleLogout={handleLogout}
            clearPreferences={clearPreferences}
          />
        }
        placement="bottom"
      >
        <Button
          variant="ghost"
          onClick={() => {
            setPopoverShown(!popoverShown);
          }}
        >
          <Icon
            name="Profile"
            backgroundShape="square"
            color="var(--eds-color-text-secondary)"
          />
        </Button>
      </Popover>
    </div>
  );
};

const Header: FunctionComponent<Props> = ({
  appName,
  username,
  routes,
  handleLogout,
  clearPreferences,
}) => {
  const [displayLeftMenu, setdisplayLeftMenu] = useState(false);

  return (
    <>
      <AppHeader
        appName={appName}
        logoSlot={
          <>
            {/* <Icon name="Menu" onClick={() => setdisplayLeftMenu(true)} /> */}
            <NikeApp color="black" width="56px" height="64px" />
          </>
        }
        toolbarSlot={
          <ToolbarPlaceHolder
            handleLogout={handleLogout}
            clearPreferences={clearPreferences}
          />
        }
        avatarSlot={<span>{username}</span>}
      />
      <SlidingPane
        className="SideMenuContainer"
        closeIcon={<Icon name="Close" size="m" />}
        title={<div>{appName && <Text font="title-6">{appName}</Text>}</div>}
        isOpen={displayLeftMenu}
        from="left"
        width="345px"
        onRequestClose={() => setdisplayLeftMenu(false)}
      >
        <ul>
          {routes?.map(
            (
              item: {
                icon: string;
                subItems: any;
                path: any;
                id: React.Key | null | undefined;
                label: string;
              },
              key: any
            ) => (
              // eslint-disable-next-line react/no-array-index-key
              <li key={`nav-item-${key}`} className="navItem">
                <Text font="title-6" as="h6">
                  {item.path ? (
                    <RouterLink to={item.path}>
                      {' '}
                      <Icon name={item.icon} />
                      {item.label}
                    </RouterLink>
                  ) : (
                    <>
                      <Icon name={item.icon} /> {item.label}
                    </>
                  )}
                </Text>
                {item.subItems && (
                  <ul>
                    {item.subItems?.map(
                      (
                        subItem: {
                          icon: string;
                          path: To;
                          label: string;
                        },
                        subKey: any
                      ) => (
                        // eslint-disable-next-line react/no-array-index-key
                        <li key={`nav-item-${subKey}`} className="navSubItem">
                          <Text font="body-2" as="span">
                            {subItem.path ? (
                              <RouterLink to={subItem.path}>
                                <Icon name={subItem.icon} /> {subItem.label}
                              </RouterLink>
                            ) : (
                              <>
                                <Icon name={subItem.icon} /> {subItem.label}
                              </>
                            )}
                          </Text>
                        </li>
                      )
                    )}
                  </ul>
                )}
              </li>
            )
          )}
        </ul>
      </SlidingPane>
    </>
  );
};

export default Header;
