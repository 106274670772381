/* eslint-disable import/no-cycle */
import {
  TR_DOWNLOAD_ATTACHMENT_FAILED,
  TR_DOWNLOAD_ATTACHMENT_START,
  TR_DOWNLOAD_ATTACHMENT_SUCCESS,
  TransferAttachmentDispatchType,
  TR_DOWNLOAD_ATTACHMENT_RESET,
  TR_UPLOAD_ATTACHMENT_RESET,
  TR_DELETE_ATTACHMENT_RESET,
} from '../types/transferAttachmentTypes';

interface ITransferAttachmentDefaultState {
  deleteTransferAttachmentLoading: boolean;
  deleteTransferAttachmentData: number;
  deleteTransferAttachmentError: number;
  uploadTransferAttachmentLoading: boolean;
  uploadTransferAttachmentData: number;
  uploadTransferAttachmentError: number;
  downloadTransferAttachmentLoading: boolean;
  downloadTransferAttachmentData: any;
  downloadTransferAttachmentError: number;
  downloadAllTransferAttachmentLoading: boolean;
  downloadAllTransferAttachmentData: any;
  downloadAllTransferAttachmentError: number;
}

const defaultState: ITransferAttachmentDefaultState = {
  deleteTransferAttachmentLoading: false,
  deleteTransferAttachmentData: 0,
  deleteTransferAttachmentError: 0,
  uploadTransferAttachmentLoading: false,
  uploadTransferAttachmentData: 0,
  uploadTransferAttachmentError: 0,
  downloadTransferAttachmentLoading: false,
  downloadTransferAttachmentData: [],
  downloadTransferAttachmentError: 0,
  downloadAllTransferAttachmentLoading: false,
  downloadAllTransferAttachmentData: [],
  downloadAllTransferAttachmentError: 0,
};

const transferAttachmentReducer = (
  state: ITransferAttachmentDefaultState = defaultState,
  action: TransferAttachmentDispatchType
): ITransferAttachmentDefaultState => {
  switch (action.type) {
    case TR_DOWNLOAD_ATTACHMENT_START:
      return { ...state, downloadTransferAttachmentLoading: true };
    case TR_DOWNLOAD_ATTACHMENT_SUCCESS:
      return {
        ...state,
        downloadTransferAttachmentData: action.payload,
        downloadTransferAttachmentError: 0,
      };
    case TR_DOWNLOAD_ATTACHMENT_FAILED:
      return {
        ...state,
        downloadTransferAttachmentError: action.payload,
        downloadTransferAttachmentData: [],
      };
    case TR_DOWNLOAD_ATTACHMENT_RESET:
      return {
        ...state,
        downloadTransferAttachmentData: [],
        downloadTransferAttachmentError: 0,
      };
    case TR_UPLOAD_ATTACHMENT_RESET:
      return {
        ...state,
        uploadTransferAttachmentData: 0,
        uploadTransferAttachmentError: 0,
      };
    case TR_DELETE_ATTACHMENT_RESET:
      return {
        ...state,
        deleteTransferAttachmentData: 0,
        deleteTransferAttachmentError: 0,
      };
    default:
      return state;
  }
};

export default transferAttachmentReducer;
