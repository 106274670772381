import { Checkbox, Label } from '@nike/eds';
import { ChangeEventHandler, FC } from 'react';
import { useAppSelector, useAppDispatch } from '../../../redux/hooks';
import {
  ITransferData,
  TRANSFER_TYPE_TECH_PACK,
  TRANSFER_TYPE_TOOLING,
} from '../NewTranferInterface';
import { roles } from '../../common/RolesConstants';
import { updateInputTouched } from '../../../redux/actions/inputActions';

interface FieldProps {
  transferData: any | ITransferData;
  setTransferData: (params: any) => any;
}

const Checkboxes: FC<FieldProps> = ({ transferData, setTransferData }) => {
  const [transferItemData, userRole, isEditMode] = useAppSelector(state => [
    state.transferRecordItem.transferItemData.source,
    state.userRole.role,
    state.input.isEditMode,
  ]);

  const dispatch = useAppDispatch();

  const handleCheckInputChange: ChangeEventHandler<
    HTMLInputElement
  > = event => {
    const { checked, value } = event.target;

    let list = transferData.transferGeos || [];

    if (checked) {
      list.push(value);
    } else {
      list = list?.filter((item: string) => {
        return item !== value;
      });
    }
    if (value === 'ALL') {
      list = list.filter((item: string) => {
        return item === value;
      });
    }

    setTransferData({ ...transferData, transferGeos: list });

    dispatch<any>(updateInputTouched(true));
  };

  return (
    <div className="form-input-container">
      <Label className="input-label">Transfer GEOs</Label>
      <div className="checkbox-inputs">
        <div>
          <Checkbox
            label="ALL"
            value="ALL"
            id="ALL"
            name="transferGeos"
            onChange={handleCheckInputChange}
            checked={transferData.transferGeos.includes('ALL')}
            className="checkbox-input"
            disabled={
              !transferData?.transferType?.value ||
              transferData?.transferType?.value === TRANSFER_TYPE_TOOLING ||
              transferData?.transferType?.value === TRANSFER_TYPE_TECH_PACK ||
              ((userRole === roles.factory || userRole === roles.pmo) &&
                isEditMode &&
                transferItemData.status !== 'Factory' &&
                transferItemData.status !== 'Draft')
            }
          />
          <Checkbox
            label="NA"
            value="NA"
            id="NA"
            name="transferGeos"
            onChange={handleCheckInputChange}
            checked={transferData.transferGeos.includes('NA')}
            className="checkbox-input"
            disabled={
              !transferData?.transferType?.value ||
              transferData?.transferType?.value === TRANSFER_TYPE_TOOLING ||
              transferData?.transferType?.value === TRANSFER_TYPE_TECH_PACK ||
              ((userRole === roles.factory || userRole === roles.pmo) &&
                isEditMode &&
                transferItemData.status !== 'Factory' &&
                transferItemData.status !== 'Draft') ||
              transferData.transferGeos.includes('ALL')
            }
          />
        </div>
        <div>
          <Checkbox
            label="EMEA"
            value="EMEA"
            id="EMEA"
            name="transferGeos"
            onChange={handleCheckInputChange}
            checked={transferData.transferGeos.includes('EMEA')}
            className="checkbox-input"
            disabled={
              !transferData?.transferType?.value ||
              transferData?.transferType?.value === TRANSFER_TYPE_TOOLING ||
              transferData?.transferType?.value === TRANSFER_TYPE_TECH_PACK ||
              ((userRole === roles.factory || userRole === roles.pmo) &&
                isEditMode &&
                transferItemData.status !== 'Factory' &&
                transferItemData.status !== 'Draft') ||
              transferData.transferGeos.includes('ALL')
            }
          />
          <Checkbox
            label="APLA"
            value="APLA"
            id="APLA"
            name="transferGeos"
            onChange={handleCheckInputChange}
            checked={transferData.transferGeos.includes('APLA')}
            className="checkbox-input"
            disabled={
              !transferData?.transferType?.value ||
              transferData?.transferType?.value === TRANSFER_TYPE_TOOLING ||
              transferData?.transferType?.value === TRANSFER_TYPE_TECH_PACK ||
              ((userRole === roles.factory || userRole === roles.pmo) &&
                isEditMode &&
                transferItemData.status !== 'Factory' &&
                transferItemData.status !== 'Draft') ||
              transferData.transferGeos.includes('ALL')
            }
          />
        </div>
        <div>
          <Checkbox
            label="GC"
            value="GC"
            id="GC"
            name="transferGeos"
            onChange={handleCheckInputChange}
            checked={transferData.transferGeos.includes('GC')}
            className="checkbox-input"
            disabled={
              !transferData?.transferType?.value ||
              transferData?.transferType?.value === TRANSFER_TYPE_TOOLING ||
              transferData?.transferType?.value === TRANSFER_TYPE_TECH_PACK ||
              ((userRole === roles.factory || userRole === roles.pmo) &&
                isEditMode &&
                transferItemData.status !== 'Factory' &&
                transferItemData.status !== 'Draft') ||
              transferData.transferGeos.includes('ALL')
            }
          />
        </div>
      </div>
    </div>
  );
};

export default Checkboxes;
