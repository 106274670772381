/* eslint-disable no-underscore-dangle */
import {
  TR_FETCH_SUCCESS,
  TR_LOADING_END,
  TR_LOADING_START,
  TR_LOADING_ERROR,
  TransferRecordDispatchType,
  TransferRecordType,
  DELETE_TRANSFER_DATA_DASHBOARD_SUCCESS,
} from '../types/transferRecordsTypes';
import { POST_TRANSFER_DATA_DASHBOARD_SUCCESS } from '../types/newTransferTypes';

interface ITransferRecordDefaultState {
  loading: boolean;
  totalRecords: TransferRecordType[];
  lastModified: number;
  complete: boolean;
  isLoadingScreen: boolean;
}

const defaultState: ITransferRecordDefaultState = {
  loading: false,
  totalRecords: [],
  lastModified: 1,
  complete: false,
  isLoadingScreen: false,
};

const transferRecordReducer = (
  state: ITransferRecordDefaultState = defaultState,
  action: TransferRecordDispatchType
): ITransferRecordDefaultState => {
  switch (action.type) {
    case TR_LOADING_START:
      return { ...state, loading: true, isLoadingScreen: true };
    case TR_LOADING_END:
      return {
        ...state,
        loading: false,
        complete: true,
        isLoadingScreen: false,
      };
    case TR_LOADING_ERROR:
      return { ...state, loading: false, isLoadingScreen: false };
    case TR_FETCH_SUCCESS:
      return {
        ...state,
        totalRecords: [...state.totalRecords, ...action.payload].sort(
          (a, b) =>
            Date.parse(b.modifiedTimestamp) - Date.parse(a.modifiedTimestamp)
        ),
        lastModified: Date.now(),
        isLoadingScreen: false,
      };
    case POST_TRANSFER_DATA_DASHBOARD_SUCCESS:
      return {
        ...state,
        totalRecords: [action.payload, ...state.totalRecords]
          .reduce((acc: any, current: any) => {
            const x = acc.find((item: any) => item._id === current._id);
            if (!x) {
              return acc.concat([current]);
            }
            return acc;
          }, [])
          .sort(
            (a: any, b: any) =>
              Date.parse(b.modifiedTimestamp) - Date.parse(a.modifiedTimestamp)
          ),
        lastModified: Date.now(),
        isLoadingScreen: false,
      };
    case DELETE_TRANSFER_DATA_DASHBOARD_SUCCESS:
      return {
        ...state,
        totalRecords: state.totalRecords.filter(x => x._id !== action.payload),
        isLoadingScreen: false,
      };
    default:
      return state;
  }
};

export default transferRecordReducer;
