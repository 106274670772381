import { Dispatch } from 'redux';
import { toast } from 'react-toastify';
import {
  TR_DELETE_START,
  TR_DELETE_END,
  TR_DELETE_SUCCESS,
  TR_DELETE_FAILED,
  ResponseCode,
  DeleteTransferDispatchType,
  TR_DELETE_RESET,
} from '../types/deleteTransferRecordTypes';
import apiInstance from '../axios/index';
import apiConfig from '../../config/apiConfig';
import { DELETE_TRANSFER_DATA_DASHBOARD_SUCCESS } from '../types/transferRecordsTypes';

export const deleteTransferRecord =
  (id: any) => async (dispatch: Dispatch<DeleteTransferDispatchType>) => {
    dispatch({ type: TR_DELETE_START });

    const errorMsg = 'Something went wrong, please try again';
    try {
      let deleteTransferUrl = apiConfig.baseURL;
      if (id.transferId !== undefined) {
        deleteTransferUrl += apiConfig.deleteTransferRecord.url.replace(
          '{id}',
          id.transferId
        );
      } else {
        deleteTransferUrl += apiConfig.deleteTransferRecord.url.replace(
          '{id}',
          id
        );
      }

      const response = await apiInstance.delete(`${deleteTransferUrl}`);

      if (response && response.status === ResponseCode.OKAY) {
        dispatch({
          type: TR_DELETE_SUCCESS,
        });
        dispatch({
          type: DELETE_TRANSFER_DATA_DASHBOARD_SUCCESS,
          payload: id,
        });
        toast.success('Transfer Record has been Deleted successfully');
      }
    } catch (err) {
      dispatch({
        type: TR_DELETE_FAILED,
      });
      toast.error(errorMsg);
    } finally {
      dispatch({ type: TR_DELETE_END });
    }
  };

export const deleteTransferRecordReset = () => ({
  type: TR_DELETE_RESET,
});
